// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

const IconWhoreInfo = ({ active }: AttentionWhoreElProps) => {
  return (
    <IconWhoreIcon
      className={`jarvis_alert jarvis_alert_big pixelify_font_family ${
        active ? "nerdfy_blue" : "nerdfy_grey_stronger"
      }`}
    />
  );
};

interface IconWhoreInfoProps {
  className?: string;
}

export const IconWhoreIcon = ({ className }: IconWhoreInfoProps) => {
  return <span className={className}>!</span>;
};

export default IconWhoreInfo;
