// react
import { useState, useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { jarvisChatsActions } from "redux/slices/jarvisChatsSlice";

// enums
import { BubbleType } from "enums/bubbleEnum";

// components
import { DropZoneAttentionWhore } from "components/DropZone";
import AttentionWhores from "components/attentionWhores/AttentionWhores";
import JarvisAnswerBubble from "handlers/jarvisQuestionBubble/JarvisAnswerBubble";

// handlers
import QuestionsHandler from "handlers/challengeButtons/questions/QuestionsHandler";
// import JarvisBubbleIntroHandler from "handlers/jarvisQuestionBubble/JarvisBubbleIntroHandler";

// svgs
import DonePixelSvg from "icons/DonePixelIcon";

// interfaces
import {
  JarvisChatProps,
  JarvisChatFirebaseProps,
} from "interfaces/jarvisChat";
import { ChallengeCommonProps } from "interfaces/challenge";
import { AttentionWhoreProps } from "interfaces/attentionWhore";
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

// utils
import isEmpty from "lodash/isEmpty";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

const WhoreJarvisQuestionTemplateEl = ({
  whore,
  active,
}: AttentionWhoreElProps) => {
  const payload = whore.unknownPayload as JarvisChatProps;

  return payload.answerRead ? (
    <p
      className={`pixelify_font_family ${
        active ? "nerdfy_orange" : "nerdfy_grey_stronger"
      }`}
    >
      {payload.question}{" "}
      <span>
        <DonePixelSvg />
      </span>
    </p>
  ) : (
    <p
      className={`pixelify_font_family ${
        active ? "nerdfy_orange" : "nerdfy_grey_stronger"
      }`}
    >
      {payload.question}
    </p>
  );
};

interface CurrentJarvisProps {
  id: number;
  answer: string;
  question: string;
  answerShow: boolean;
  questionsShow: boolean;
  hidingAnimation: boolean;

  // answer
  answerRead: boolean;
  answerThumbsUp: boolean;
  answerThumbsDown: boolean;
}

interface JarvisQuestionBubbleHandlerProps {
  paused: boolean;
  wrapper: HTMLDivElement | null;
  challenge: ChallengeCommonProps;
  finished?(): void;
}

const JarvisQuestionBubbleHandler = ({
  paused,
  wrapper,
  finished,
  challenge,
}: JarvisQuestionBubbleHandlerProps) => {
  const dispatch = useDispatch();
  const jarvisChats = useSelector(s.jarvisChats());
  const whoresLenRef = useRef(0);
  const questions = useRef<JarvisChatProps[]>([]);
  const [displayButton, setDisplayButton] = useState(0);
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  // const [isRefillQuestions, setIsRefillQuestions] = useState(false);
  const [currentJarvis, setCurrentJarvis] = useState<CurrentJarvisProps>(
    getCurrentJarvisEmptyValues()
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listen, [jarvisChats]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [jarvisChats]);

  function listen() {
    if (isEmpty(jarvisChats)) return;
    questions.current = sort(filterByChallengeId(jarvisChats));
  }

  function bootstrap() {
    if (!isEmpty(whores)) return;
    if (isEmpty(jarvisChats)) return;
    if (!filterByAnswerNotRead(questions.current).length) return;

    setTimeout(() => setDisplayButton(displayButton + 1), 1500);
    // start(filterByAnswerNotRead(questions.current), 500);
  }

  function refillQuestions() {
    // setIsRefillQuestions(true);
    start(filterByAnswerNotRead(questions.current), 50);
  }

  function start(whores: JarvisChatProps[], delay = 0) {
    whoresLenRef.current = whores.length;

    if (whoresLenRef.current === 0) {
      handleFinishedWhores();
      return;
    }

    setTimeout(displayWhores.bind(null, whores), delay);
  }

  function displayWhores(w: JarvisChatProps[]) {
    setWhores([...mapWhores(w)]);
  }

  function mapWhores(whores: JarvisChatProps[]): AttentionWhoreProps[] {
    return whores.map((w: JarvisChatProps) => getWhore(w));
  }

  function filterByAnswerNotRead(chats: JarvisChatProps[]) {
    return chats.filter((data) => !data.answerRead);
  }

  function filterByChallengeId(chats: JarvisChatProps[]) {
    return chats.filter((data) => data.challengeId === challenge.id);
  }

  function sort(chats: JarvisChatProps[]) {
    return chats.sort((a, b) => a.index - b.index);
  }

  function getWhore(data: JarvisChatProps, last = false): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      last,
      payload: "",
      unknownPayload: data,
      call: onDragWhoreCall,
      bubbleType: BubbleType.None,
      dropZoneBorderColor: "orange",
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
      WhoreEl: WhoreJarvisQuestionTemplateEl,
    });
  }

  function onDragWhoreCall(whore: AttentionWhoreProps) {
    callJarvisBubble(whore.unknownPayload as JarvisChatProps);
  }

  function getCurrentJarvisEmptyValues(config?: {
    questionsShow: boolean;
  }): CurrentJarvisProps {
    return {
      id: 0,
      answer: "",
      question: "",
      answerShow: false,
      answerRead: false,
      answerThumbsUp: false,
      hidingAnimation: false,
      answerThumbsDown: false,
      questionsShow: !!config?.questionsShow,
    };
  }

  function hideJarvisBubble() {
    decWhoresLen();
    handleFinishedWhores();

    setCurrentJarvis({
      ...currentJarvis,
      answerShow: false,
      hidingAnimation: true,
    });
  }

  function handleFinishedWhores() {
    if (whoresLenRef.current > 0) return;

    finish();
    filterByAnswerNotRead(questions.current).length &&
      setDisplayButton(displayButton + 1);
  }

  function finish() {
    if (!finished) return;
    setTimeout(finished);
  }

  function decWhoresLen() {
    const len = whoresLenRef.current;
    if (len > 0) whoresLenRef.current -= 1;
  }

  function syncAnswerThumbsWithFirebase(
    thumbsUp: boolean,
    thumbsDown: boolean
  ) {
    const jarvisId = currentJarvis.id;
    if (!jarvisId) return;

    const dataFirebase: JarvisChatFirebaseProps = {
      id: jarvisId,
      up: thumbsUp,
      down: thumbsDown,
    };

    dispatch(jarvisChatsActions.async.setAnswerThumbs(dataFirebase));
  }

  function onJarvisAnswerBubbleCloseAnimationEnd() {
    setCurrentJarvis(getCurrentJarvisEmptyValues());
  }

  function callJarvisBubble(jarvis: JarvisChatProps) {
    setCurrentJarvis({
      ...jarvis,
      answerRead: true,
      answerShow: true,
      questionsShow: true,
      hidingAnimation: false,
      answerThumbsUp: !!jarvis.answerUp,
      answerThumbsDown: !!jarvis.answerDown,
    });

    const id = jarvis.id;
    const questionClicked = true;
    const data = { id, questionClicked };

    setJarvisAnswerAsRead(id);
    dispatch(jarvisChatsActions.async.setQuestionAsClicked(data));
  }

  function setJarvisAnswerAsRead(id: number) {
    if (!id) return;

    const dataFirebase: JarvisChatFirebaseProps = {
      id,
      read: true,
    };

    // setCurrentJarvis({ ...currentJarvis, answerRead: true });
    dispatch(jarvisChatsActions.async.setAnswerAsRead(dataFirebase));
  }

  return (
    <>
      {/* {whores.length > 0 && (
        <JarvisBubbleIntroHandler
          wrapper={wrapper}
          paused={paused}
          challenge={challenge}
        />
      )} */}

      <QuestionsHandler
        challenge={challenge}
        display={displayButton}
        refillQuestions={refillQuestions}
      />

      <AttentionWhores
        whores={whores}
        paused={paused}
        clear={challenge.flowDone}
        dropZoneBorderColor="orange"
        // disableInactive={isRefillQuestions}
        disabledDrop={currentJarvis.answerShow}
      />

      <JarvisAnswerBubble
        hide={hideJarvisBubble}
        answer={currentJarvis.answer}
        read={currentJarvis.answerRead}
        show={currentJarvis.answerShow}
        question={currentJarvis.question}
        thumbsUp={currentJarvis.answerThumbsUp}
        syncThumbs={syncAnswerThumbsWithFirebase}
        thumbsDown={currentJarvis.answerThumbsDown}
        onCloseAnimationEnd={onJarvisAnswerBubbleCloseAnimationEnd}
      />

      <DropZoneAttentionWhore />
    </>
  );
};

export default JarvisQuestionBubbleHandler;
