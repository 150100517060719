// react
import { useState, useEffect } from "react";

// components
import ChallengeButton from "handlers/challengeButtons/ChallengeButton";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

// utils
import color from "styles/color";
import animationUtils from "utils/animationUtils";

interface QuestionsHandlerProps {
  display: number;
  refillQuestions: () => void;
  challenge: ChallengeCommonProps;
}

const QuestionsHandler = ({
  display,
  challenge,
  refillQuestions,
}: QuestionsHandlerProps) => {
  const [animation, setAnimation] = useState<string>("");

  useEffect(displayButton, [display]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(buttonZoomOut, [challenge.flowFinished]);

  function displayButton() {
    if (!display) return;
    setAnimation(animationUtils.getRandom());
  }

  function buttonZoomOut() {
    if (!animation) return;
    if (animation === "animate__zoomOut") return;

    setAnimation("animate__zoomOut");
  }

  function onClick() {
    if (animation === "animate__zoomOut") return;

    refillQuestions();
    setTimeout(() => setAnimation("animate__zoomOut"));
  }

  return (
    <>
      {!!animation && !challenge.flowDone && (
        <ChallengeButton
          bottom={310}
          onClick={onClick}
          color={color.orange}
          label={<span>?</span>}
          customClassName={`animate__animated ${animation} no-swipe`}
        />
      )}
    </>
  );
};

export default QuestionsHandler;
