// react
import { IonRippleEffect } from "@ionic/react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// interfaces
import { AuthProps } from "interfaces/auth";

// utils
import color from "styles/color";
import styled from "styled-components";
import authUtils from "utils/authUtils";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  opacity: 0.9;
  background: ${color.dark.bg};
  padding: 20px;

  .fake_btn {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .stats {
      position: absolute;
      bottom: 12px;
    }

    > p {
      font-size: 1.8em;
      text-align: center;
      margin: 10px 20px;

      &.desc {
        color: ${color.grey.middle};
      }
    }
  }
`;

interface BootstrapButtonProps {
  loading: boolean;
  onClick: () => void;
}

const BootstrapButton = ({ loading, onClick }: BootstrapButtonProps) => {
  function click() {
    setTimeout(onClick, 50);
  }

  return (
    <Wrapper onClick={click} className="ion-activatable">
      <IonRippleEffect style={{ color: color.yellow }} />

      <div className="fake_btn blink">
        <p className="nerdfy_white">Press to Start</p>
        <p className="desc">{loading ? "Loading" : "We're almost there..."}</p>
      </div>
    </Wrapper>
  );
};

interface ChallengeButtonProps extends BootstrapButtonProps {
  auth: AuthProps;
}

const ChallengeButton = ({ auth, loading, onClick }: ChallengeButtonProps) => {
  const challenge = useSelector(s.challenge());
  const { checkpoint } = useSelector(s.playerClassRoom());

  function click() {
    setTimeout(onClick, 50);
  }

  return (
    <Wrapper onClick={click} className="ion-activatable">
      <IonRippleEffect style={{ color: color.blue }} />

      <div className="fake_btn blink">
        {challenge.flowDone ? (
          <>
            <p className="nerdfy_green">Press to View</p>
            <p className="desc">
              {loading ? (
                "Loading..."
              ) : auth.anonymous ? (
                <>
                  <span className="nerdfy_blue">Sign in</span> and{" "}
                  <span className="nerdfy_white">begin</span> your{" "}
                  <span className="nerdfy_white">coding journey</span>.
                </>
              ) : auth.subscribed ||
                (!auth.subscribed && !authUtils.hasTrialExpired(checkpoint)) ? (
                <>
                  Come back <span className="nerdfy_yellow">later</span> to{" "}
                  <span className="nerdfy_white">play</span> again.
                </>
              ) : (
                <>
                  <span className="nerdfy_white">Unlock</span> to{" "}
                  <span className="nerdfy_red">boost</span> your{" "}
                  <span className="nerdfy_white">coding skills</span> daily.
                </>
              )}
            </p>
          </>
        ) : (
          <>
            <p className="nerdfy_green">Press to Start</p>
            <p className="desc">{loading ? "Loading..." : "Are you ready?"}</p>
          </>
        )}
      </div>
    </Wrapper>
  );
};

interface PressButtonHandlerProps {
  auth: AuthProps;
  loading: boolean;
  onClickStart: () => void;
  onClickChallenge: () => void;
}

const PressButtonHandler = ({
  auth,
  loading,
  onClickStart,
  onClickChallenge,
}: PressButtonHandlerProps) => {
  return (
    <>
      {auth.signed ? (
        <ChallengeButton
          auth={auth}
          loading={loading}
          onClick={onClickChallenge}
        />
      ) : (
        <BootstrapButton loading={loading} onClick={onClickStart} />
      )}
    </>
  );
};

export default PressButtonHandler;
