// react
import { useState } from "react";

// redux
import { useDispatch } from "react-redux";

// services
import UserService, { UserNamesProps } from "services/UserService";
import PlayerFirebaseService from "services/firebase/PlayerFirebaseService";

// components
import Loading from "components/Loading";
import FormHeader from "components/form/FormHeader";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";

// interfaces
import { AuthProps } from "interfaces/auth";
import { UserProps } from "interfaces/user";

// utils
import styled from "styled-components";
import { authActions } from "redux/slices/authSlice";
import { AllToOptional } from "utils/transformTypeUtils";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
  }
`;

interface EndProps {
  goBack(): void;
  username: string;
  jarvisName: string;
  disabledBack: boolean;
  proceedDisabled: boolean;
  auth: AuthProps | undefined;
  finished?(): void;
  trackSignUp?: (user: UserProps) => void;
}

const End = ({
  auth,
  goBack,
  finished,
  username,
  jarvisName,
  trackSignUp,
  disabledBack,
  proceedDisabled,
}: EndProps) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  async function save() {
    try {
      setLoading(true);

      const data = map(username, jarvisName);
      await Promise.all([syncJarvisName(), updateUserNames(data)]);

      track();
      dispatchUser(mapUser(data));
      finished && setTimeout(finished);
    } catch (err: unknown) {
      setLoading(false);
      setError("Something went wrong. Please try again.");
    }
  }

  function updateUserNames(data: UserNamesProps): Promise<unknown> {
    return new UserService().updateNames(data);
  }

  function syncJarvisName(): Promise<void> {
    if (!auth) return Promise.reject();
    if (!auth.user) return Promise.reject();
    if (!auth.user.id) return Promise.reject();

    return new PlayerFirebaseService().update(auth.user.id, {
      jarvis: {
        name: jarvisName,
      },
    });
  }

  function map(username: string, jarvisName: string): UserNamesProps {
    const split = username.split(" ");
    const hasLastName = split.length > 1;

    const data: UserNamesProps = {
      jarvisName,
      givenName: username,
      firstName: split[0],
    };

    if (hasLastName) data.lastName = split[split.length - 1];
    return data;
  }

  function mapUser(data: UserNamesProps): AllToOptional<UserProps> {
    return {
      lastName: data.lastName,
      firstName: data.firstName,
      givenName: data.givenName,
    };
  }

  function dispatchUser(user: AllToOptional<UserProps>) {
    dispatch(authActions.updateUser(user));
  }

  function track() {
    if (!auth) return;
    if (!auth.user) return;
    if (!trackSignUp) return;

    setTimeout(trackSignUp.bind(null, auth.user));
  }

  return (
    <Wrapper>
      <Loading loading={loading} />

      <FormHeader
        save={save}
        back={goBack}
        disabled={proceedDisabled}
        disabledBack={disabledBack}
      />

      <div className="content"></div>
      {error && <ErrorFeedback err={error} />}
    </Wrapper>
  );
};

export default End;
