// redux
import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { appActions } from "redux/slices/appSlice";

// enums
import { UserGenreType } from "enums/userEnum";

// interfaces
import { AuthProps } from "interfaces/auth";
import { UserProps } from "interfaces/user";

// utils
import { AllToOptional } from "utils/transformTypeUtils";

const initialState: AuthProps = {
  user: {
    id: 0,
    email: "",
    image: "",
    lastName: "",
    firstName: "",
    givenName: "",
    languageId: 0,
    emailActivated: false,
    genre: UserGenreType.Unknown,
  },
  signed: false,
  anonymous: false,
  subscribed: false,
  firebaseSigned: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<AuthProps>) => {
      return action.payload;
    },
    subscribe: (state) => {
      state.subscribed = true;
    },
    unsubscribe: (state) => {
      state.subscribed = false;
    },
    updateUser(state, action: PayloadAction<AllToOptional<UserProps>>) {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
