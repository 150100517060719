// react
import React, { useState, useEffect } from "react";

// svgs
// import DonePixelSvg from "icons/DonePixelIcon";

// components
import Thumbs from "components/Thumbs";
import Typewriter from "components/Typewriter";
import CloseButton from "components/CloseButton";

interface BubbleFeedbackFooterProps {
  onThumbsUp(): void;
  isThumbsUp: boolean;
  onThumbsDown(): void;
  isThumbsDown: boolean;
}

export const BubbleFeedbackFooter = ({
  onThumbsUp,
  isThumbsUp,
  onThumbsDown,
  isThumbsDown,
}: BubbleFeedbackFooterProps) => {
  return (
    <div>
      <Thumbs
        is={isThumbsDown}
        onClick={onThumbsDown}
        buttonClassName="f_button_down"
      />

      <Thumbs
        up
        is={isThumbsUp}
        onClick={onThumbsUp}
        buttonClassName="f_button_up m_left_10"
      />
    </div>
  );
};

interface BubbleQuestionSubHeaderProps {
  question: string;
  read?: boolean;
  questionLabelClassName?: string;
}

export const BubbleQuestionSubHeader = ({
  read,
  question,
  questionLabelClassName = "nerdfy_orange",
}: BubbleQuestionSubHeaderProps) => {
  return (
    <p className="bubble_question_subheader">
      <span className={questionLabelClassName}>{question}</span>

      {/* {read && (
        <span className="green_opacity m_left_10">
          <DonePixelSvg width="20" height="20" />
        </span>
      )} */}
    </p>
  );
};

export interface BubbleProps {
  name: string;
  hide(): void;
  content: string;
  finishedTyping?(): void;
  footer?: React.ReactNode;
  customClasseName?: string;
  subHeader?: React.ReactNode;
  onAnimationEnd?(e: React.AnimationEvent<HTMLDivElement>): void;
}

interface BubbleMainProps {
  hide(): void;
  name: string;
  show: boolean;
  content: string;
  labelClassName: string;
  footerIcon: React.ReactNode;
  finishedTyping?(): void;
  footer?: React.ReactNode;
  customClasseName?: string;
  subHeader?: React.ReactNode;
  onAnimationEnd?(e: React.AnimationEvent<HTMLDivElement>): void;
}

const Bubble = ({
  name,
  hide,
  show,
  footer,
  content,
  subHeader,
  footerIcon,
  labelClassName,
  finishedTyping,
  onAnimationEnd,
  customClasseName,
}: BubbleMainProps) => {
  const [animation, setAnimation] = useState("");

  useEffect(handleShowUpAnimation, [show]);

  function handleShowUpAnimation() {
    if (!show) return;
    setAnimation("animate__bounceInLeft");
  }

  function hideAnimation() {
    setAnimation("animate__bounceOutLeft");

    setTimeout(() => {
      hide && hide();
    }, 800);
  }

  function animationEnd(e: React.AnimationEvent<HTMLDivElement>) {
    e.stopPropagation();
    onAnimationEnd && onAnimationEnd(e);
  }

  return (
    <>
      {animation && (
        <div
          className={`nerdfy_bubble animate__animated no-swipe default_font_family ${animation} ${customClasseName}`}
          onAnimationEnd={animationEnd}
        >
          <div className="bubble_header">
            <h2 className={`${labelClassName} pixelify_font_family`}>{name}</h2>
          </div>

          {!!subHeader && subHeader}

          <div className="bubble_content">
            {show && (
              <Typewriter
                delay={1600}
                content={content}
                finished={finishedTyping}
              />
            )}
          </div>

          {!footer && (
            <div className="bubble_footer">
              <div className="icon_wrapper">{footerIcon}</div>
              <CloseButton onClick={hideAnimation} className="close_button" />
            </div>
          )}

          {!!footer && (
            <div className="bubble_footer">
              {footer}
              <CloseButton onClick={hideAnimation} className="close_button" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Bubble;
