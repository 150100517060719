// interfaces
import { ChallengeProps } from "interfaces/challenge";
import { PlayerClassRoomProps } from "interfaces/playerClassRoom";

// utils
import codeUtils from "utils/code/codeUtils";
import { increment, serverTimestamp, FieldValue } from "firebase/firestore";

interface FlowInitValuesProps {
  id: number;
  type: number;
  checkpoint: number;
  languageId: number;
  classRoomId: number;
  userId: string | number;
  challengeSectionId: number;

  // timestamp
  initOn: FieldValue;
}

interface FlowStartedValuesProps {
  // timestamp
  startedOn: FieldValue;
}

interface FlowFinishedValuesProps {
  // timestamp
  finishedOn: FieldValue;
}

interface FlowDoneValuesProps {
  frame: number;
  currentCode: string;

  // flow
  flowDone: true;
  flowSuccess: true;

  // timestamp
  doneOn: FieldValue;
}

interface FlowFailedValuesProps {
  frame: number;
  currentCode: string;

  // flow
  flowDone: true;
  flowFailed: true;
  flowFinished: true;

  killed: FieldValue;

  // timestamp
  doneOn: FieldValue;
}

class ChallengeFirebaseEntity {
  getKilledValues(): { killed: FieldValue } {
    return { killed: increment(1) };
  }

  getMissedValues(): { missed: FieldValue } {
    return { missed: increment(1) };
  }

  getHelpedValues(): { help: FieldValue } {
    return { help: increment(1) };
  }

  getFlowInitValues(
    userId: string | number,
    player: PlayerClassRoomProps,
    challenge: ChallengeProps
  ): FlowInitValuesProps {
    return {
      userId,
      id: challenge.id,
      type: challenge.type,
      initOn: serverTimestamp(),
      checkpoint: player.checkpoint,
      languageId: challenge.languageId,
      classRoomId: challenge.classRoomId,
      challengeSectionId: challenge.challengeSectionId,
    };
  }

  getFlowStartedValues(): FlowStartedValuesProps {
    return {
      startedOn: serverTimestamp(),
    };
  }

  getFlowFinishedValues(): FlowFinishedValuesProps {
    return {
      finishedOn: serverTimestamp(),
    };
  }

  getDoneValues(challenge: ChallengeProps): FlowDoneValuesProps {
    const { code, currentCode } = challenge;

    return {
      flowDone: true,
      flowSuccess: true,
      doneOn: serverTimestamp(),
      frame: challenge.frame + 1,
      currentCode: codeUtils.simple.getFinished(currentCode, code),
    };
  }

  getFailedValues(challenge: ChallengeProps): FlowFailedValuesProps {
    const { code } = challenge;

    return {
      flowDone: true,
      flowFailed: true,
      flowFinished: true,
      killed: increment(1),
      doneOn: serverTimestamp(),
      frame: challenge.frame + 1,
      currentCode: code.finished[0].data,
    };
  }
}

export default ChallengeFirebaseEntity;
