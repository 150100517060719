// redux
import { useEffect } from "react";
import { classRoomActions } from "redux/slices/classRoomSlice";
import { sectionsBriefActions } from "redux/slices/sectionsBriefSlice";

// redux
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { challengesActions } from "redux/slices/challengesSlice";

// interfaces
import { ChallengeFirebaseProps } from "interfaces/challengeFirebase";

// components
import { hasPendingWritesDocType } from "firebaseSync/FirebaseSync";

// parsers
import sectionsBriefParser from "parsers/sectionsBriefParser";

// services
import FirebaseCoreService from "services/core/FirebaseCoreService";

// entities
import challengeFirebaseParser from "parsers/challengeFirebaseParser";

// firestore
import { collection } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";

// utils
import isEmpty from "lodash/isEmpty";

interface ChallengesFirebaseSyncProps {
  classRoomId: number;
  userId: number | string;
  isCachedData: (docs: hasPendingWritesDocType) => boolean;
}

const ChallengesFirebaseSync = ({
  userId,
  classRoomId,
  isCachedData,
}: ChallengesFirebaseSyncProps) => {
  const dispatch = useDispatch();
  const [snapshot] = useCollection(
    collection(
      FirebaseCoreService.getDatabase(),
      `players/${userId}/classRooms/${classRoomId}/challenges`
    )
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(sync, [snapshot]);

  function sync() {
    const docs = snapshot?.docs;
    if (!docs) return;

    const challenges = docs.reduce((acc, doc) => {
      if (!isCachedData(doc)) return acc;
      acc.push(doc.data() as ChallengeFirebaseProps);
      return acc;
    }, [] as ChallengeFirebaseProps[]);

    syncChallengesFirebase(challenges, dispatch);
  }

  return null;
};

export function syncChallengesFirebase(
  challenges: ChallengeFirebaseProps[],
  dispatch: Dispatch
) {
  if (isEmpty(challenges)) return;

  const mapped = challenges.map(challengeFirebaseParser.map);

  dispatch(challengesActions.update(mapped));
  dispatch(challengesActions.updateHash(mapped));
  dispatch(classRoomActions.async.syncFlowParcialDone());
  dispatch(
    sectionsBriefActions.updateChallengesDone(
      sectionsBriefParser.mapChallengesDone(mapped)
    )
  );
}

export default ChallengesFirebaseSync;
