// react
import { useState, useEffect, useRef, useCallback } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";

// ionic-react
import { IonicSlides } from "@ionic/react";

// components
import FormHeader from "components/form/FormHeader";
import { DropZoneAttentionWhore } from "components/DropZone";
import ChallengeCode from "components/challenge/battle/ChallengeCode";
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// enums
import { CheckpointsEnum } from "enums/checkpointsEnum";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";

// Swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// utils
import color from "styles/color";
import styled from "styled-components";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: 0.9;

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;

    ul.abas {
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 45px;
      list-style: none;
      margin: 0;
      padding: 0;
      border-bottom: 2px solid ${color.grey.dark};

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 15px;
        height: 32px;
        font-size: 1.1em;
        color: ${color.grey.stronger};

        &.selected {
          color: ${color.green};
        }
      }
    }

    .slides {
      flex: 1;
      display: flex;
      flex-direction: column;

      .slide {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .no_input_wrapper {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 25px;

          p {
            color: ${color.grey.strong};
            font-size: 1.2em;
            text-align: center;
          }
        }
      }
    }
  }
`;

const INPUT_SLIDE = 0;
const OUTPUT_SLIDE = 1;

interface LabsPresentationProps {
  input: string;
  show: boolean;
  output: string;
  language: string;
  animationEnd(): void;
  onClose?(): void;
}

const IOCodePresentation = ({
  show,
  input,
  output,
  onClose,
  language,
  animationEnd,
}: LabsPresentationProps) => {
  const dispatch = useDispatch();
  const auth = useSelector(s.auth());
  const checkpoints = useSelector(s.checkpoints());
  const [animation, setAnimation] = useState("");
  const swiperRef = useRef<SwiperProps | undefined>();
  const wrapperRef = useRef<HTMLDivElement | undefined>();
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [slideActiveIndex, setSlideActiveIndex] = useState(INPUT_SLIDE);
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());
  const handle = useCallback((node: HTMLDivElement) => {
    if (!node) return;
    wrapperRef.current = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [show]);
  useEffect(handleShow, [show]);

  function bootstrap() {
    if (!show) return;
    if (hasAlreadySeenIntro()) return;

    setTimeout(first, 1000);
  }

  function handleShow() {
    if (!show) return;
    setTimeout(() => setAnimation("animate__bounceInLeft"));
  }

  function close() {
    setAnimation("animate__bounceOutLeft");
    onClose && setTimeout(onClose);
  }

  function onAnimationEnd(e: React.AnimationEvent<HTMLElement>) {
    e.stopPropagation();
    if (e.animationName === "bounceOutLeft") animationEnd();
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;

    listenToSlideChange(swiper);
  }

  function listenToSlideChange(swiper: SwiperProps) {
    swiper.on("slideChange", ({ activeIndex }: SwiperProps) => {
      setSlideActiveIndex(activeIndex);
    });
  }

  function first() {
    const payload = `The input.js file contains the starting code for the challenge, while the output.js file displays the expected result. Swipe left or right to view the full content.`;
    setWhores([getWhore(payload)]);
  }

  function getWhore(payload: string, last?: boolean): AttentionWhoreProps {
    if (!wrapperRef.current) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.getTutorial({
      last,
      payload,
      call: onAttentionWhoreCall,
      wrapperWidth: wrapperRef.current.clientWidth,
      wrapperHeight: wrapperRef.current.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    setTimeout(updateCheckpoint);
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });
  }

  function updateCheckpoint() {
    if (hasAlreadySeenIntro()) return;

    if (!auth) return;
    if (!auth.user) return;
    if (!auth.user.id) return;

    dispatch(
      playerActions.async.update({
        checkpoints: { [CheckpointsEnum.IntroIOCode]: true },
      })
    );
  }

  function hasAlreadySeenIntro() {
    return checkpoints[CheckpointsEnum.IntroIOCode];
  }

  return (
    <>
      {!!animation && (
        <Content
          ref={handle}
          onAnimationEnd={onAnimationEnd}
          className={`animate__animated ${animation}`}
        >
          <div className="body">
            <ul className="abas">
              <li
                className={`${slideActiveIndex === INPUT_SLIDE && "selected"}`}
              >
                input.js
              </li>
              <li
                className={`${slideActiveIndex === OUTPUT_SLIDE && "selected"}`}
              >
                output.js
              </li>
            </ul>

            <div className="slides">
              <Swiper
                slidesPerView={1}
                onSwiper={handleSwiper}
                modules={[IonicSlides]}
                initialSlide={INPUT_SLIDE}
              >
                <SwiperSlide>
                  <div className="slide">
                    {input ? (
                      <ChallengeCode
                        wrap
                        code={input}
                        fontSize="0.9em"
                        language={language}
                      />
                    ) : (
                      <div className="no_input_wrapper">
                        <p>
                          No input code is required for this challenge. Swipe
                          left to check the output code
                        </p>
                      </div>
                    )}
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slide">
                    {output ? (
                      <ChallengeCode
                        wrap
                        code={output}
                        fontSize="0.9em"
                        language={language}
                      />
                    ) : (
                      <div className="no_input_wrapper">
                        <p>
                          No output code is required for this challenge. Swipe
                          right to check the input code
                        </p>
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>

          <AttentionWhores whores={whores} />

          <BubbleHandler
            hide={hideJarvisBubble}
            show={!!attentionWhore.show}
            content={attentionWhore.payload}
            type={attentionWhore.bubbleType}
          />

          <DropZoneAttentionWhore />
          <FormHeader hideBrand close={close} position="bottom" />
        </Content>
      )}
    </>
  );
};

export default IOCodePresentation;
