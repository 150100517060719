// react
import { useState, useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";

// interfaces
import { TipProps } from "interfaces/tip";

// components
import { DropZoneAttentionWhore } from "components/DropZone";
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";

// utils
import interact from "interactjs";
import tipUtils from "utils/code/tipUtils";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface TipsHandlerProps {
  paused: boolean;
  wrapper: HTMLDivElement | null;
}

const TipsHandler = ({ paused, wrapper }: TipsHandlerProps) => {
  const dispatch = useDispatch();
  const challenge = useSelector(s.challenge());
  const playerClassRoom = useSelector(s.playerClassRoom());
  const tipsRef = useRef<TipProps[]>([]);
  const tipsDisabledRef = useRef(playerClassRoom.tipsDisabled);
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());
  const { tip } = challenge;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listen, [tip]);
  useEffect(listenToTips, [challenge.tips]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenToCurrentCode, [challenge.currentCode]);
  useEffect(listenToDisabledTips, [playerClassRoom.tipsDisabled]);

  function listen() {
    if (!tip) return;
    if (!wrapper) return;
    if (tipsDisabledRef.current) return;

    const whore = attentionWhoreUtils.getWarning({
      payload: tip,
      call: onAttentionWhoreCall,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });

    setWhores([...whores, whore]);
  }

  function listenToCurrentCode() {
    if (!challenge.currentCode) return;
    handleTips(challenge.currentCode);
  }

  function handleTips(code: string) {
    setTimeout(() => {
      const tips = tipsRef.current;
      const found = tipUtils.find(tips, code);

      if (!found) return;
      tipsRef.current = tipUtils.remove(tips, found.id);

      dispatch(
        challengeActions.update({
          tip: found.tip,
        })
      );
    });
  }

  function listenToTips() {
    tipsRef.current = challenge.tips;
  }

  function listenToDisabledTips() {
    tipsDisabledRef.current = playerClassRoom.tipsDisabled;
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });
  }

  return (
    <>
      <BubbleHandler
        hide={hideJarvisBubble}
        show={!!attentionWhore.show}
        content={attentionWhore.payload}
        type={attentionWhore.bubbleType}
      />

      <AttentionWhores
        disableInactive
        whores={whores}
        paused={paused}
        clear={challenge.flowDone}
        disabledDrop={!!attentionWhore.show}
        draggableClassName="drag_whore_dropzone_tip"
        droppableAcceptClassName="drag_whore_droppable_tip"
        interactDropZone={interact(".drag_whore_dropzone_tip")}
        interactDraggable={interact(".drag_whore_dropzone_tip")}
      />

      <DropZoneAttentionWhore dropZoneClassName="drag_whore_dropzone_tip" />
    </>
  );
};

export default TipsHandler;
