// redux
import { appActions } from "redux/slices/appSlice";
import { initialState } from "redux/slices/challengeSlice";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// interfaces
import { ChallengeProps } from "interfaces/challenge";

export const challengeRawSlice = createSlice({
  name: "challengeRaw",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<ChallengeProps>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const challengeRawActions = challengeRawSlice.actions;
export default challengeRawSlice.reducer;
