// react
import { useState, useEffect } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";

// components
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// enums
import { CheckpointsEnum } from "enums/checkpointsEnum";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";

// utils
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface TipsHandlerProps {
  clear: boolean;
  paused: boolean;
  wrapper: HTMLDivElement | null;
}

const WelcomeIntroHandle = ({ clear, paused, wrapper }: TipsHandlerProps) => {
  const dispatch = useDispatch();
  const auth = useSelector(s.auth());
  const checkpoints = useSelector(s.checkpoints());
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listen, []);

  function listen() {
    if (!wrapper) return;
    if (hasAlreadySeenIntro()) return;

    first();
  }

  function first() {
    const payload = `Welcome to your first intro! Don't worry, we're in safe mode now. From time to time, I'll guide you step by step. Feel free to click the question mark icon (if available) for more information. You can also enable or disable tips anytime you like.`;
    setWhores([getWhore(payload)]);
  }

  function getWhore(payload: string, last?: boolean): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.getTutorial({
      last,
      payload,
      call: onAttentionWhoreCall,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    setTimeout(updateCheckpoint);
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });
  }

  function updateCheckpoint() {
    if (hasAlreadySeenIntro()) return;

    if (!auth) return;
    if (!auth.user) return;
    if (!auth.user.id) return;

    dispatch(
      playerActions.async.update({
        checkpoints: { [CheckpointsEnum.IntroWelcome]: true },
      })
    );
  }

  function hasAlreadySeenIntro() {
    return checkpoints[CheckpointsEnum.IntroWelcome];
  }

  return (
    <>
      <BubbleHandler
        hide={hideJarvisBubble}
        show={!!attentionWhore.show}
        content={attentionWhore.payload}
        type={attentionWhore.bubbleType}
      />

      <AttentionWhores
        disableInactive
        clear={clear}
        whores={whores}
        paused={paused}
        disabledDrop={!!attentionWhore.show}
      />
    </>
  );
};

export default WelcomeIntroHandle;
