// react
import React, { useEffect, useRef } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// ionic-react
import { IonRippleEffect } from "@ionic/react";

// components
import JarvisSpeech from "components/customize/JarvisSpeech";

// interfaces
import { ChallengeProps } from "interfaces/challenge";

// utils
import color from "styles/color";
import styled from "styled-components";

const UnlockButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  background: pink;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  color: ${color.yellow};
  background: ${color.grey.dark};

  span {
    margin-left: 10px;
  }
`;

const UnlockButton = () => {
  const payment = useSelector(s.payment());

  function unlock() {
    window.location.href = payment.url;
  }

  return (
    <UnlockButtonWrapper
      onClick={unlock}
      className="animate__animated animate__backInRight ion-activatable"
    >
      <IonRippleEffect style={{ color: color.yellow }} />
      <span className="blink">Click here to unlock</span>
    </UnlockButtonWrapper>
  );
};

const TRIAL_DAYS = 5;

const SUCCESS_MESSAGE = [
  "Well done!",
  "Great job!",
  "Nice work!",
  "Awesome!",
  "Fantastic!",
  "Bravo!",
  "Kudos!",
  "You did it!",
  "Amazing!",
  "Congratulations!",
  "Hats off to you!",
  "Cheers to that!",
  "Good for you!",
  "Way to go!",
  "Impressive!",
  "That's impressive!",
  "You rock!",
  "High five!",
  "You nailed it!",
  "Well played!",
  "That's the spirit!",
  "Superb!",
  "Outstanding!",
  "You're a star!",
  "Keep it up!",
  "Phenomenal!",
  "Incredible!",
  "Terrific!",
  "Spectacular!",
  "Excellent!",
  "Stellar work!",
  "You're amazing!",
  "Top-notch!",
  "Fabulous!",
  "What a win!",
  "Remarkable!",
  "You crushed it!",
  "Brilliant!",
  "Perfect execution!",
  "Gold star for you!",
  "You've got this!",
  "Killing it!",
  "Simply awesome!",
  "Pure genius!",
  "Fantastic effort!",
  "Legendary!",
  "Above and beyond!",
  "You're unstoppable!",
  "Keep shining!",
  "On point!",
  "That's how it's done!",
  "Epic!",
  "Masterful!",
];

const SUCCESS_GOOD_MESSAGE = [
  "Well done, my dear!",
  "Splendid job, young one!",
  "Bravo, truly remarkable!",
  "Outstanding work, my friend!",
  "Marvelous achievement!",
  "You've done us proud!",
  "Kudos, fine effort indeed!",
  "You've outdone yourself, splendid!",
  "A hearty congratulations to you!",
  "Good on you, truly well done!",
  "Hats off to you, splendid effort!",
  "Excellent work, my congratulations!",
  "I tip my hat to you, remarkable!",
  "Jolly good work, bravo!",
  "Well played, my lad/lass!",
  "You've truly excelled, well done!",
  "Fine work, my hearty applause!",
  "Magnificent effort, bravo indeed!",
  "My sincerest congratulations!",
  "Outstanding effort, a real triumph!",
  "You've made quite an impression!",
  "Capital job, well done!",
  "Remarkable feat, splendid work!",
  "Cheers to your success, bravo!",
  "What a performance, my dear!",
  "Wonderful work, truly inspiring!",
  "You've earned my deepest respect!",
  "A standing ovation for you!",
  "My warmest congratulations!",
  "You've set the bar high, excellent!",
  "Great accomplishment, old sport!",
  "Top-notch work, truly top-notch!",
  "My compliments, you've shone bright!",
  "Remarkable progress, my hearty cheers!",
  "Fine job, my congratulations to you!",
  "You've exceeded all expectations!",
  "Brilliant work, young fellow!",
  "Exceptional effort, I'm impressed!",
  "A crowning achievement, bravo!",
  "You've done us all proud!",
  "What a remarkable achievement!",
  "You've truly outshone everyone!",
  "My heart swells with pride for you!",
  "You've earned your laurels!",
  "Such a splendid display of effort!",
  "An extraordinary feat, bravo!",
  "A job well done, indeed!",
  "You've surpassed all expectations!",
  "What a triumph, magnificent work!",
  "Truly a stellar performance!",
  "My deepest congratulations to you!",
  "A triumph of the highest order!",
  "Well-earned applause, dear friend!",
  "You've shown true mettle, bravo!",
  "Outstanding execution, remarkable!",
  "My profound admiration for your work!",
  "You've reached new heights, splendid!",
  "What a masterstroke, well done!",
  "A grand success, my hearty cheers!",
  "Your effort warms my heart, bravo!",
  "A victory worth celebrating!",
  "You've achieved greatness, my friend!",
  "You're the toast of the town!",
  "A fine display of perseverance!",
  "My most sincere congratulations to you!",
  "What an exceptional milestone!",
  "You've truly raised the bar, bravo!",
  "A fantastic effort, old chap!",
  "Quite the accomplishment, splendid indeed!",
  "My hat is off to you, marvelous!",
  "Such an awe-inspiring result!",
  "Your brilliance knows no bounds!",
  "A remarkable achievement, well done!",
  "Superb effort, well-earned success!",
  "My most heartfelt congratulations!",
  "A beacon of excellence, bravo!",
  "The mark of true brilliance!",
  "A victory worthy of celebration!",
  "You've proven your worth, splendid!",
  "A masterful performance, well done!",
  "What a triumph, extraordinary work!",
  "Such an admirable accomplishment!",
  "Your diligence shines through, bravo!",
  "A landmark achievement, indeed!",
  "The fruit of your labor is astounding!",
  "My utmost respect for your success!",
  "A true champion's achievement!",
  "What a glorious outcome, bravo!",
  "A job of the highest caliber!",
  "A most impressive success story!",
  "A momentous occasion, well earned!",
  "A feat deserving of endless applause!",
  "An accomplishment for the ages, bravo!",
];

const ERROR_MESSAGE = [
  "Oops!",
  "Whoops!",
  "My bad!",
  "Sorry about that!",
  "Well, that's awkward.",
  "Yikes!",
  "That didn't go as planned!",
  "Looks like we hit a bump.",
  "Hmm, not quite right.",
  "Oh no!",
  "A little hiccup!",
];

const ERROR_SECOND_MESSAGE = [
  "He got what he deserved.",
  "His actions led to this.",
  "It's fine, he earned it.",
  "His foolishness caused this.",
  "He made a bad call, paid the price.",
  "He earned his fate.",
  "He took the risk, now it's done.",
  "He brought it on himself.",
  "His decisions sealed his fate.",
  "He acted recklessly and faced it.",
  "He caused this with his choices.",
  "He created his own problems.",
  "He was careless and faced it.",
  "He played stupid games, got stupid prizes.",
  "He earned what he got.",
  "His fate was his doing.",
  "He knew better but didn't act.",
  "He was foolish and faced it.",
  "He sowed the seeds of his downfall.",
  "He's responsible for his fate.",
];

const OLD_SCHOOL_FAREWELLS = [
  "Till tomorrow!",
  "Catch you on the morrow!",
  "See you on the morrow!",
  "Till we meet again!",
  "Until tomorrow, my friend!",
  "Tomorrow, then!",
  "Fare thee well till dawn!",
  "Until the next sun rises!",
  "To the morrow!",
  "We shall meet anon!",
  "Until next we meet!",
  "Until the morrow's light!",
  "Good morrow to come!",
  "See you with the morning light!",
  "Till the sun greets us anew!",
  "Tomorrow awaits us!",
  "Meet you on the next dawn!",
  "Adieu till morn!",
  "Until the day breaks!",
  "Until sunrise!",
  "We'll meet at first light!",
  "The morrow is ours!",
  "Farewell till the next day!",
  "See you bright and early!",
  "Onward to tomorrow!",
  "See you come dawn!",
  "On the next sunrise!",
  "Until another day!",
  "We'll meet by and by!",
  "Farewell till daybreak!",
  "The morrow will bring us together!",
  "Till the break of day!",
  "We'll meet at the next light!",
  "Till the sun rises again!",
  "Catch you with the rooster's crow!",
  "See you as the sun rises!",
  "Until the morning comes!",
  "Until the morrow's dawn!",
  "The light of day shall see us meet!",
  "Meet you on the new day!",
  "Until the earth turns once more!",
  "Till the coming dawn!",
  "Till we greet another day!",
  "Till the morning dew falls!",
  "Until we greet the sun again!",
  "Farewell until day's return!",
  "Until the rooster calls!",
  "Until day greets us again!",
  "Tomorrow's dawn will see us!",
];

interface ChallengeFirstMessageHandlerProps {
  challenge: ChallengeProps;
}

const ChallengeFlowFinishedMessageHandler = ({
  challenge,
}: ChallengeFirstMessageHandlerProps) => {
  const auth = useSelector(s.auth());
  const playerClassRoom = useSelector(s.playerClassRoom());
  const checkpointRef = useRef(0);
  const trialDaysLeft = useRef(0);
  const [result, setResult] = React.useState<{
    success: boolean;
    failed: boolean;
  }>({ success: false, failed: false });

  const { flowFinished, flowSuccess, flowFailed } = challenge;

  useEffect(listenToPlayerClassRoom, [playerClassRoom.checkpoint]);

  useEffect(bootstrap, [
    flowFailed,
    flowSuccess,
    flowFinished,
    playerClassRoom.checkpoint,
  ]);

  function bootstrap() {
    if (!flowFinished) return;
    if (flowSuccess) setResult({ success: true, failed: false });
    if (flowFailed) setResult({ success: false, failed: true });
  }

  function listenToPlayerClassRoom() {
    if (checkpointRef.current) return;
    if (trialDaysLeft.current) return;
    if (!playerClassRoom.checkpoint) {
      checkpointRef.current = 1;
      trialDaysLeft.current = TRIAL_DAYS - 1;

      return;
    }

    checkpointRef.current = playerClassRoom.checkpoint + 1;
    trialDaysLeft.current = TRIAL_DAYS - (playerClassRoom.checkpoint + 1);
  }

  function getSuccessMessage() {
    return SUCCESS_MESSAGE[Math.floor(Math.random() * SUCCESS_MESSAGE.length)];
  }

  function getSecondSuccessMessage() {
    return getGoodSuccessMessage();
  }

  function getGoodSuccessMessage() {
    return SUCCESS_GOOD_MESSAGE[
      Math.floor(Math.random() * SUCCESS_GOOD_MESSAGE.length)
    ];
  }

  function getErrorMessage() {
    return ERROR_MESSAGE[Math.floor(Math.random() * ERROR_MESSAGE.length)];
  }

  function getErrorSecondMessage() {
    return ERROR_SECOND_MESSAGE[
      Math.floor(Math.random() * ERROR_SECOND_MESSAGE.length)
    ];
  }

  function getOldSchoolsFarewells() {
    return OLD_SCHOOL_FAREWELLS[
      Math.floor(Math.random() * OLD_SCHOOL_FAREWELLS.length)
    ];
  }

  if (!flowFinished) return null;

  return (
    <>
      {/* ANONYMOUS */}
      {auth.anonymous ? (
        <>
          {result.success && (
            <JarvisSpeech
              top="130px"
              delay={3000}
              content={[getSuccessMessage(), getSecondSuccessMessage()]}
            />
          )}

          {result.failed && (
            <JarvisSpeech
              top="130px"
              delay={3000}
              content={[getErrorMessage(), getErrorSecondMessage()]}
            />
          )}
        </>
      ) : (
        <>
          {/* SIGNED */}

          {auth.subscribed ? (
            <>
              {result.success && (
                <JarvisSpeech
                  top="130px"
                  delay={3000}
                  content={[
                    getSuccessMessage(),
                    getSecondSuccessMessage(),
                    getOldSchoolsFarewells(),
                  ]}
                />
              )}

              {result.failed && (
                <JarvisSpeech
                  top="130px"
                  delay={3000}
                  content={[
                    getErrorMessage(),
                    getErrorSecondMessage(),
                    getOldSchoolsFarewells(),
                  ]}
                />
              )}
            </>
          ) : trialDaysLeft.current > 0 ? (
            <>
              {result.success && (
                <JarvisSpeech
                  top="130px"
                  delay={3000}
                  content={[
                    getSuccessMessage(),
                    getSecondSuccessMessage(),
                    `Just ${trialDaysLeft.current} trial days left. See you tomorrow?`,
                  ]}
                />
              )}

              {result.failed && (
                <JarvisSpeech
                  top="130px"
                  delay={3000}
                  content={[
                    getErrorMessage(),
                    getErrorSecondMessage(),
                    `Just ${trialDaysLeft.current} trial days left. See you tomorrow?`,
                  ]}
                />
              )}
            </>
          ) : (
            <>
              {result.success && (
                <JarvisSpeech
                  top="130px"
                  delay={3000}
                  EndHtmlElement={<UnlockButton />}
                  content={[
                    getSuccessMessage(),
                    `Your trial has ended. Unlock access to continue learning and growing.`,
                  ]}
                />
              )}

              {result.failed && (
                <JarvisSpeech
                  top="130px"
                  delay={3000}
                  EndHtmlElement={<UnlockButton />}
                  content={[
                    getErrorMessage(),
                    `Your trial has ended. Unlock access to continue learning and growing.`,
                  ]}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ChallengeFlowFinishedMessageHandler;
