// react
import { IonProgressBar } from "@ionic/react";

// utils
import color from "styles/color";
import styled from "styled-components";

interface ProgressBarStatusProps {
  bgColor: string;
}

const ProgressBar = styled(IonProgressBar)<ProgressBarStatusProps>`
  height: 6px;
  opacity: 0.6;
  --background: ${color.grey.stronger};
  --progress-background: ${({ bgColor }) => bgColor} !important;
`;

interface ProgressStatusProps {
  color: string;
  value: number;
}

const ProgressStatus = ({ color, value }: ProgressStatusProps) => {
  return <ProgressBar value={value} bgColor={color} />;
};

export default ProgressStatus;
