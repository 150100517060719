// interfaces
import {
  SubscriberProps,
  SubscriberResourceProps,
} from "interfaces/subscriber";
import JarvisEntity from "entities/characters/JarvisEntity";

function map(subscriber: SubscriberResourceProps): SubscriberProps {
  return {
    id: subscriber.id,
    trial: !!subscriber.trial,
    checkpoint: subscriber.checkpoint,
    jarvisName: subscriber.jarvisName || JarvisEntity.unknownName,
  };
}

const subscriberParser = {
  map,
};

export default subscriberParser;
