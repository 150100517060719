class JarvisEntity {
  _name: string;
  static defaultName = "Gaspar Turing";
  static unknownName = "Unknown Voice";

  constructor({ name }: { name: string }) {
    this._name = name;
  }

  has(): boolean {
    return !!(this._name && this._name !== JarvisEntity.unknownName);
  }

  get name() {
    return this._name;
  }
}

export default JarvisEntity;
