// react
import { useState, useEffect, useRef } from "react";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";
import { ChallengeProps, IOCodeProps } from "interfaces/challenge";

// components
import ChallengeButton from "handlers/challengeButtons/ChallengeButton";
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// presentations
import IOCodePresentation from "handlers/challengeButtons/ioCode/IOCodePresentation";

// utils
import color from "styles/color";
import isEmpty from "lodash/isEmpty";
import animationUtils from "utils/animationUtils";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface IODataProps {
  input: IOCodeProps | undefined;
  output: IOCodeProps | undefined;
}

interface LabsHandlerProps {
  paused: boolean;
  challenge: ChallengeProps;
  wrapper: HTMLDivElement | null;
}

const IOCodeHandler = ({ paused, wrapper, challenge }: LabsHandlerProps) => {
  const { input, output, flowInit, flowFinished } = challenge;
  const inputRef = useRef<IOCodeProps>();
  const outputRef = useRef<IOCodeProps>();
  const [animation, setAnimation] = useState("");
  const [IOData, setIOData] = useState<IODataProps>({
    input: undefined,
    output: undefined,
  });
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [flowInit]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(buttonZoomOut, [flowFinished]);

  function bootstrap() {
    if (!flowInit) return;
    if (IOData.input) return;
    if (IOData.output) return;
    if (isEmpty(input) && isEmpty(output)) return;

    inputRef.current = input && input[0];
    outputRef.current = output && output[0];

    setIOData({
      input: inputRef.current,
      output: outputRef.current,
    });

    setTimeout(displayWhores, 800);
  }

  function displayWhores() {
    setWhores([getWhore()]);
  }

  function buttonZoomOut() {
    if (!animation) return;
    if (!flowFinished) return;
    if (animation === "animate__zoomOut") return;

    setAnimation("animate__zoomOut");
  }

  function getWhore(): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();
    if (!inputRef.current && !outputRef.current)
      return attentionWhoreUtils.fake();

    return attentionWhoreUtils.getIO({
      last: true,
      payload: "",
      call: onAttentionWhoreCall,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function finishedPresentation() {
    displayButton();
    setAttentionWhore({ ...attentionWhore, show: false });
  }

  function displayButton() {
    setAnimation(animationUtils.getRandom());
  }

  function hideButton() {
    setTimeout(() => setAnimation("animate__zoomOut"));
  }

  function onClick() {
    if (animation === "animate__zoomOut") return;

    displayWhores();
    setTimeout(hideButton);
  }

  return (
    <>
      {!!animation &&
        !!(
          (IOData.input && IOData.input.data) ||
          (IOData.output && IOData.output.data)
        ) && (
          <ChallengeButton
            bottom={230}
            onClick={onClick}
            color={color.pink}
            label={
              <span className="nerdfy_pink pixelify_font_family">code</span>
            }
            customClassName={`animate__animated ${animation}`}
          />
        )}

      <IOCodePresentation
        animationEnd={finishedPresentation}
        input={(IOData.input && IOData.input.data) || ""}
        output={(IOData.output && IOData.output.data) || ""}
        language={(IOData.input && IOData.input.programmingLanguage) || ""}
        show={
          !!attentionWhore.show &&
          !!(
            (IOData.input && IOData.input.data) ||
            (IOData.output && IOData.output.data)
          )
        }
      />

      <AttentionWhores
        paused={paused}
        whores={whores}
        clear={flowFinished}
        disabledDrop={!!attentionWhore.show}
      />
    </>
  );
};

export default IOCodeHandler;
