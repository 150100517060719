// react
import { useState, useRef, useEffect } from "react";

// svg
import CheckSvg from "svg/CheckSvg";

// components
import Typewriter from "components/Typewriter";
import AvatarJarvis from "components/avatars/AvatarJarvis";

// utils
import color from "styles/color";
import { isEmpty } from "lodash";
import styled from "styled-components";

const Wrapper = styled.div<{ top: string }>`
  position: absolute;
  top: ${({ top }) => top};
  left: 0;
  display: flex;
  z-index: 1001;
  padding: 0 25px;

  .avatar_jarvis {
    margin: 40px 20px 0 0;
  }

  .speeches {
    display: flex;
    flex-direction: column;

    .speech {
      padding: 20px;
      border-radius: 10px;
      color: ${color.grey.light};
      background: ${color.grey.dark};
      position: relative;
      min-width: 80px;
      margin-bottom: 10px;
      position: relative;

      .check {
        display: inline-block;
        position: absolute;
        bottom: 5px;
        right: 8px;
      }

      span {
        line-height: 1.8 !important;
      }

      span.dots {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translate(-50%, -50%);
        color: ${color.yellow};
      }
    }
  }
`;

interface JarvisSpeechProps {
  content: string[];
  top?: string;
  delay?: number;
  onFinished?: () => void;
  EndHtmlElement?: JSX.Element;
}

const DEFAULT_DELAY = 700;

const JarvisSpeech = ({
  content,
  onFinished,
  top = "110px",
  EndHtmlElement,
  delay = DEFAULT_DELAY,
}: JarvisSpeechProps) => {
  const counter = useRef(0);
  const [speeches, setSpeeches] = useState<string[]>([]);
  const [started, setStarted] = useState<{ [key: string]: boolean }>({});
  const [finished, setFinished] = useState<{
    all: boolean;
    [key: string]: boolean;
  }>({ all: false });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleSpeeches, [content]);

  function handleSpeeches() {
    if (isEmpty(content)) return;
    if (!isEmpty(speeches)) return;

    setSpeeches([content[counter.current]]);
  }

  function onFinishedSpeech() {
    counter.current += 1;

    if (hasSpeechContentFinished(counter.current)) {
      finishedSpeech(counter.current - 1, true);

      counter.current = 0;
      onFinished && setTimeout(onFinished);

      return;
    }

    finishedSpeech(counter.current - 1);
    setSpeeches([...speeches, content[counter.current]]);
  }

  function finishedSpeech(index: number, all = false) {
    setFinished({ ...finished, all, [index]: true });
  }

  function startedSpeech(index: number) {
    setStarted({ ...started, [index]: true });
  }

  function hasSpeechContentFinished(counter: number) {
    return counter >= content.length;
  }

  return (
    <Wrapper
      top={top}
      className="animate__animated no-swipe animate__bounceInLeft"
    >
      <div className="avatar_jarvis animate_y_infinite">
        <AvatarJarvis />
      </div>

      <div className="speeches">
        {speeches.map((speech, index) => (
          <div key={index} className="speech">
            {!started[index] && <span className="dots">...</span>}
            <Typewriter
              content={speech}
              finished={onFinishedSpeech}
              started={startedSpeech.bind(null, index)}
              delay={index === 0 ? delay : DEFAULT_DELAY}
            />

            {finished[index] ? (
              <span className="check animate__animated animate__zoomIn">
                <CheckSvg color={color.green} />
              </span>
            ) : null}
          </div>
        ))}

        {!!EndHtmlElement && finished.all && EndHtmlElement}
      </div>
    </Wrapper>
  );
};

export default JarvisSpeech;
