// react
import { IonContent, IonPage } from "@ionic/react";
import { useState, useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { authActions } from "redux/slices/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";
import { subscriberActions } from "redux/slices/subscriberSlice";

// components
import Sign from "components/sign/Sign";
import Loading from "components/Loading";
import BrandLogo from "components/BrandLogo";
import Header from "components/header/Header";
import FirebaseSync from "firebaseSync/FirebaseSync";
import HeaderMenu from "components/header/HeaderMenu";
import ExceptionError from "components/ExceptionError";
import ButtonPremium from "components/buttons/ButtonBuy";
import PressButtonHandler from "handlers/PressButtonHandler";

// svg
import PremiumSvg from "svg/PremiumSvg";

// services
import SubscriberService from "services/SubscriberService";

// interfaces
import { ChallengeProps } from "interfaces/challenge";
import { ChallengeSectionProps } from "interfaces/challengeSection";

// handlers
import ChallengeHandler from "handlers/ChallengeHandler";
import PlayerStreakHandler from "handlers/PlayerStreakHandler";
import FetchClassRoomDataHandler from "handlers/FetchClassRoomDataHandler";

// parsers
import subscriberParser from "parsers/subscriberParser";

// utils
import color from "styles/color";
import styled from "styled-components";
import redditPixelUtils from "utils/redditPixelUtils";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .logo_wrapper {
    display: flex;
    align-items: center;

    .premium_icon {
      opacity: 0.3;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  --ion-background-color: ${color.dark.bg};

  .button_wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;

// TODO: O classRoomId deve ser dinâmico
const CLASSROOM_DEFAULT_ID = 1;

const ChallengePage = () => {
  const dispatch = useDispatch();
  const auth = useSelector(s.auth());
  const payment = useSelector(s.payment());
  const classRoom = useSelector(s.classRoom());
  const sections = useSelector(s.challengeSections());
  const challengesHash = useSelector(s.challengesHash());
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isChallengeModalOpen, setIsChallengeModalOpen] = useState(false);
  const sectionsRef = useRef<ChallengeSectionProps[]>([]);
  const challengesHashRef = useRef<any>();
  const alreadyOpenAutomatically = useRef(false);
  const alreadyCheckedIfSubscribed = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleSections, [sections]);
  useEffect(handleChallengesHash, [challengesHash]);
  useEffect(listenToCloseChallengeModal, [isChallengeModalOpen]);

  useEffect(() => {
    redditPixelUtils.init();

    if (!auth) return;
    if (!auth.signed) return;

    if (auth.anonymous || !auth.user) {
      redditPixelUtils.viewContent();
      return;
    }

    redditPixelUtils.viewContent({
      email: auth.user.email,
      externalId: String(auth.user.id),
    });
  }, [auth]);

  useEffect(() => {
    handleSubscriber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, classRoom]);

  useEffect(() => {
    dispatch(subscriberActions.async.updateJarvisName());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSections() {
    sectionsRef.current = sections;
  }

  async function handleSubscriber() {
    if (!auth) return;
    if (!auth.signed) return;
    if (auth.anonymous) return;
    if (alreadyCheckedIfSubscribed.current) return;

    try {
      alreadyCheckedIfSubscribed.current = true;

      const resource = new SubscriberService();
      const { data } = await resource.get(CLASSROOM_DEFAULT_ID);

      if (data) {
        data.trial
          ? dispatch(authActions.unsubscribe())
          : dispatch(authActions.subscribe());
        dispatch(subscriberActions.set(subscriberParser.map(data)));
      } else dispatch(authActions.unsubscribe());
    } catch (_err) {}
  }

  function listenToCloseChallengeModal() {
    if (isChallengeModalOpen) return;
    alreadyOpenAutomatically.current = false;
  }

  function handleChallengesHash() {
    challengesHashRef.current = challengesHash;
  }

  function getSection(): ChallengeSectionProps | undefined {
    return sectionsRef.current[0];
  }

  function getChallenge(
    section: ChallengeSectionProps | undefined
  ): ChallengeProps | undefined {
    if (!section) return undefined;

    const challengesFromSection = (challengesHashRef.current[section.id] ||
      []) as ChallengeProps[];

    return challengesFromSection[0] || undefined;
  }

  function onOrderModalDismissed() {
    setIsSignInModalOpen(false);
  }

  function onOrderModalFinished() {
    setIsSignInModalOpen(false);
    openChallengeModalAutomatically(false);
  }

  function onStart() {
    setLoading(true);
  }

  function errorCallback(_error: unknown) {
    setLoading(false);
    setHasError(true);
  }

  function successCallback() {
    setTimeout(() => setLoading(false), 350);

    setChallenge();
    openChallengeModalAutomatically(isSignInModalOpen);
  }

  function setChallenge() {
    if (!auth.signed) return;

    const section = getSection();
    if (!section) return;

    const challenge = getChallenge(section);
    if (!challenge) return;

    dispatch(challengeActions.set(challenge));
  }

  function openChallengeModalAutomatically(isSignInModalOpen: boolean) {
    if (loading) return;
    if (isSignInModalOpen) return;
    if (alreadyOpenAutomatically.current) return;

    setTimeout(openChallengeModal, 250);
    alreadyOpenAutomatically.current = true;
  }

  function openChallengeModal() {
    if (!auth.signed) return;
    if (isChallengeModalOpen) return;

    setIsChallengeModalOpen(true);
    setTimeout(() => {
      const section = getSection();
      const challenge = getChallenge(section);

      if (!section) return;
      if (!challenge) return;

      dispatch(challengeActions.async.bootstrapModal(challenge, section));
    });
  }

  function openSignInModal() {
    setIsSignInModalOpen(true);
  }

  function unlock() {
    window.location.href = payment.url;
  }

  return (
    <Wrapper>
      <HeaderMenu signed={auth.signed} />

      <IonPage id="mainContent">
        <Header
          logo={
            <div className="logo_wrapper">
              <BrandLogo />

              {auth.signed && auth.subscribed && !auth.anonymous && (
                <div className="premium_icon">
                  <PremiumSvg color={color.yellow} />
                </div>
              )}
            </div>
          }
          secondaryButton={
            auth.signed && !auth.subscribed && !auth.anonymous ? (
              <ButtonPremium onClick={unlock} />
            ) : undefined
          }
        />
        <Loading loading={loading} />

        <IonContent>
          <Content>
            <div className="button_wrapper">
              {hasError ? (
                <ExceptionError />
              ) : (
                <PressButtonHandler
                  auth={auth}
                  loading={loading}
                  onClickStart={openSignInModal}
                  onClickChallenge={openChallengeModal}
                />
              )}
            </div>

            {!!(auth.firebaseSigned && auth.user && classRoom.id) && (
              <FirebaseSync user={auth.user} classRoomId={classRoom.id} />
            )}

            <Sign
              isOpen={isSignInModalOpen}
              close={onOrderModalDismissed}
              finished={onOrderModalFinished}
            />

            {/* HANDLERS */}

            <ChallengeHandler
              sections={sections}
              isChallengeQuizModalOpen={false}
              openChallengeQuizModal={() => {}}
              challengesMerged={challengesHash}
              toggleChallengeQuizModal={() => {}}
              openChallengeModal={openChallengeModal}
              isChallengeModalOpen={isChallengeModalOpen}
              toggleChallengeModal={setIsChallengeModalOpen}
            />

            <PlayerStreakHandler />

            <FetchClassRoomDataHandler
              auth={auth}
              languageId={1}
              classRoomId={1}
              onStart={onStart}
              errorCallback={errorCallback}
              successCallback={successCallback}
            />

            {/* HANDLERS END */}
          </Content>
        </IonContent>
      </IonPage>
    </Wrapper>
  );
};

export default ChallengePage;
