// react
import { useRef, useState, useCallback, useEffect } from "react";

// components
import Wizard from "components/Wizard";

// enums
import { WizardTypeEnum } from "enums/wizardEnum";

interface WizardBoxProps {
  type: WizardTypeEnum;
}

const WizardBox = ({ type }: WizardBoxProps) => {
  const intervalRef = useRef<NodeJS.Timeout>();
  const [wrapper, setWrapper] = useState<HTMLDivElement | undefined>();
  const handle = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    setInterval(() => {
      if (!node.clientWidth) return;
      if (!node.clientHeight) return;

      setWrapper(node);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => destroyComponent, []);

  function destroyComponent() {
    const ref = intervalRef.current;
    if (!ref) return;

    clearInterval(ref);
  }

  return (
    <div ref={handle} className="wizard_box">
      {wrapper && <Wizard wrapper={wrapper} type={type} bottom={10} />}
    </div>
  );
};

export default WizardBox;
