// components
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// icons
import { IconWhoreIcon } from "components/iconWhores/IconWhoreInfo";

// utils
import styled from "styled-components";

const Button = styled(ButtonRippleEffect)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 10;

  .opacity {
    opacity: 0.2;
  }
`;

interface ButtonPlayPauseToggleProps {
  enabled: boolean;
  enable: () => void;
  disable: () => void;
  disabled?: boolean;
}

const ButtonTipToggle = ({
  enable,
  enabled,
  disable,
  disabled,
}: ButtonPlayPauseToggleProps) => {
  return (
    <Button
      color="#242424"
      disabled={disabled}
      onClick={enabled ? disable : enable}
    >
      <IconWhoreIcon
        className={`jarvis_alert jarvis_alert_middle pixelify_font_family ${
          enabled ? "nerdfy_yellow opacity" : "nerdfy_grey_stronger"
        }`}
      />
    </Button>
  );
};

export default ButtonTipToggle;
