// utils
import isEmpty from "lodash/isEmpty";
import { AllToOptional } from "./transformTypeUtils";

const PIXEL_ID = "a2_gad11ok36pgg";
type optionalRedditProps = AllToOptional<RedditProps>;

// flags
let alreadyInit = false;
let alreadySignedUp = false;
let alreadyPageVisit = false;
let alreadyViewContent = false;

// https://business.reddithelp.com/s/article/manual-conversion-events-with-the-reddit-pixel
interface RedditProps {
  email: string;
  externalId: string; // An advertiser-assigned custom identifier. We recommend passing an external ID with its corresponding click ID whenever possible.
  aaid?: string; // A unique identifier associated with individual devices running the Android operating system.
  idfa?: string; // A unique identifier associated with individual devices running Apple’s iOS operating system.
}

function init(config?: optionalRedditProps) {
  if (!allow()) return;
  if (alreadyInit) return;

  alreadyInit = true;
  const mapped = config ? map(config) : undefined;

  if (isEmpty(mapped)) {
    rdt("init", PIXEL_ID);
    return;
  }

  rdt("init", PIXEL_ID, mapped);
}

function pageVisit(config?: optionalRedditProps) {
  if (!allow()) return;
  if (alreadyPageVisit) return;

  alreadyPageVisit = true;
  const mapped = config ? map(config) : undefined;

  if (isEmpty(mapped)) {
    rdt("track", "PageVisit");
    return;
  }

  rdt("track", "PageVisit", mapped);
}

function viewContent(config?: optionalRedditProps) {
  if (!allow()) return;
  if (alreadyViewContent) return;

  alreadyViewContent = true;
  const mapped = config ? map(config) : undefined;

  if (isEmpty(mapped)) {
    rdt("track", "ViewContent");
    return;
  }

  rdt("track", "ViewContent", mapped);
}

function signUp(config?: optionalRedditProps) {
  if (!allow()) return;
  if (alreadySignedUp) return;

  alreadySignedUp = true;
  const mapped = config ? map(config) : undefined;

  if (isEmpty(mapped)) {
    rdt("track", "SignUp");
    return;
  }

  rdt("track", "SignUp", mapped);
}

// private

function map(config: optionalRedditProps): optionalRedditProps {
  const mapped: optionalRedditProps = {};

  if (config.aaid) mapped.aaid = config.aaid;
  if (config.idfa) mapped.idfa = config.idfa;
  if (config.email) mapped.email = config.email;
  if (config.externalId) mapped.externalId = config.externalId;

  return mapped;
}

function allow() {
  return !!rdt && process.env.REACT_APP_ENV === "production";
}

const redditPixelUtils = {
  init,
  signUp,
  pageVisit,
  viewContent,
};

export default redditPixelUtils;
