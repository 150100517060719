// react
import { useEffect, useRef, useState } from "react";

// redux
import { useDispatch } from "react-redux";
import { tagActions } from "redux/slices/tagSlice";
import { challengeActions } from "redux/slices/challengeSlice";

// factories
import {
  alertAnimate,
  AlertFactory,
  AlertFactoryType,
} from "factories/AlertFactory";

// interfaces
import { TagProps } from "interfaces/tag";
import { ChallengeTypeHandlerCommonProps } from "components/challenge/Challenge";

// handlers
import BittlesHandler from "handlers/BittlesHandler";
import ChallengeBattleProgressHandler from "handlers/ChallengeBattleProgressHandler";

// components
import SudoHandler from "handlers/challengeButtons/sudo/SudoHandler";
import ChallengeBattle from "components/challenge/battle/ChallengeBattle";

// utils
import audioUtils from "utils/audioUtils";
import blinkUtils from "utils/blinkUtils";
import numberUtils from "utils/numberUtils";

const TOTAL_LIFE = 3;

interface ChallengeExerciseProps extends ChallengeTypeHandlerCommonProps {
  getBiggestStepLength(): number;
  blinkElRef: React.MutableRefObject<HTMLDivElement>;
}

const ChallengeExercise = ({
  code,
  close,
  paused,
  challenge,
  wrapperRef,
  blinkElRef,
  isTagValid,
  onSuccessTag,
  handlerWrapperRef,
  wrapperNotifiersRef,
  handlerNotifiersRef,
  getBiggestStepLength,
}: ChallengeExerciseProps) => {
  const dispatch = useDispatch();
  const [missedProgress, setMissedProgress] = useState(1);
  const poisonHpRemainingRef = useRef(getBiggestStepLength());
  const remainingHpRef = useRef(TOTAL_LIFE - challenge.missed);
  const { missed, flowDone, flowSuccess, flowFinished, flowInit } = challenge;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleMissedProgress, [missed, flowDone]);

  function handleMissedProgress() {
    if (!missed) return;

    const progress = Number(((TOTAL_LIFE - missed) / TOTAL_LIFE).toFixed(1));
    setMissedProgress(progress);

    if (progress <= 0 && !flowDone)
      setTimeout(() => dispatch(challengeActions.async.failed()));
  }

  function onAddedTag(tag: TagProps) {
    alertHealPoison();
    onSuccessTag(tag);
  }

  function onFailedTag() {
    blink();

    onFailTag();
    alertLostHp();
  }

  function onFailTag() {
    audioUtils.play.missed();
    dispatch(tagActions.async.missed());
  }

  function blink() {
    if (!blinkElRef.current) return;
    blinkUtils.blinkRed(blinkElRef.current, 100);
  }

  function alertHealPoison() {
    poisonHpRemainingRef.current -= 1;

    alert(
      `-1 infected [${poisonHpRemainingRef.current}]`,
      AlertFactoryType.Warning
    );
  }

  function alertLostHp() {
    remainingHpRef.current -= 1;
    alert(`-1 hp [${remainingHpRef.current}]`, AlertFactoryType.Danger);
  }

  function alert(content: string, type: AlertFactoryType) {
    if (!wrapperRef.current) return;

    alertAnimate({
      factories: [
        AlertFactory({
          type,
          content,
        }),
      ],
      wrapper: wrapperRef.current as HTMLDivElement,
      bounds: {
        top: `${numberUtils.randomInterval(
          100,
          wrapperRef.current.clientHeight - 200
        )}px`,
        left: `${numberUtils.randomInterval(
          100,
          wrapperRef.current.clientWidth - 200
        )}px`,
      },
    });
  }

  return (
    <>
      <ChallengeBattleProgressHandler
        challenge={challenge}
        getBiggestStepLength={getBiggestStepLength}
      />

      <ChallengeBattle
        code={code}
        close={close}
        paused={paused}
        challenge={challenge}
        wrapperRef={wrapperRef}
        isTagValid={isTagValid}
        onFailedTag={onFailedTag}
        onSuccessTag={onAddedTag}
        handlerWrapperRef={handlerWrapperRef}
        handlerNotifiersRef={handlerNotifiersRef}
        wrapperNotifiersRef={wrapperNotifiersRef}
      />

      <SudoHandler
        paused={paused}
        challenge={challenge}
        wrapper={wrapperRef.current}
      />

      <BittlesHandler
        hide={paused}
        init={flowInit}
        missed={missedProgress}
        show={!paused && !flowDone}
        success={flowDone && flowFinished && flowSuccess}
      />
    </>
  );
};

export default ChallengeExercise;
