// react
import React from "react";

// utils
import styled from "styled-components";

const Wrapper = styled.div<{ bgImage: string }>`
  background-image: url(${(props) => props.bgImage});

  &.mad {
    filter: invert(46%) sepia(25%) saturate(1058%) hue-rotate(120deg)
      brightness(99%) contrast(82%);

    background-size: contain;
    animation: blink 0.5s linear infinite;
  }

  @keyframes blink {
    from {
      filter: invert(46%) sepia(25%) saturate(1058%) hue-rotate(120deg)
        brightness(99%) contrast(82%);
    }
    to {
      filter: none;
    }
  }
`;

interface AvatarBittleProps {
  index: number;
  isMad?: boolean;
  customClassName?: string;
  onAnimationEnd?: () => void;
}

const AvatarBittle = ({
  index,
  isMad = false,
  onAnimationEnd,
  customClassName = "",
}: AvatarBittleProps) => {
  return (
    <Wrapper
      onAnimationEnd={onAnimationEnd}
      bgImage={`/assets/imgs/monsters/png/monster${index}.png`}
      className={`nerdfy_avatar ${customClassName} ${isMad && "mad"}`}
    ></Wrapper>
  );
};

function areEqual(prev: AvatarBittleProps, next: AvatarBittleProps) {
  if (!prev) return false;
  if (!next) return false;

  return prev.index === next.index && prev.isMad === next.isMad;
}

export default React.memo(AvatarBittle, areEqual);
