// react
import { useEffect, useRef } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// enums
import { WizardActionEnum, WizardTypeEnum } from "enums/wizardEnum";

// utils
import { forever } from "async";
import { v4 as uuidv4 } from "uuid";
import domUtils from "utils/domUtils";

interface WizardMetaDataProps {
  len: number;
  run: boolean;
  left: number;
  walk: boolean;
  idle: boolean;
  index: number;
  bottom: number;
}

interface WizardMetaInputDataProps {
  bottom: number;
}

interface WizardMetaProps {
  [key: number]: (data: WizardMetaInputDataProps) => WizardMetaDataProps;
}

interface KittenMetaDataEnrichedProps extends WizardMetaDataProps {
  goToRightSide: boolean;
}

const RUN_SPEED = 1;
const RUN_DELAY = 10;
const WALK_SPEED = 0.5;
const IDLE_DELAY = 3000;
const COMPARE_HALF = 0.5;
const ANIMATION_DELAY = 200;
const COMPARE_STOP_IDLE = 0.6;
const COMPARE_STOP_MOVEMENT = 0.1;

const idle_pets = [
  WizardActionEnum.Idle,
  WizardActionEnum.Idle,
  WizardActionEnum.Idle,
  WizardActionEnum.Idle,
  WizardActionEnum.Idle,
  WizardActionEnum.Idle,
  WizardActionEnum.Idle,
  WizardActionEnum.Idle,
  WizardActionEnum.Idle,
  WizardActionEnum.Idle,
  WizardActionEnum.Attack,
  WizardActionEnum.SkillShield,
];
const movement_pets = [WizardActionEnum.Walk];

const wizard_meta: WizardMetaProps = {
  [WizardActionEnum.Idle]: ({ bottom }) => ({
    bottom,
    len: 5,
    left: 50,
    idle: true,
    run: false,
    walk: false,
    index: WizardActionEnum.Idle,
  }),
  [WizardActionEnum.Walk]: ({ bottom }) => ({
    bottom,
    len: 6,
    left: -50,
    run: false,
    walk: true,
    idle: false,
    index: WizardActionEnum.Walk,
  }),
  [WizardActionEnum.Attack]: ({ bottom }) => ({
    bottom,
    len: 7,
    left: 50,
    idle: true,
    run: false,
    walk: false,
    index: WizardActionEnum.Attack,
  }),
  [WizardActionEnum.SkillShield]: ({ bottom }) => ({
    bottom,
    len: 7,
    left: 50,
    idle: true,
    run: false,
    walk: false,
    index: WizardActionEnum.SkillShield,
  }),
};

export const WizardFactory = () => {
  const wrapper = document.createElement("div");
  wrapper.className = "wizard absolute";

  const el = document.createElement("div");
  wrapper.appendChild(el);

  return wrapper;
};

interface KittenProps {
  wrapper: HTMLDivElement | null;
  bottom?: number;
  startRight?: boolean;
  type?: WizardTypeEnum;
  listenMovement?(x: number, y: number): void;
}

const Kitten = ({
  wrapper,
  bottom = 0,
  listenMovement,
  type = WizardTypeEnum.Idle,
}: KittenProps) => {
  const { flowDone } = useSelector(s.challenge());
  const { missed } = useSelector(s.playerClassRoom());
  const alreadyBootstrapped = useRef(false);
  const processIdRef = useRef<string | undefined>();
  const kittenRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => destroyComponent, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleHurtPet, [missed]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleHappyPet, [flowDone]);

  function bootstrap() {
    const meta = getRandomMovementPet();

    setTimeout(() => {
      if (!wrapper) return;

      kittenRef.current = WizardFactory();
      const left = getLeftStartPosition(meta);
      const _meta = { ...meta, left };

      setFirstPosition(kittenRef.current, _meta);
      start(kittenRef.current, _meta);

      wrapper.appendChild(kittenRef.current);
      alreadyBootstrapped.current = true;
    });
  }

  function handleHappyPet() {
    if (!flowDone) return;
    if (!wrapper) return;
    if (!kittenRef.current) return;
    if (!alreadyBootstrapped.current) return;

    killProcess();

    const happy = [WizardActionEnum.Idle];
    const meta = {
      ...wizard_meta[happy[Math.floor(Math.random() * happy.length)]]({
        bottom,
      }),
      goToRightSide: getToRightSideByCurrentPosition(kittenRef.current),
    };

    start(kittenRef.current, meta, true);
    setTimeout(() => {
      start(kittenRef.current as HTMLDivElement, getRandomMovementPet());
    }, 5000);
  }

  function handleHurtPet() {
    if (!missed) return;
    if (!wrapper) return;
    if (!kittenRef.current) return;
    if (!alreadyBootstrapped.current) return;

    killProcess();

    const meta = {
      ...wizard_meta[WizardActionEnum.Idle]({ bottom }),
      goToRightSide: compare(COMPARE_HALF),
    };

    start(kittenRef.current, meta, true);
    setTimeout(() => {
      start(kittenRef.current as HTMLDivElement, getRandomMovementPet());
    }, 500);
  }

  function start(
    el: HTMLDivElement,
    meta: KittenMetaDataEnrichedProps,
    ignoreCheckpoint = false
  ) {
    processIdRef.current = uuidv4();
    animate(el, meta, processIdRef.current);

    meta.run &&
      move(meta)(el, RUN_SPEED, processIdRef.current, ignoreCheckpoint);
    meta.walk &&
      move(meta)(el, WALK_SPEED, processIdRef.current, ignoreCheckpoint);

    return processIdRef.current;
  }

  function setFirstPosition(
    el: HTMLDivElement,
    { bottom, left }: WizardMetaDataProps
  ) {
    el.style.zIndex = "1000";
    el.style.left = `${left}px`;
    el.style.bottom = `${bottom}px`;
  }

  function getLeftStartPosition(meta: KittenMetaDataEnrichedProps) {
    return meta.goToRightSide
      ? -50
      : (wrapper as HTMLDivElement).clientWidth + 50;
  }

  function animate(
    el: HTMLDivElement,
    meta: KittenMetaDataEnrichedProps,
    processId: string
  ) {
    let pos = 0;

    forever(
      (next: () => void) => {
        if (processId !== processIdRef.current) return;

        pos += 1;
        if (pos >= meta.len) pos = 1;

        (el.firstChild as HTMLDivElement).className = `wizard_${
          meta.index
        }_${pos} wizard_${type}_${meta.index}_bg ${
          meta.goToRightSide ? "wizard_scale" : "wizard_flip_horizontal_scale"
        }`;

        setTimeout(next, ANIMATION_DELAY);
      },
      (_err: unknown) => {}
    );
  }

  function move({ goToRightSide }: KittenMetaDataEnrichedProps) {
    return goToRightSide ? moveToRight : moveToLeft;
  }

  function moveToRight(
    el: HTMLDivElement,
    speed = RUN_SPEED,
    processId: string,
    ignoreCheckpoint = false
  ) {
    if (!wrapper) return;

    setTimeout(() => {
      const top = wrapper.clientHeight;
      const barrier = wrapper.clientWidth - 30;

      let checkpoint = 0;
      let left = domUtils.getLeftPosition(el);

      forever(
        (next: () => void) => {
          if (processId !== processIdRef.current) return;

          left += speed;
          checkpoint = left;
          el.style.left = `${left}px`;

          if (listenMovement) listenMovement(left, top);

          if (left >= barrier) return idle(el, false);
          if (
            !ignoreCheckpoint &&
            checkpoint > 1 &&
            checkpoint % 50 === 0 &&
            compare(COMPARE_STOP_MOVEMENT)
          ) {
            checkpoint = 0;
            return idle(el, true);
          }

          setTimeout(next, RUN_DELAY);
        },
        (_err: unknown) => {}
      );
    });
  }

  function moveToLeft(
    el: HTMLDivElement,
    speed = RUN_SPEED,
    processId: string,
    ignoreCheckpoint = false
  ) {
    if (!wrapper) return;

    setTimeout(() => {
      const barrier = 30;
      const top = wrapper.clientHeight;
      const width = wrapper.clientWidth;

      let checkpoint = width;
      let left = domUtils.getLeftPosition(el);

      forever(
        (next: () => void) => {
          if (processId !== processIdRef.current) return;

          left -= speed;
          checkpoint = left;
          el.style.left = `${left}px`;

          if (listenMovement) listenMovement(left, top);

          if (left <= barrier) return idle(el, true);
          if (
            !ignoreCheckpoint &&
            checkpoint < width &&
            checkpoint % 50 === 0 &&
            compare(COMPARE_STOP_MOVEMENT)
          ) {
            checkpoint = 0;
            return idle(el, false);
          }

          setTimeout(next, RUN_DELAY);
        },
        (_err: unknown) => {}
      );
    });
  }

  function idle(el: HTMLDivElement, goToRightSide: boolean) {
    setTimeout(() => {
      let alreadyHere = false;
      const processId = start(el, getRandomIdlePet(goToRightSide));

      forever(
        (next: () => void) => {
          if (processId !== processIdRef.current) return;

          if (alreadyHere && compare(COMPARE_STOP_IDLE))
            return start(el, getRandomMovementPet());

          alreadyHere = true;
          setTimeout(next, IDLE_DELAY);
        },
        (_err: unknown) => {}
      );
    });
  }

  function getRandomIdlePet(goToRightSide: boolean) {
    return {
      goToRightSide,
      ...wizard_meta[getRandomPet(idle_pets)]({ bottom }),
    };
  }

  function getRandomMovementPet() {
    return {
      ...wizard_meta[getRandomPet(movement_pets)]({ bottom }),
      goToRightSide: goToRightSide(),
    };
  }

  function goToRightSide() {
    return compare(COMPARE_HALF);
  }

  function compare(to: number) {
    return Math.random() < to;
  }

  function getRandomPet(pets: WizardActionEnum[]) {
    return pets[Math.floor(Math.random() * pets.length)];
  }

  function getToRightSideByCurrentPosition(el: HTMLDivElement) {
    if (!el) return compare(COMPARE_HALF);
    if (!wrapper) return compare(COMPARE_HALF);

    const width = wrapper.clientWidth;
    const left = domUtils.getLeftPosition(el);

    return !(left && left > width / 2);
  }

  function destroyComponent() {
    killProcess();
  }

  function killProcess() {
    processIdRef.current = undefined;
  }

  return null;
};

export default Kitten;
