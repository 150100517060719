// redux
import { appActions } from "redux/slices/appSlice";
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import { PlayerClassRoomProps } from "interfaces/playerClassRoom";
import { PlayerClassRoomFirebaseUpdateProps } from "interfaces/playerClassRoomFirebase";

// utils
import { AllToOptional } from "utils/transformTypeUtils";

export const initialState: PlayerClassRoomProps = {
  // stats
  help: 0,
  intro: 0,
  battle: 0,
  missed: 0,
  killed: 0,
  streak: 0,
  maxStreak: 0,
  checkpoint: 0,
  tipsDisabled: false,

  last: 0,

  quiz: {
    easy: 0,
    hard: 0,
    medium: 0,
  },

  rating: {
    value: 0,
    message: "",
  },
};

const update = createAction(
  "@async/playerClassRoom/update",
  (payload: PlayerClassRoomFirebaseUpdateProps) => ({
    payload,
  })
);

export const playerClassRoomSlice = createSlice({
  name: "playerClassRoom",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<PlayerClassRoomProps>) => {
      return action.payload;
    },
    update: (
      state,
      action: PayloadAction<AllToOptional<PlayerClassRoomProps>>
    ) => {
      state = {
        ...state,
        ...action.payload,
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const playerClassRoomActions = {
  async: {
    update,
  },
  ...playerClassRoomSlice.actions,
};

export default playerClassRoomSlice.reducer;
