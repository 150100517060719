// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { challengeQuizActions } from "redux/slices/challengeQuizSlice";
import { nextChallengeActions } from "redux/slices/nextChallengeSlice";

// entities
import ChallengeQuizStateEntity from "entities/ChallengeQuizStateEntity";
import ChallengeQuizFirebaseEntity from "entities/ChallengeQuizFirebaseEntity";
import PlayerChallengeQuizFirebaseEntity from "entities/PlayerQuizFirebaseEntity";

// interfaces
import {
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";
import { PlayerFirebaseUpdateProps } from "interfaces/playerFirebase";
import { ChallengeQuizFirebaseUpdateProps } from "interfaces/challengeQuizFirebase";

// services
import PlayerFirebaseService from "services/firebase/PlayerFirebaseService";
import PlayerChallengeQuizFirebaseService from "services/firebase/player/PlayerChallengeQuizFirebaseService";

// utils
import isEmpty from "lodash/isEmpty";
import challengeCommonUtils from "redux/utils/challengeCommonUtils";
import stateUtils, { listenerApiType } from "redux/utils/stateUtils";

const challengeQuizMiddleware = createListenerMiddleware();

// quiz/init
challengeQuizMiddleware.startListening({
  actionCreator: challengeQuizActions.async.init,
  effect: async (_action, listenerApi) => {
    const { challengeQuiz: quiz, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = quiz;
    const userId = auth.user.id;

    const stateEntity = new ChallengeQuizStateEntity();
    const firebaseEntity = new ChallengeQuizFirebaseEntity();
    const values = stateEntity.getFlowInitValues();
    const valuesFirebase = firebaseEntity.getFlowInitValues(userId, quiz);

    listenerApi.dispatch(challengeQuizActions.update(values));

    // firebase
    updateChallengeQuizFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// quiz/start
challengeQuizMiddleware.startListening({
  actionCreator: challengeQuizActions.async.start,
  effect: async (_action, listenerApi) => {
    const { challengeQuiz: quiz, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = quiz;
    const userId = auth.user.id;

    const stateEntity = new ChallengeQuizStateEntity();
    const firebaseEntity = new ChallengeQuizFirebaseEntity();
    const values = stateEntity.getFlowStartedValues();
    const valuesFirebase = firebaseEntity.getFlowStartedValues();

    listenerApi.dispatch(challengeQuizActions.update(values));

    // firebase
    updateChallengeQuizFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// quiz/finish
challengeQuizMiddleware.startListening({
  actionCreator: challengeQuizActions.async.finish,
  effect: async (_action, listenerApi) => {
    const { challengeQuiz: quiz, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = quiz;
    const userId = auth.user.id;

    const stateEntity = new ChallengeQuizStateEntity();
    const firebaseEntity = new ChallengeQuizFirebaseEntity();
    const values = stateEntity.getFlowFinishedValues();
    const valuesFirebase = firebaseEntity.getFlowFinishedValues();

    listenerApi.dispatch(challengeQuizActions.update(values));

    // firebase
    updateChallengeQuizFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// quiz/un_finish
challengeQuizMiddleware.startListening({
  actionCreator: challengeQuizActions.async.unFinish,
  effect: async (_action, listenerApi) => {
    const stateEntity = new ChallengeQuizStateEntity();
    const values = stateEntity.getFlowUnFinishedValues();

    listenerApi.dispatch(challengeQuizActions.update(values));
  },
});

// quiz/success
challengeQuizMiddleware.startListening({
  actionCreator: challengeQuizActions.async.success,
  effect: async ({ payload }, listenerApi) => {
    const { answer } = payload;
    const {
      auth,
      challenges,
      playerClassRoom,
      challengeQuiz: quiz,
    } = stateUtils.get(listenerApi);
    const userId = auth.user.id;
    const { id, classRoomId } = quiz;

    const stateEntity = new ChallengeQuizStateEntity();
    const firebaseEntity = new ChallengeQuizFirebaseEntity();
    const playerFirebaseEntity = new PlayerChallengeQuizFirebaseEntity({
      playerClassRoom,
      quiz,
    });
    const values = stateEntity.getSuccessValues(playerClassRoom, quiz);
    const valuesFirebase = firebaseEntity.getSuccessValues(
      playerClassRoom,
      quiz,
      answer
    );
    const playerValues = playerFirebaseEntity.getSuccessValues();

    if (isEmpty(valuesFirebase.answer)) return;

    setNextChallenge({ ...quiz, ...values }, challenges.hash, listenerApi);
    listenerApi.dispatch(challengeQuizActions.update(values));

    // firebase
    updatePlayerFirebase(userId, playerValues);
    updateChallengeQuizFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// quiz/failed
challengeQuizMiddleware.startListening({
  actionCreator: challengeQuizActions.async.failed,
  effect: async ({ payload }, listenerApi) => {
    const { answer } = payload;
    const {
      auth,
      challenges,
      playerClassRoom,
      challengeQuiz: quiz,
    } = stateUtils.get(listenerApi);
    const { id, classRoomId } = quiz;
    const userId = auth.user.id;

    const stateEntity = new ChallengeQuizStateEntity();
    const firebaseEntity = new ChallengeQuizFirebaseEntity();
    const playerFirebaseEntity = new PlayerChallengeQuizFirebaseEntity({
      playerClassRoom,
      quiz,
    });
    const values = stateEntity.getFailedValues(playerClassRoom, quiz);
    const valuesFirebase = firebaseEntity.getFailedValues(
      playerClassRoom,
      quiz,
      answer
    );
    const playerValues = playerFirebaseEntity.getFailedValues();

    if (isEmpty(valuesFirebase.answer)) return;

    setNextChallenge({ ...quiz, ...values }, challenges.hash, listenerApi);
    listenerApi.dispatch(challengeQuizActions.update(values));

    // firebase
    updatePlayerFirebase(userId, playerValues);
    updateChallengeQuizFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// private

function setNextChallenge(
  challenge: ChallengeCommonProps,
  hash: ChallengesCommonHashProps,
  listenerApi: listenerApiType
) {
  const challenges = challengeCommonUtils.merge(challenge, hash);
  listenerApi.dispatch(nextChallengeActions.async.set(challenge, challenges));
}

function updateChallengeQuizFirebase(
  userId: number | string,
  quizId: number,
  classRoomId: number,
  data: ChallengeQuizFirebaseUpdateProps
) {
  if (!userId) return;
  if (!quizId) return;
  if (!classRoomId) return;

  const resource = new PlayerChallengeQuizFirebaseService();
  resource.update(quizId, userId, classRoomId, data);
}

function updatePlayerFirebase(
  userId: number | string,
  data: PlayerFirebaseUpdateProps
) {
  const resource = new PlayerFirebaseService();
  resource.update(userId, data);
}

export default challengeQuizMiddleware;
