// components
import Bubble, { BubbleProps } from "handlers/bubble/Bubble";

const BubbleSudo = ({
  hide,
  name,
  footer,
  content,
  subHeader,
  onAnimationEnd,
  finishedTyping,
}: BubbleProps) => {
  return (
    <Bubble
      show
      hide={hide}
      name={name}
      footer={footer}
      content={content}
      subHeader={subHeader}
      labelClassName="nerdfy_yellow"
      onAnimationEnd={onAnimationEnd}
      finishedTyping={finishedTyping}
      footerIcon={
        <span className="footer_title pixelify_font_family nerdfy_grey_stronger">
          task
        </span>
      }
    />
  );
};

export default BubbleSudo;
