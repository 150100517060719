// react
import { useState } from "react";

// components
import Footer from "components/Footer";
import Loading from "components/Loading";
import FormHeader from "components/form/FormHeader";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";
import ButtonDuolingoBlue from "components/buttons/templates/ButtonDuolingoBlue";

// interfaces
import { UserSignProps } from "interfaces/user";
import { AuthProps, AuthResourceProps } from "interfaces/auth";

// services
import UserService from "services/UserService";
import FirebaseAuthService from "services/auth/FirebaseAuthService";

// parsers
import authParser from "parsers/authParser";

// formik
import * as yup from "yup";
import { Formik, Form, FormikProps } from "formik";
import FormikInput from "components/formik/FormikInput";
import FormikAutoFill from "components/formik/FormikAutoFill";

// utils
import color from "styles/color";
import styled from "styled-components";
import authUtils from "utils/authUtils";
import errorUtils from "utils/errorUtils";

const SignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 30px;
    color: ${color.grey.light};

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .submit_button_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 30px;
      }

      button {
        margin: 25px 0;
      }

      .input_wrapper {
        label {
          display: block;
          margin: 8px 0;
        }

        input {
          opacity: 0.8;
          padding: 10px;
          width: 100%;
          border-radius: 10px;
          background: none !important;
          border: 1px solid ${color.grey.stronger};
        }

        p {
          font-size: 1em;
          margin: 10px 2px;
          color: ${color.red};
        }
      }
    }
  }
`;

interface SignInProps {
  goBack: () => void;
  disabledInputs: boolean;
  onSignedIn: (auth: AuthProps, registered: boolean) => void;
}

const SignIn = ({ goBack, onSignedIn, disabledInputs }: SignInProps) => {
  const [danger, setDanger] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function getSchemaValidation() {
    const email = yup
      .string()
      .email("Please enter a valid email")
      .required("Email is missing");
    const password = yup
      .string()
      .required("Password is missing")
      .min(8, "The password must be at least 8 characters");

    return yup.object({
      email,
      password,
    });
  }

  function getInitialValues(): UserSignProps {
    return {
      email: "",
      password: "",
    };
  }

  function onGoBack() {
    setDanger("");
    setTimeout(goBack);
  }

  async function submit({ email, password }: UserSignProps) {
    try {
      setIsLoading(true);
      setDanger("");

      const resource = new UserService();
      const firebaseAuth = new FirebaseAuthService();
      const response = await resource.signIn({ email, password });
      const auth: AuthResourceProps = response.data;

      authUtils.setAccessToken(auth.accessToken);
      await firebaseAuth.signIn(auth.firebaseToken);

      onSignedIn(authParser.map(auth), !!response.data.user.jarvisName);
    } catch (error: unknown) {
      setIsLoading(false);

      const { message } = errorUtils.handle.auth(error);
      setDanger(message);
    }
  }

  return (
    <SignInWrapper>
      <Loading loading={isLoading} />
      <FormHeader back={onGoBack} />

      <div className="content">
        <h2 className="title">Welcome back!</h2>

        <Formik
          onSubmit={submit}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={getInitialValues()}
          validationSchema={getSchemaValidation()}
        >
          {(props: FormikProps<UserSignProps>) => {
            return (
              <Form noValidate>
                <FormikAutoFill
                  props={props}
                  field="email"
                  identifier="userSignInEmail"
                />
                <FormikAutoFill
                  props={props}
                  field="password"
                  identifier="userSignInPassword"
                />

                <FormikInput
                  name="email"
                  type="email"
                  label="Email"
                  inputmode="email"
                  autocorrect="off"
                  id="userSignInEmail"
                  autocomplete="email"
                  autocapitalize="off"
                  placeholder="Your email"
                  disabled={disabledInputs}
                />

                <FormikInput
                  name="password"
                  type="password"
                  label="Password"
                  autocorrect="off"
                  autocapitalize="off"
                  placeholder="Password"
                  id="userSignInPassword"
                  disabled={disabledInputs}
                  autocomplete="current-password"
                />

                <div className="submit_button_wrapper">
                  <ButtonDuolingoBlue
                    widthFull
                    type="submit"
                    height="40px"
                    onClick={() => {}}
                  >
                    Sign In
                  </ButtonDuolingoBlue>
                </div>
              </Form>
            );
          }}
        </Formik>

        <div className="error_wrapper">
          {danger && <ErrorFeedback err={danger} />}
        </div>
      </div>

      <Footer />
    </SignInWrapper>
  );
};

export default SignIn;
