// redux
import { appActions } from "redux/slices/appSlice";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

// entities
import JarvisEntity from "entities/characters/JarvisEntity";

// interfaces
import { SubscriberProps } from "interfaces/subscriber";

const updateCheckpoint = createAction(
  "@async/subscriber/updateCheckpoint",
  (payload: { checkpoint: number }) => ({ payload })
);

const updateJarvisName = createAction("@async/subscriber/updateJarvisName");

const initialState: SubscriberProps = {
  id: 0,
  trial: false,
  checkpoint: 0,
  jarvisName: JarvisEntity.unknownName,
};

export const subscriberSlice = createSlice({
  name: "subscriber",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<SubscriberProps>) => {
      return action.payload;
    },
    updateCheckpoint(state, action: PayloadAction<{ checkpoint: number }>) {
      state.checkpoint = action.payload.checkpoint;
    },
    updateJarvisName(state, action: PayloadAction<{ jarvisName: string }>) {
      state.jarvisName = action.payload.jarvisName;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const subscriberActions = {
  async: {
    updateCheckpoint,
    updateJarvisName,
  },
  ...subscriberSlice.actions,
};

export default subscriberSlice.reducer;
