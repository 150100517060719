import React from "react";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

// icons
import IconWhoreLabs from "components/iconWhores/IconWhoreLabs";
import IconWhoreQuiz from "components/iconWhores/IconWhoreQuiz";
import IconWhoreInfo from "components/iconWhores/IconWhoreInfo";
import IconWhoreAlert from "components/iconWhores/IconWhoreAlert";
import IconWhoreWarning from "components/iconWhores/IconWhoreWarning";

// enums
import { BubbleType } from "enums/bubbleEnum";

// types
import { dropZoneBorderColorType } from "components/DropZone";

// utils
import { v4 as uuidv4 } from "uuid";
import dragUtils from "utils/dragUtils";

interface GetProps {
  payload: string;
  wrapperWidth: number;
  wrapperHeight: number;
  bubbleType: BubbleType;
  WhoreEl: React.FC<AttentionWhoreElProps>;
  dropZoneBorderColor: dropZoneBorderColorType;
  call(attentionWhore: AttentionWhoreProps): void;
  last?: boolean;
  label?: string;
  unknownPayload?: unknown;
}

interface GetEnrichedProps {
  payload: string;
  wrapperWidth: number;
  wrapperHeight: number;
  call(attentionWhore: AttentionWhoreProps): void;
  last?: boolean;
}

function get({
  call,
  WhoreEl,
  payload,
  label = "",
  last = false,
  wrapperWidth,
  wrapperHeight,
  unknownPayload,
  dropZoneBorderColor,
  bubbleType = BubbleType.None,
}: GetProps): AttentionWhoreProps {
  const wrapper = { width: wrapperWidth, height: wrapperHeight };
  const { x, y } = dragUtils.getRandomPosition({ wrapper });

  return {
    call,
    last,
    label,
    payload,
    WhoreEl,
    bubbleType,
    x: `${x}px`,
    y: `${y}px`,
    id: uuidv4(),
    unknownPayload,
    dropZoneBorderColor,
  };
}

function getIO({
  call,
  payload,
  last = false,
  wrapperWidth,
  wrapperHeight,
}: GetEnrichedProps): AttentionWhoreProps {
  return get({
    last,
    call,
    payload,
    wrapperWidth,
    wrapperHeight,
    WhoreEl: IconWhoreLabs,
    dropZoneBorderColor: "pink",
    bubbleType: BubbleType.None,
  });
}

function getQuiz({
  call,
  payload,
  last = false,
  wrapperWidth,
  wrapperHeight,
}: GetEnrichedProps): AttentionWhoreProps {
  return get({
    last,
    call,
    payload,
    wrapperWidth,
    wrapperHeight,
    WhoreEl: IconWhoreQuiz,
    dropZoneBorderColor: "green",
    bubbleType: BubbleType.Quiz,
  });
}

function getInfo({
  call,
  payload,
  last = false,
  wrapperWidth,
  wrapperHeight,
}: GetEnrichedProps): AttentionWhoreProps {
  return get({
    last,
    call,
    payload,
    wrapperWidth,
    wrapperHeight,
    WhoreEl: IconWhoreInfo,
    dropZoneBorderColor: "blue",
    bubbleType: BubbleType.Info,
  });
}

function getTutorial({
  call,
  payload,
  last = false,
  wrapperWidth,
  wrapperHeight,
}: GetEnrichedProps): AttentionWhoreProps {
  return getAlert({
    last,
    call,
    payload,
    wrapperWidth,
    wrapperHeight,
  });
}

function getWarning({
  call,
  payload,
  last = false,
  wrapperWidth,
  wrapperHeight,
}: GetEnrichedProps): AttentionWhoreProps {
  return get({
    last,
    call,
    payload,
    wrapperWidth,
    wrapperHeight,
    WhoreEl: IconWhoreWarning,
    dropZoneBorderColor: "yellow",
    bubbleType: BubbleType.Warning,
  });
}

function getAlert({
  call,
  payload,
  last = false,
  wrapperWidth,
  wrapperHeight,
}: GetEnrichedProps): AttentionWhoreProps {
  return get({
    last,
    call,
    payload,
    wrapperWidth,
    wrapperHeight,
    WhoreEl: IconWhoreAlert,
    dropZoneBorderColor: "red",
    bubbleType: BubbleType.Alert,
  });
}

function fake(): CurrentAttentionWhoreProps {
  return {
    x: "0",
    y: "0",
    label: "",
    show: null,
    payload: "",
    id: uuidv4(),
    call: () => {},
    WhoreEl: _WhoreEl,
    bubbleType: BubbleType.None,
    dropZoneBorderColor: "yellow",
  };
}

// private
const _WhoreEl = () => <div />;

const attentionWhoreUtils = {
  get,
  fake,
  getIO,
  getQuiz,
  getInfo,
  getAlert,
  getWarning,
  getTutorial,
};

export default attentionWhoreUtils;
