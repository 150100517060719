import { PlayerClassRoomProps } from "interfaces/playerClassRoom";
import { PlayerClassRoomFirebaseProps } from "interfaces/playerClassRoomFirebase";

function map(player: PlayerClassRoomFirebaseProps): PlayerClassRoomProps {
  return {
    // stats
    help: player.help || 0,
    missed: player.missed || 0,
    killed: player.killed || 0,
    battle: player.battle || 0,
    intro: player.intro || 0,
    streak: player.streak || 0,
    last: getPlayerLastMili(player),
    maxStreak: player.maxStreak || 0,
    checkpoint: player.checkpoint || 0,
    tipsDisabled: player.tipsDisabled || false,

    // quiz points
    quiz: {
      easy: player.quiz?.easy || 0,
      medium: player.quiz?.medium || 0,
      hard: player.quiz?.hard || 0,
    },

    // rating
    rating: {
      message: player.rating?.message || "",
      value: player.rating?.value || 0,
    },
  };
}

function getPlayerLastMili(player: PlayerClassRoomFirebaseProps) {
  if (!player) return 0;
  if (!player.last) return 0;

  return player.last.toMillis();
}

const playerParser = {
  map,
};

export default playerParser;
