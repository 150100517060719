// interfaces
import { ChallengeQuizProps } from "interfaces/challengeQuiz";
import { PlayerClassRoomProps } from "interfaces/playerClassRoom";
import { PlayerClassRoomFirebaseUpdateProps } from "interfaces/playerClassRoomFirebase";

// entities
import ChallengeQuizLevelPointsEntity from "entities/ChallengeQuizLevelPointsEntity";

class PlayerChallengeQuizFirebaseEntity {
  private quiz: ChallengeQuizProps;
  private player: PlayerClassRoomProps;

  constructor({
    quiz,
    playerClassRoom,
  }: {
    quiz: ChallengeQuizProps;
    playerClassRoom: PlayerClassRoomProps;
  }) {
    this.quiz = quiz;
    this.player = playerClassRoom;
  }

  getSuccessValues(): PlayerClassRoomFirebaseUpdateProps {
    const { quiz, player } = this;
    const { quizEasy, quizHard, quizMedium } =
      new ChallengeQuizLevelPointsEntity({
        player,
        level: quiz.level,
      }).inc();

    return {
      quiz: {
        easy: quizEasy,
        hard: quizHard,
        medium: quizMedium,
      },
    };
  }

  getFailedValues(): PlayerClassRoomFirebaseUpdateProps {
    const { quiz, player } = this;
    const { quizEasy, quizHard, quizMedium } =
      new ChallengeQuizLevelPointsEntity({
        player,
        level: quiz.level,
      }).dec();

    return {
      quiz: {
        easy: quizEasy,
        hard: quizHard,
        medium: quizMedium,
      },
    };
  }
}

export default PlayerChallengeQuizFirebaseEntity;
