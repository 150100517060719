// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const OreSvg = ({
  width = "18",
  height = "18",
  color = c.yellow,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          fill={color}
          d="M3.2 19.3c.1.4.5.8 1 .8H20c.5 0 .9-.3 1-.8l2.6-10.8c.1-.4-.1-.9-.4-1.1-.4-.2-.8-.2-1.2.1L16.6 12l-3.5-7.5c-.4-.3-.7-.5-1.1-.5s-.7.2-.9.6l-3.5 7.5-5.5-4.6c-.3-.3-.8-.3-1.1-.1s-.6.7-.5 1.1zm4.1-4.9c.2.2.6.3.9.2s.6-.3.7-.6L12 7.3l3.2 6.7c.1.3.4.5.7.6s.6 0 .9-.2l4.1-3.4-1.7 7H4.9l-1.7-7z"
          opacity="1"
          data-original="#505171"
        ></path>
      </g>
    </svg>
  );
};

export default OreSvg;
