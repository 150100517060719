// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    position: relative;
    margin: 0;
    padding: 0;
    background: ${color.grey.dark};
    color: ${color.grey.stronger};

    p {
      all: unset;
      font-size: 1em;
    }

    span {
      display: inline-block;
      font-size: 1em;
    }
  }

  .terms_footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 60px;
    padding: 20px;
    width: 100%;
    background: ${color.grey.dark};
    color: ${color.grey.stronger};

    a {
      all: unset;
      font-size: 0.8rem;

      &:nth-of-type(2) {
        margin-left: 15px;
      }

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <div className="footer">
        <p>
          Bittle ©{new Date().getFullYear()}. Made with{" "}
          <span className="nerdfy_red">♥</span>
        </p>
      </div>
    </Wrapper>
  );
};

export const TermsFooter = () => {
  return (
    <Wrapper>
      <div className="terms_footer">
        <a
          href="https://bittle.io/terms/terms-and-service"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>
        <a
          href="https://bittle.io/policies/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div>
    </Wrapper>
  );
};

export default Footer;
