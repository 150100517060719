// interfaces
import { TipProps } from "interfaces/tip";

function find(tips: TipProps[], code: string): TipProps | undefined {
  const len = code.length;

  return tips.find((tip) => {
    const matchLen = tip.match.length;
    if (len < matchLen) return false;

    return match(tip, removeWhiteSpace(code.substring(len - matchLen, len)));
  });
}

function remove(tips: TipProps[], tipIdToBeRemoved: string): TipProps[] {
  return tips.filter((tip) => tip.id !== tipIdToBeRemoved);
}

// private

function match(tip: TipProps, code: string): boolean {
  if (!tip.match) return false;

  const len = code.length;
  const match = removeWhiteSpace(tip.match);
  const sub = code.substring(len - match.length, len);

  return match === sub;
}

function removeWhiteSpace(code: string): string {
  return code.replace(/\s/g, "");
}

const tipUtils = {
  find,
  remove,
};

export default tipUtils;
