// react
import { useEffect, useRef } from "react";

// redux
import { useDispatch } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";

// components
import Confetti from "components/Celebration";
import CloseButton from "components/CloseButton";
import StatsHandler from "handlers/stats/StatsHandler";
import ChallengeCode from "components/challenge/battle/ChallengeCode";
import ChallengeOptionsFooter from "components/ChallengeOptionsFooter";
import ChallengeStatusBar from "components/challenge/ChallengeStatusBar";
import { DropZoneTag, DropZoneAttentionWhore } from "components/DropZone";
import ChallengeTags from "components/challenge/battle/tags/ChallengeTags";

// handlers
import TipsHandler from "handlers/TipsHandler";
import ChallengeGameFlowHandler from "handlers/ChallengeGameFlowHandler";
import IOCodeHandler from "handlers/challengeButtons/ioCode/IOCodeHandler";
import ChallengeFlowFinishedMessageHandler from "handlers/ChallengeFlowFinishedMessageHandler";

// svgs
import DoneSvg from "svg/DoneSvg";
import CrossSvg from "svg/CrossSvg";

// interfaces
import { ChallengeTypeHandlerCommonProps } from "components/challenge/Challenge";

// utils
import color from "styles/color";
import styled from "styled-components";
import WizardBox from "components/WizardBox";
import { WizardTypeEnum } from "enums/wizardEnum";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  .challenge_battle_content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .code_wrapper {
    flex: 1;
    height: 100%;
  }

  .result_header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 65px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 15px;
    background: ${color.grey.dark};

    > .close_button {
      position: absolute;
      top: 12px;
      left: 18px;
    }
  }
`;

interface TagBoundsProps {
  left: string;
  top: string;
}

interface ChallengeBattleProps extends ChallengeTypeHandlerCommonProps {
  onFailedTag(bounds: TagBoundsProps): void;
}

const ChallengeBattle = ({
  code,
  close,
  paused,
  challenge,
  wrapperRef,
  isTagValid,
  onFailedTag,
  onSuccessTag,
  handlerWrapperRef,
  handlerNotifiersRef,
}: ChallengeBattleProps) => {
  const dispatch = useDispatch();
  const disabledTagDropRef = useRef(false);

  const {
    tags,
    flowInit,
    flowDone,
    flowStarted,
    flowSuccess,
    currentCode,
    flowFinished,
    programmingLanguageType,
  } = challenge;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleInitFlow, [paused, flowInit]);

  function handleInitFlow() {
    if (paused) return;
    if (flowInit) return;

    setTimeout(() => dispatch(challengeActions.async.init()));
  }

  return (
    <Wrapper
      ref={handlerWrapperRef}
      className={`challenge_battle_wrapper ${
        !paused && !flowDone && "no-swipe"
      }`}
    >
      <div className="nerdfy_notifiers" ref={handlerNotifiersRef}></div>

      {flowDone && (
        <div
          className={
            flowFinished
              ? "animate__animated animate__bounceInRight result_header"
              : "result_header"
          }
        >
          <CloseButton onClick={close} className="close_button" />
          {flowSuccess ? <DoneSvg /> : <CrossSvg />}
          <div />
        </div>
      )}

      <ChallengeStatusBar />

      <DropZoneTag />
      <DropZoneAttentionWhore />

      {flowStarted && (
        <ChallengeTags
          code={code}
          tags={tags}
          paused={paused}
          clear={flowDone}
          isTagValid={isTagValid}
          onFailedTag={onFailedTag}
          onSuccessTag={onSuccessTag}
          wrapper={wrapperRef.current}
          disabledDropRef={disabledTagDropRef}
        />
      )}

      <div className="challenge_battle_content">
        <div className="code_wrapper">
          <ChallengeCode
            wrap
            code={currentCode}
            language={programmingLanguageType}
          />
        </div>

        <ChallengeOptionsFooter
          wrapper={wrapperRef.current}
          disabled={challenge.flowDone}
        />

        {/* **** GAME FLOW **** */}

        <ChallengeGameFlowHandler paused={paused} challenge={challenge} />

        {/* GAME FLOW END */}
      </div>

      {/* **** HANDLERS **** */}

      <ChallengeFlowFinishedMessageHandler challenge={challenge} />

      {flowDone && flowFinished && flowSuccess && <Confetti />}

      {flowInit && (
        <>
          <IOCodeHandler
            paused={paused}
            challenge={challenge}
            wrapper={wrapperRef.current}
          />

          <TipsHandler paused={paused} wrapper={wrapperRef.current} />
        </>
      )}

      <StatsHandler challenge={challenge} />

      {/* HANDLERS END */}

      <WizardBox type={WizardTypeEnum.Idle} />
    </Wrapper>
  );
};

export default ChallengeBattle;
