// react
import { useEffect } from "react";

//redux
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { playerClassRoomActions } from "redux/slices/playerClassRoomSlice";

// parsers
import playerClassRoomParser from "parsers/playerClassRoomParser";

// firebase
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";

// interfaces
import { UserProps } from "interfaces/user";
import { PlayerClassRoomFirebaseProps } from "interfaces/playerClassRoomFirebase";

// services
import FirebaseCoreService from "services/core/FirebaseCoreService";

// utils
import isEmpty from "lodash/isEmpty";

interface PlayerFirebaseSyncProps {
  user: UserProps;
  classRoomId: number;
}

const PlayerClassRoomFirebaseSync = ({
  user,
  classRoomId,
}: PlayerFirebaseSyncProps) => {
  const dispatch = useDispatch();
  const [snapshot] = useDocument(
    doc(
      FirebaseCoreService.getDatabase(),
      `players/${user.id}/classRooms`,
      String(classRoomId)
    )
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(sync, [snapshot]);

  function sync() {
    syncPlayerFirebase(
      snapshot?.data() as PlayerClassRoomFirebaseProps,
      dispatch
    );
  }

  return null;
};

export function syncPlayerFirebase(
  playerClassRoom: PlayerClassRoomFirebaseProps,
  dispatch: Dispatch
) {
  if (isEmpty(playerClassRoom)) return;

  const p = playerClassRoomParser.map(playerClassRoom);
  dispatch(playerClassRoomActions.update(p));
}

export default PlayerClassRoomFirebaseSync;
