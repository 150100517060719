// react
import { IonProgressBar } from "@ionic/react";

// utils
import c from "styles/color";
import styled from "styled-components";

interface ProgressBarStatusProps {
  bgColor: string;
}

const Progress = styled(IonProgressBar)<ProgressBarStatusProps>`
height: 6px;
opacity: 0.6;
--background: ${c.grey.stronger};
--progress-background ${({ bgColor }) => bgColor} !important;
`;
interface ProgressBarProps {
  value: number;
  color?: string;
}

const ProgressBar = ({ value, color = c.purple }: ProgressBarProps) => {
  return <Progress value={value} mode="ios" bgColor={color} />;
};

export default ProgressBar;
