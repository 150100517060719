// redux
import { appActions } from "redux/slices/appSlice";
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import { PlayerProps } from "interfaces/player";
import { PlayerFirebaseUpdateProps } from "interfaces/playerFirebase";

// utils
import { AllToOptional } from "utils/transformTypeUtils";

export const initialState: PlayerProps = {};

const update = createAction(
  "@async/player/update",
  (payload: PlayerFirebaseUpdateProps) => ({
    payload,
  })
);

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<PlayerProps>) => {
      return action.payload;
    },
    update: (state, action: PayloadAction<AllToOptional<PlayerProps>>) => {
      state = {
        ...state,
        ...action.payload,
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const playerActions = {
  async: {
    update,
  },
  ...playerSlice.actions,
};

export default playerSlice.reducer;
