// redux
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import {
  Persistor,
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

// middlewares
import tagMiddleware from "redux/middlewares/tagMiddleware";
import playerMiddleware from "redux/middlewares/playerMiddleware";
import feedbackMiddleware from "redux/middlewares/feedbackMiddleware";
import challengeMiddleware from "redux/middlewares/challengeMiddleware";
import classRoomMiddleware from "redux/middlewares/classRoomMiddleware";
import subscriberMiddleware from "redux/middlewares/subscriberMiddleware";
import jarvisChatsMiddleware from "redux/middlewares/jarvisChatsMiddleware";
import challengeQuizMiddleware from "redux/middlewares/challengeQuizMiddleware";
import nextChallengeMiddleware from "redux/middlewares/nextChallengeMiddleware";
import challengesMergedMiddleware from "redux/middlewares/challengesMiddleware";
import playerClassRoomMiddleware from "redux/middlewares/playerClassRoomMiddleware";

// reducers
import appReducers from "redux/reducers";

// interfaces
import { AppProps } from "interfaces/app";
import { AuthProps } from "interfaces/auth";
import { StoryProps } from "interfaces/story";
import { EventProps } from "interfaces/event";
import { NpcHashProps } from "interfaces/npc";
import { PlayerProps } from "interfaces/player";
import { PaymentProps } from "interfaces/payment";
import { ClassRoomProps } from "interfaces/classRoom";
import { ChallengeProps } from "interfaces/challenge";
import { SubscriberProps } from "interfaces/subscriber";
import { JarvisChatProps } from "interfaces/jarvisChat";
import { NextChallengeProps } from "interfaces/nextChallenge";
import { ChallengeCodeProps } from "interfaces/challengeCode";
import { ChallengeFlowProps } from "interfaces/challengeFlow";
import { SectionsBriefProps } from "interfaces/sectionsBrief";
import { ChallengeQuizProps } from "interfaces/challengeQuiz";
import { PlayerClassRoomProps } from "interfaces/playerClassRoom";
import { ChallengeSectionProps } from "interfaces/challengeSection";
import { ChallengesMergedProps } from "interfaces/challengesMerged";
import { CheckpointsStateProps } from "interfaces/checkpoints";
import { ProgrammingLanguageProps } from "interfaces/programmingLanguage";
import { ClassRoomRatingSummaryProps } from "interfaces/classRoomRating";

export interface ApplicationState {
  app: AppProps;
  auth: AuthProps;
  player: PlayerProps;
  npc: NpcHashProps;
  classRoom: ClassRoomProps;
  playerClassRoom: PlayerClassRoomProps;
  programmingLanguages: ProgrammingLanguageProps[];
  challengeSections: ChallengeSectionProps[];
  challengeSection: ChallengeSectionProps;
  challenge: ChallengeProps;
  nextChallenge: NextChallengeProps;
  challengeCode: ChallengeCodeProps;
  jarvisChats: JarvisChatProps[];
  jarvisChat: JarvisChatProps[];
  challengeFlow: ChallengeFlowProps;
  stories: StoryProps[];
  events: EventProps[];
  payment: PaymentProps;
  sectionsBrief: SectionsBriefProps;
  classRoomRatingSummary: ClassRoomRatingSummaryProps;
  challengeQuiz: ChallengeQuizProps;
  challenges: ChallengesMergedProps;
  checkpoints: CheckpointsStateProps;
  subscriber: SubscriberProps;
  challengeRaw: ChallengeProps;
}

const LOCAL_STORAGE_STORE_TOPIC = "87aea99d7054b04afb77b66f971d7929";
const persistConfig = {
  key: LOCAL_STORAGE_STORE_TOPIC,
  storage,
  // do NOT persist these reducers
  blacklist: [
    "app",
    "challengeFlow",
    "jarvisAnswers",
    "classRooms",
    "player",
    "sectionsBrief",
    "checkpoints",
    "walkieTalkie",
    "classRoomRatings",
    "classRoom",
    "jarvisChats",
    "jarvisChat",
    "nextChallenge",
    "challengeCode",
    "challenge",
    "challengeQuiz",
    "challengesMerged",
    "classRoomRatingSummary",
    "challengeSections",
    "challengeSection",
    "challengeModal",
  ],
};

const persistedReducer = persistReducer(persistConfig, appReducers);
const middlewares = [
  tagMiddleware.middleware,
  playerMiddleware.middleware,
  feedbackMiddleware.middleware,
  classRoomMiddleware.middleware,
  challengeMiddleware.middleware,
  subscriberMiddleware.middleware,
  jarvisChatsMiddleware.middleware,
  nextChallengeMiddleware.middleware,
  challengeQuizMiddleware.middleware,
  playerClassRoomMiddleware.middleware,
  challengesMergedMiddleware.middleware,
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(middlewares),
});

export const persistor: Persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
