// react
import { useEffect } from "react";

// redux
import { useDispatch } from "react-redux";
import { classRoomActions } from "redux/slices/classRoomSlice";
import { challengeActions } from "redux/slices/challengeSlice";
import { challengesActions } from "redux/slices/challengesSlice";
import { jarvisChatsActions } from "redux/slices/jarvisChatsSlice";
import { sectionsBriefActions } from "redux/slices/sectionsBriefSlice";
import { challengeSectionsActions } from "redux/slices/challengeSectionsSlice";
import { programmingLanguagesActions } from "redux/slices/programmingLanguagesSlice";

// parsers
import challengeParser from "parsers/challengeParser";
import classRoomParser from "parsers/classRoomParser";
import jarvisChatParser from "parsers/jarvisChatParser";
import sectionsBriefParser from "parsers/sectionsBriefParser";
import challengeQuizParser from "parsers/challengeQuizParser";
import challengeSectionParser from "parsers/challengeSectionParser";
import programmingLanguageParser from "parsers/programmingLanguageParser";

// firebase sync
import { syncPlayerFirebase } from "firebaseSync/PlayerClassRoomFirebaseSync";
import { syncChallengesFirebase } from "firebaseSync/ChallengesFirebaseSync";

// interfaces
import { AuthProps } from "interfaces/auth";
import { ClassRoomDtoResourceProps } from "interfaces/classRoom";
import { ChallengeFirebaseProps } from "interfaces/challengeFirebase";
import { PlayerClassRoomFirebaseProps } from "interfaces/playerClassRoomFirebase";

// services
import JarvisService from "services/JarvisService";
import ClassRoomDtoService from "services/ClassRoomDtoService";
import PlayerChallengeFirebaseService from "services/firebase/player/PlayerChallengeFirebaseService";

// utils
import authUtils from "utils/authUtils";
import { Timestamp } from "firebase/firestore";
import dateUTCUtils from "utils/dateUTCUtils";
import PlayerClassRoomFirebaseService from "services/firebase/player/PlayerClassRoomFirebaseService";
import { JarvisChatResourceProps } from "interfaces/jarvisChat";

export interface FetchCallbackProps {
  dtoData: ClassRoomDtoResourceProps;
  jarvisChatData: JarvisChatResourceProps[];
  playerFirebaseData: PlayerClassRoomFirebaseProps;
  challengesFirebaseData: ChallengeFirebaseProps[];
}

interface FetchClassRoomDataHandlerProps {
  auth: AuthProps;
  languageId: number;
  classRoomId: number;
  successCallback: () => void;
  errorCallback: (error: unknown) => void;
  onStart?: () => void;
}

const FetchClassRoomDataHandler = ({
  auth,
  onStart,
  languageId,
  classRoomId,
  errorCallback,
  successCallback,
}: FetchClassRoomDataHandlerProps) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(start, [auth]);

  function start() {
    if (!onStart) return;
    if (!auth.signed) return;

    onStart();
  }

  useEffect(() => {
    async function handlePromises() {
      try {
        const userId = auth.user.id;
        if (!userId) return;

        const jarvisService = new JarvisService();
        const classRoomDtoResource = new ClassRoomDtoService();
        const challengeFirebaseResource = new PlayerChallengeFirebaseService();
        const playerClassRoomFirebaseResource =
          new PlayerClassRoomFirebaseService();
        const [jarvisChat, playerClassRoomFirebase, challengesFirebase]: any =
          await Promise.all([
            jarvisService.get(classRoomId),
            playerClassRoomFirebaseResource.get(userId, classRoomId),
            challengeFirebaseResource.getCollection(userId, classRoomId),
          ]);
        const [dto]: any = await Promise.all([
          classRoomDtoResource.getDaily({
            languageId,
            id: classRoomId,
            checkpoint: getCheckpoint(playerClassRoomFirebase),
          }),
        ]);

        fetchCallback({
          dtoData: dto.data,
          jarvisChatData: jarvisChat.data,
          playerFirebaseData: playerClassRoomFirebase,
          challengesFirebaseData: challengesFirebase,
        });
      } catch (e: unknown) {
        errorCallback(e);
      }
    }

    handlePromises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, languageId, classRoomId]);

  function fetchCallback({
    dtoData,
    jarvisChatData,
    playerFirebaseData,
    challengesFirebaseData,
  }: FetchCallbackProps) {
    try {
      const {
        classRoom: classRoomData,
        challenges: challengesData,
        sections: sectionsData,
        programmingLanguages: programmingLanguagesData,
        challengeQuizzes: challengeQuizzesData,
      } = dtoData;
      const classRoom = classRoomParser.map(classRoomData);
      const sections = challengeSectionParser.list(sectionsData);
      const programmingLanguages = programmingLanguageParser.list(
        programmingLanguagesData
      );
      const challenges = challengeParser.list(
        challengesData,
        programmingLanguagesData
      );

      const quizzes = challengeQuizParser.list(challengeQuizzesData);
      const hashes = [
        challengeParser.hash(
          challengeParser.mix(challenges, challengesFirebaseData)
        ),
      ];
      const sectionsBrief = sectionsBriefParser.map(sections, [
        ...challenges,
        ...quizzes,
      ]);

      dispatch(classRoomActions.set(classRoom));
      dispatch(challengeActions.set(challenges[0]));
      dispatch(challengeSectionsActions.set(sections));
      dispatch(sectionsBriefActions.set(sectionsBrief));
      dispatch(challengesActions.async.merge({ hashes }));
      dispatch(programmingLanguagesActions.set(programmingLanguages));
      dispatch(jarvisChatsActions.set(jarvisChatParser.list(jarvisChatData)));

      // sync firebase
      syncPlayerFirebase(playerFirebaseData, dispatch);
      syncChallengesFirebase(challengesFirebaseData, dispatch);

      dispatch(classRoomActions.async.syncFlowParcialDone());
      setTimeout(successCallback);
    } catch (e: unknown) {
      errorCallback(e);
    }
  }

  function getCheckpoint(
    playerClassRoom: PlayerClassRoomFirebaseProps
  ): number {
    if (auth.anonymous) return 0;
    if (!playerClassRoom) return 0;
    if (!playerClassRoom.checkpoint) return 0;
    if (
      !auth.subscribed &&
      authUtils.hasTrialExpired(playerClassRoom.checkpoint)
    )
      return 0;

    let checkpoint = playerClassRoom.checkpoint;

    if (!isChallengeAvailable(Timestamp.now(), playerClassRoom))
      checkpoint -= 1;

    return checkpoint;
  }

  function isChallengeAvailable(
    now: Timestamp,
    player: PlayerClassRoomFirebaseProps
  ) {
    if (!player) return true;
    if (!player.last) return true;

    const a = dateUTCUtils.reset(new Date(now.toMillis()));
    const b = dateUTCUtils.reset(new Date(player.last.toMillis()));

    return a.getTime() > b.getTime();
  }

  return null;
};

export default FetchClassRoomDataHandler;
