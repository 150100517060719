// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { challengeActions } from "redux/slices/challengeSlice";
import { subscriberActions } from "redux/slices/subscriberSlice";
import { challengeCodeActions } from "redux/slices/challengeCodeSlice";
import { nextChallengeActions } from "redux/slices/nextChallengeSlice";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";
import { challengeSectionActions } from "redux/slices/challengeSectionSlice";

// entities
import ChallengeStateEntity from "entities/ChallengeStateEntity";
import ChallengeFirebaseEntity from "entities/ChallengeFirebaseEntity";
import PlayerClassRoomFirebaseEntity from "entities/PlayerClassRoomFirebaseEntity";

// interfaces
import {
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";
import { ChallengeFirebaseUpdateProps } from "interfaces/challengeFirebase";
import { PlayerClassRoomFirebaseUpdateProps } from "interfaces/playerClassRoomFirebase";

// services
import PlayerClassRoomFirebaseService from "services/firebase/player/PlayerClassRoomFirebaseService";
import PlayerChallengeFirebaseService from "services/firebase/player/PlayerChallengeFirebaseService";

// utils
import stateUtils, { listenerApiType } from "redux/utils/stateUtils";
import challengeCommonUtils from "redux/utils/challengeCommonUtils";

const challengeMiddleware = createListenerMiddleware();

// challenge/init
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.init,
  effect: async (_action, listenerApi) => {
    const { challenge, auth, playerClassRoom } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const userId = auth.user.id;

    const stateEntity = new ChallengeStateEntity();
    const firebaseEntity = new ChallengeFirebaseEntity();
    const values = stateEntity.getFlowInitValues();
    const valuesFirebase = firebaseEntity.getFlowInitValues(
      userId,
      playerClassRoom,
      challenge
    );

    listenerApi.dispatch(challengeActions.update(values));

    // firebase
    updateChallengeFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// challenge/start
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.start,
  effect: async (_action, listenerApi) => {
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const userId = auth.user.id;

    const stateEntity = new ChallengeStateEntity();
    const firebaseEntity = new ChallengeFirebaseEntity();
    const values = stateEntity.getFlowStartedValues();
    const valuesFirebase = firebaseEntity.getFlowStartedValues();

    listenerApi.dispatch(challengeActions.update(values));

    // firebase
    updateChallengeFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// challenge/done
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.done,
  effect: async (_action, listenerApi) => {
    const { challenge, challenges, playerClassRoom, auth } =
      stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const userId = auth.user.id;

    const challengeStateEntity = new ChallengeStateEntity();
    const challengeFirebaseEntity = new ChallengeFirebaseEntity();
    const values = challengeStateEntity.getDoneValues(challenge);
    const playerClassRoomFirebaseEntity = new PlayerClassRoomFirebaseEntity();
    const valuesFirebase = challengeFirebaseEntity.getDoneValues(challenge);
    const playerClassRoomFirebaseValues =
      playerClassRoomFirebaseEntity.getDoneValues(challenge, playerClassRoom);

    setNextChallenge({ ...challenge, ...values }, challenges.hash, listenerApi);

    listenerApi.dispatch(
      subscriberActions.async.updateCheckpoint({
        checkpoint: playerClassRoom.checkpoint + 1,
      })
    );
    listenerApi.dispatch(challengeCodeActions.clear());
    listenerApi.dispatch(challengeActions.update(values));

    // firebase
    updateChallengeFirebase(userId, id, classRoomId, valuesFirebase);
    updatePlayerClassRoomFirebase(
      userId,
      classRoomId,
      playerClassRoomFirebaseValues
    );
  },
});

challengeMiddleware.startListening({
  actionCreator: challengeActions.async.failed,
  effect: async (_action, listenerApi) => {
    const { challenge, challenges, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const userId = auth.user.id;

    const challengeStateEntity = new ChallengeStateEntity();
    const challengeFirebaseEntity = new ChallengeFirebaseEntity();
    const playerClassRoomFirebaseEntity = new PlayerClassRoomFirebaseEntity();
    const values = challengeStateEntity.getFailedValues(challenge);
    const valuesFirebase = challengeFirebaseEntity.getFailedValues(challenge);
    const playerClassRoomValues =
      playerClassRoomFirebaseEntity.getFailedValues();

    setNextChallenge({ ...challenge, ...values }, challenges.hash, listenerApi);

    listenerApi.dispatch(challengeActions.update(values));
    listenerApi.dispatch(challengeCodeActions.clear());

    // firebase
    updateChallengeFirebase(userId, id, classRoomId, valuesFirebase);
    updatePlayerClassRoomFirebase(userId, classRoomId, playerClassRoomValues);
  },
});

// challenge/updateSpeechProgress
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.updateSpeechProgress,
  effect: async ({ payload }, listenerApi) => {
    const { speechProgress } = payload;
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const values = { speechProgress };
    const userId = auth.user.id;

    listenerApi.dispatch(challengeActions.update(values));

    // firebase
    updateChallengeFirebase(userId, id, classRoomId, values);
  },
});

// challenge/bootstrapModal
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.bootstrapModal,
  effect: async ({ payload }, listenerApi) => {
    const { challenge, section } = payload;
    const { challenges } = stateUtils.get(listenerApi);

    listenerApi.dispatch(challengeFlowActions.pause());
    listenerApi.dispatch(challengeActions.set(challenge));
    listenerApi.dispatch(challengeSectionActions.set(section));
    listenerApi.dispatch(challengeCodeActions.set(challenge.code));
    listenerApi.dispatch(
      nextChallengeActions.async.set(challenge, challenges.hash)
    );
  },
});

// private

function setNextChallenge(
  challenge: ChallengeCommonProps,
  hash: ChallengesCommonHashProps,
  listenerApi: listenerApiType
) {
  const challenges = challengeCommonUtils.merge(challenge, hash);
  listenerApi.dispatch(nextChallengeActions.async.set(challenge, challenges));
}

function updatePlayerClassRoomFirebase(
  userId: number | string,
  classRoomId: number,
  values: PlayerClassRoomFirebaseUpdateProps
) {
  if (!userId) return;
  if (!classRoomId) return;

  const resource = new PlayerClassRoomFirebaseService();
  resource.update(userId, classRoomId, values);
}

function updateChallengeFirebase(
  userId: number | string,
  challengeId: number,
  classRoomId: number,
  data: ChallengeFirebaseUpdateProps
) {
  if (!userId) return;
  if (!challengeId) return;
  if (!classRoomId) return;

  const resource = new PlayerChallengeFirebaseService();
  resource.update(challengeId, userId, classRoomId, data);
}

export default challengeMiddleware;
