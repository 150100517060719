// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

// utils
import color from "styles/color";

const IconWhoreLabs = ({ active }: AttentionWhoreElProps) => {
  return (
    <span
      className={`${
        active ? color.pink : undefined
      } nerdfy_pink pixelify_font_family battle_icon_cmd`}
    >
      code
    </span>
  );
};

export default IconWhoreLabs;
