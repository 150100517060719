// interfaces
import { AuthProps, AuthResourceProps } from "interfaces/auth";

// entities
import userParser from "parsers/userParser";

// enums
import { UserGenreType } from "enums/userEnum";

// utils
import isEmpty from "lodash/isEmpty";

function map({
  user,
  accessToken,
  firebaseToken,
}: AuthResourceProps): AuthProps {
  return {
    anonymous: false,
    subscribed: false,
    user: userParser.map(user),
    signed: !!(!isEmpty(user) && accessToken),
    firebaseSigned: !!(!isEmpty(user) && firebaseToken),
  };
}

function fake(userId: string, genre?: UserGenreType): AuthProps {
  return {
    signed: true,
    anonymous: true,
    subscribed: false,
    firebaseSigned: true,
    user: userParser.fake(userId, genre),
  };
}

const authParser = {
  map,
  fake,
};

export default authParser;
