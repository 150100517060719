// utils
import s from "lodash/shuffle";
import numberUtils from "utils/numberUtils";

function getRandom(): string {
  const animations = shuffle();
  return animations[numberUtils.randomInterval(0, animations.length - 1)];
}

function shuffle(): string[] {
  return s([
    "animate__backInDown",
    "animate__backInLeft",
    "animate__backInRight",
    "animate__bounceInDown",
    "animate__bounceInLeft",
    "animate__bounceInRight",
    "animate__bounceInLeft",
  ]);
}

const animationUtils = {
  getRandom,
};

export default animationUtils;
