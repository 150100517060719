// react
import { IonicSlides } from "@ionic/react";
import { useState, useCallback, useRef } from "react";

// swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// components
import ProgressBar from "components/ProgressBar";
import FormHeader from "components/form/FormHeader";
import { DropZoneAttentionWhore } from "components/DropZone";
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// enums
import { BubbleType } from "enums/bubbleEnum";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";

// utils
import color from "styles/color";
import styled from "styled-components";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: ${color.dark.bg};
  color: ${color.grey.light};

  .how_to_play {
    font-size: 1.3em;
    color: ${color.yellow};
  }

  .rules {
    font-size: 1.3em;
    color: ${color.yellow};
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    .highlight_code {
      display: inline-block;
      color: ${color.green};
      background: ${color.grey.dark};
      box-shadow: 0 2px 0 0 ${color.grey.stronger};
      border-radius: 10px;
      font-size: 16px;
      margin: 5px 2px;
      padding: 5px 8px;
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 20px 0;
      padding: 0;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        font-size: 1.1em;

        &.separator {
          height: 2px;
          width: 100%;
          margin: 15px 0;
          background: ${color.grey.dark};
        }

        div.index {
          width: 75px;
          height: 50px;

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 100%;
            border-radius: 50%;
            background: ${color.grey.dark};
            color: ${color.white};
          }
        }

        p {
          flex: 1;
          margin: 0;
          padding: 0;
          line-height: 28px;
          text-align: left;
        }
      }
    }

    p {
      text-align: center;
    }
  }
`;

interface HowToPlaySlideProps {
  forward(): void;
}

const HowToPlaySlide = ({ forward }: HowToPlaySlideProps) => {
  return (
    <>
      <FormHeader
        forward={forward}
        fakeBrand={<h2 className="how_to_play">How to Play</h2>}
      />
      <div className="content">
        <ul>
          <li>
            <div className="index">
              <span>1</span>
            </div>{" "}
            <p>
              <span className="nerdfy_red">"</span>
              <span className="nerdfy_white">Print a Hello World message</span>
              <span className="nerdfy_red">"</span>
            </p>
          </li>

          <li className="separator"></li>

          <li>
            <div className="index">
              <span>2</span>
            </div>{" "}
            <p>
              The <span className="nerdfy_orange">pieces</span> you will
              receive: <span className="highlight_code">console.log</span>,{" "}
              <span className="highlight_code">)</span>,{" "}
              <span className="highlight_code">(</span>,{" "}
              <span className="highlight_code">"Hello World"</span>,{" "}
              <span className="highlight_code">;</span>
            </p>
          </li>

          <li className="separator"></li>

          <li>
            <div className="index">
              <span>3</span>
            </div>{" "}
            <p>
              Like a <span className="nerdfy_yellow">puzzle</span>, you should{" "}
              <span className="nerdfy_pink">drag</span> the{" "}
              <span className="nerdfy_orange">pieces</span> in this{" "}
              <span className="nerdfy_blue">order</span>:{" "}
              <span className="highlight_code">console.log</span>,{" "}
              <span className="highlight_code">(</span>,{" "}
              <span className="highlight_code">"Hello World"</span>,{" "}
              <span className="highlight_code">)</span>,{" "}
              <span className="highlight_code">;</span>
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

interface RulesProps {
  back(): void;
  save(): void;
  disabled: boolean;
}

const RuleSlide = ({ back, save, disabled }: RulesProps) => {
  return (
    <>
      <FormHeader back={back} proceed={save} disabled={disabled} />
      <div className="content">
        <ul>
          <li>
            <div className="index">
              <span>4</span>
            </div>{" "}
            <p>
              The streak <span className="nerdfy_white">resets</span> if the
              user <span className="nerdfy_red">loses</span> a battle or{" "}
              <span className="nerdfy_orange">skips</span> a{" "}
              <span className="nerdfy_blue">weekday</span>{" "}
            </p>
          </li>

          <li className="separator"></li>

          <li>
            <div className="index">
              <span>5</span>
            </div>{" "}
            <p>
              The <span className="nerdfy_yellow">streak</span> does{" "}
              <span className="nerdfy_white">NOT</span> reset if the user{" "}
              <span className="nerdfy_orange">skips</span> the{" "}
              <span className="nerdfy_blue">weekend</span>
            </p>
          </li>

          <li className="separator"></li>

          <li>
            <div className="index">
              <span>6</span>
            </div>{" "}
            <p>
              <span className="nerdfy_purple">You</span> have{" "}
              <span className="nerdfy_white">365 days (1 year)</span> to
              complete all <span className="nerdfy_yellow">challenges</span>.
            </p>
          </li>

          <li className="separator"></li>

          <li>
            <div className="index">
              <span>7</span>
            </div>{" "}
            <p>
              There are <span className="nerdfy_white">three types</span> of
              challenges: <span className="nerdfy_purple">intro</span>,{" "}
              <span className="nerdfy_orange">battle</span>, and{" "}
              <span className="nerdfy_green">quiz</span>.
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

const DragMeTemplateEl = () => {
  return <p className="nerdfy_yellow pixelify_font_family">Drag me!</p>;
};

const SLIDE_HOW_TO_PLAY = 0;
const SLIDE_RULES = 1;

interface HowToPlayPresentationProps {
  close: () => void;
}

const HowToPlayPresentation = ({ close }: HowToPlayPresentationProps) => {
  const [progress, setProgress] = useState(0.5);
  const swiperRef = useRef<SwiperProps | undefined>();
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [wrapper, setWrapper] = useState<HTMLDivElement | undefined>();
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());
  const handle = useCallback((node: HTMLDivElement) => {
    if (!node) return;
    setWrapper(node);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSave() {
    setTimeout(addWhore);
  }

  function addWhore() {
    const payload = `Be careful, some pieces may hide behind others, so you may need to search to find them. Let's destroy as many creatures as possible. May the odds be ever in our favor!`;
    setWhores([getWhore(payload, true)]);
  }

  function getWhore(payload: string, last?: boolean): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      last,
      payload,
      WhoreEl: DragMeTemplateEl,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "yellow",
      bubbleType: BubbleType.Warning,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });

    setTimeout(close, 150);
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;

    listenToSlideChange(swiperRef.current);
  }

  function listenToSlideChange(swiper: SwiperProps) {
    swiper.on("slideChange", ({ activeIndex }: SwiperProps) => {
      activeIndex ? setProgress(1) : setProgress(0.5);
    });
  }

  function goToHowToPlay() {
    setProgress(0.5);
    setTimeout(() => slideTo(SLIDE_HOW_TO_PLAY));
  }

  function goToRules() {
    setProgress(1);
    setTimeout(() => slideTo(SLIDE_RULES));
  }

  function slideTo(index: number) {
    if (!swiperRef.current) return;
    swiperRef.current.slideTo(index, 300);
  }

  return (
    <Wrapper
      ref={handle}
      className="animate__animated no-swipe animate__bounceInLeft"
    >
      <ProgressBar value={progress} color={color.green} />

      <Swiper
        initialSlide={0}
        slidesPerView={1}
        onSwiper={handleSwiper}
        modules={[IonicSlides]}
        noSwipingClass="no_swipe"
      >
        <SwiperSlide>
          <HowToPlaySlide forward={goToRules} />
        </SwiperSlide>
        <SwiperSlide>
          <RuleSlide
            save={onSave}
            back={goToHowToPlay}
            disabled={whores.length > 0}
          />
        </SwiperSlide>
      </Swiper>

      <AttentionWhores whores={whores} />

      <BubbleHandler
        hide={hideJarvisBubble}
        show={!!attentionWhore.show}
        content={attentionWhore.payload}
        type={attentionWhore.bubbleType}
      />

      <DropZoneAttentionWhore />
    </Wrapper>
  );
};

export default HowToPlayPresentation;
