// react
import React, { useEffect, useState, useRef } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// svgs
import HeartPixelSvg from "svg/HeartPixelSvg";

// components
import AvatarBittle from "components/avatars/AvatarBittle";
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// utils
import c from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div<{ bgImage: string }>`
  ::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(${(props) => props.bgImage});
  }

  .avatar_button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 6000;
    width: 50px;
    height: 45px;
    top: 8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 3px;
  }

  .hearts {
    display: flex;
    list-style: none;
    padding: 0;
    position: absolute;
    top: 5px;
    right: 18px;
    width: 70px;
    height: 18px;

    li {
      margin: 0 3px;
    }
  }
`;

interface AvatarButtonProps {
  onClick(): void;
  customClassName: string;
  avatar: React.ReactNode;
  onAnimationEnd(animateName: string): void;
  color?: string;
}

const AvatarButton = ({
  avatar,
  onClick,
  customClassName,
  onAnimationEnd,
  color = c.yellow,
}: AvatarButtonProps) => {
  return (
    <ButtonRippleEffect
      color={color}
      onClick={onClick}
      onAnimationEnd={onAnimationEnd}
      className={`${customClassName} avatar_button`}
    >
      {avatar}
    </ButtonRippleEffect>
  );
};

interface BittlesHandlerProps {
  init: boolean;
  show: boolean;
  hide: boolean;
  missed: number;
  success: boolean;
}

const BittlesHandler = ({
  init,
  show,
  hide,
  missed,
  success,
}: BittlesHandlerProps) => {
  const playerClassRoom = useSelector(s.playerClassRoom());
  const avatarAlreadySet = useRef(false);
  const avatarAlreadyInit = useRef(false);
  const [avatar, setAvatar] = useState(0);
  const [animation, setAnimation] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleAnimation, [init, show, success, hide]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleAvatar, [playerClassRoom.checkpoint]);

  function handleAvatar() {
    if (avatarAlreadySet.current) return;

    avatarAlreadySet.current = true;
    setAvatar(playerClassRoom.battle + 1);
  }

  function handleAnimation() {
    if (!init) return;

    if (init && !avatarAlreadyInit.current) {
      avatarAlreadyInit.current = true;
      setAnimation("animate__backInUp");

      return;
    }

    if (hide) {
      setAnimation("animate__backOutLeft");
      return;
    }

    show
      ? setAnimation("animate__backInUp")
      : success
      ? setAnimation("animate__flip")
      : setAnimation("animate__hinge");
  }

  function onAnimationEnd(animateName: string) {
    if (animateName === "flip")
      return setTimeout(() => setAnimation("animate__backOutRight"));
  }

  return (
    <>
      {avatar && (
        <Wrapper bgImage={`/assets/imgs/monsters/png/monster${avatar}.png`}>
          {animation && (
            <AvatarButton
              onClick={() => {}}
              onAnimationEnd={onAnimationEnd}
              customClassName={`animate__animated ${animation}`}
              avatar={
                <div className="animate_y_infinite">
                  <AvatarBittle index={avatar} isMad={!success} />
                </div>
              }
            />
          )}

          <ul className="hearts">
            <li
              className={
                show
                  ? missed <= 0.7
                    ? "animate__animated animate__jello"
                    : "animate__animated animate__heartBeat"
                  : ""
              }
            >
              <HeartPixelSvg
                primaryColor={missed <= 0.7 ? c.grey.stronger : undefined}
                secondaryColor={missed <= 0.7 ? c.grey.stronger : undefined}
              />
            </li>
            <li
              className={
                show
                  ? missed <= 0.3
                    ? "animate__animated animate__jello"
                    : "animate__animated animate__heartBeat"
                  : ""
              }
            >
              <HeartPixelSvg
                primaryColor={missed <= 0.3 ? c.grey.stronger : undefined}
                secondaryColor={missed <= 0.3 ? c.grey.stronger : undefined}
              />
            </li>
            <li
              className={
                show
                  ? missed <= 0
                    ? "animate__animated animate__jello"
                    : "animate__animated animate__heartBeat"
                  : ""
              }
            >
              <HeartPixelSvg
                primaryColor={missed <= 0 ? c.grey.stronger : undefined}
                secondaryColor={missed <= 0 ? c.grey.stronger : undefined}
              />
            </li>
          </ul>
        </Wrapper>
      )}
    </>
  );
};

export default React.memo(BittlesHandler);
