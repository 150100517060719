// react
import { useState, useEffect } from "react";

// widgets
import StatsWidget from "widgets/StatsWidget";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-around;
  width: 100%;
  height: 150px;
  z-index: 100;
  position: absolute;
  bottom: 0;
  left: 0;
  background: ${color.grey.dark};

  .feedback_info {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .feedback_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .feedback_button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: 0 10px;

      &:last-child {
        margin: 0 0 0 10px;
      }
    }

    .f_button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      font-size: 16px;
      padding: 18px;

      &.f_button_star {
        svg {
          color: ${color.yellow};
        }
      }

      &.f_button_comment {
        svg {
          color: ${color.purple};
        }
      }

      &.f_button_down {
        svg {
          color: ${color.red};
        }
      }

      &.f_button_up {
        svg {
          color: ${color.green};
        }
      }

      &:last-child {
        margin: 0 0 0 10px;
      }
    }
  }
`;

interface ChallengeFeedbackProps {
  show: boolean;
  animate: boolean;
}

const StatsBubble = ({ show, animate }: ChallengeFeedbackProps) => {
  const [showWithAnimation, setShowWithAnimation] = useState(false);

  useEffect(handleAnimation, [animate]);

  function handleAnimation() {
    if (!animate) return;
    setTimeout(() => setShowWithAnimation(true), 1500);
  }

  return (
    <>
      {(show || showWithAnimation) && (
        <Wrapper
          className={`${animate && "animate__animated animate__bounceInLeft"}`}
        >
          <div className="feedback_info">
            <StatsWidget />
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default StatsBubble;
