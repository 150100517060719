// components
import BrandLogo, { BrandLogoMono } from "components/BrandLogo";
import CloseButton from "components/CloseButton";

// buttons
import ButtonDuolingo from "components/buttons/ButtonDuolingo";
import ButtonDuolingoBlue from "components/buttons/templates/ButtonDuolingoBlue";
import ButtonDuolingoArrowIcon from "components/buttons/ButtonDuolingoArrowIcon";
import ButtonDuolingoPurple from "components/buttons/templates/ButtonDuolingoPurple";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 0 20px;
  height: 90px;
  background: ${color.dark.bg};

  .button_z {
    z-index: 101;
  }

  &.border_bottom {
    border-bottom: 5px solid ${color.grey.dark};
  }

  &.border_top {
    border-top: 5px solid ${color.grey.dark};
  }
`;

interface FormHeaderProps {
  back?(): void;
  close?(): void;
  forward?(): void;
  fakeBrand?: JSX.Element;
  add?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  send?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  save?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  proceed?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  brand?: boolean;
  disabled?: boolean;
  brandMono?: boolean;
  hideBrand?: boolean;
  disabledBack?: boolean;
  disabledClose?: boolean;
  customClasseName?: string;
  position?: "top" | "bottom";
  buttonType?: "button" | "submit";
}

const FormHeader = ({
  add,
  save,
  back,
  send,
  close,
  brand,
  forward,
  proceed,
  disabled,
  hideBrand,
  fakeBrand,
  brandMono,
  disabledBack,
  disabledClose,
  customClasseName,
  position = "top",
  buttonType = "button",
}: FormHeaderProps) => {
  return (
    <Wrapper
      className={`${customClasseName} ${
        position === "top" ? "safe--area border_bottom" : "border_top"
      }`}
    >
      {fakeBrand ? (
        fakeBrand
      ) : hideBrand ? (
        <div />
      ) : back ? null : brand && !brandMono ? (
        <BrandLogo />
      ) : (
        <BrandLogoMono />
      )}

      {back && (
        <ButtonDuolingoArrowIcon
          backward
          onClick={back}
          disabled={disabledBack}
          customClassName="button_z"
        />
      )}

      {forward && (
        <ButtonDuolingoArrowIcon onClick={forward} customClassName="button_z" />
      )}

      {close && (
        <CloseButton
          onClick={close}
          disabled={disabledClose}
          className="button button_z"
        />
      )}

      {add && (
        <ButtonDuolingoBlue
          onClick={add}
          type={buttonType}
          disabled={disabled}
          className="button_z"
        >
          Add
        </ButtonDuolingoBlue>
      )}

      {save && (
        <ButtonDuolingoPurple
          onClick={save}
          type={buttonType}
          disabled={disabled}
          className="button_z"
        >
          Save
        </ButtonDuolingoPurple>
      )}

      {send && (
        <ButtonDuolingo onClick={send} disabled={disabled} className="button_z">
          Send
        </ButtonDuolingo>
      )}

      {proceed && (
        <ButtonDuolingoBlue
          onClick={proceed}
          type={buttonType}
          disabled={disabled}
          className="button_z"
        >
          Continue
        </ButtonDuolingoBlue>
      )}
    </Wrapper>
  );
};

interface FormHeaderWrapperProps {
  children: React.ReactNode;
}

export const FormHeaderWrapper = ({ children }: FormHeaderWrapperProps) => {
  <Wrapper className="safe--area">{children}</Wrapper>;
};

export default FormHeader;
