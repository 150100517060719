// components
import ButtonDuolingo from "components/buttons/ButtonDuolingo";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled(ButtonDuolingo)`
  span {
    display: inline-block;
    margin-right: 20px;
  }
`;

interface ButtonDuolingoIconProps {
  onClick(): void;
  disabled?: boolean;
  type?: "button" | "submit";
}

const ButtonDuolingoSignIn = ({
  onClick,
  disabled,
  type = "button",
}: ButtonDuolingoIconProps) => {
  return (
    <Wrapper
      widthFull
      type={type}
      height="40px"
      bg={color.blue}
      onClick={onClick}
      disabled={disabled}
      color={color.white}
      boxShadowColor="#256bb2"
    >
      Sign In with Email
    </Wrapper>
  );
};

export default ButtonDuolingoSignIn;
