// interfaces
import {
  ChallengeQuizProps,
  ChallengeQuizAnswersHashProps,
} from "interfaces/challengeQuiz";
import { PlayerClassRoomProps } from "interfaces/playerClassRoom";

// enums
import { ChallengeQuizLevelType } from "enums/challengeQuizEnum";

// entities
import ChallengeQuizScoreEntity from "entities/ChallengeQuizScoreEntity";
import ChallengeQuizLevelPointsEntity from "entities/ChallengeQuizLevelPointsEntity";

// utils
import { serverTimestamp, FieldValue } from "firebase/firestore";

interface FlowInitValuesProps {
  id: number;
  type: number;
  languageId: number;
  classRoomId: number;
  userId: string | number;
  challengeSectionId: number;

  // timestamp
  initOn: FieldValue;
}

interface FlowStartedValuesProps {
  // timestamp
  startedOn: FieldValue;
}

interface FlowDoneValuesProps {
  multi: boolean;
  generic: boolean;
  answers: string[];
  correct: number[];
  question: string[];
  level: ChallengeQuizLevelType;
  answer: ChallengeQuizAnswersHashProps;

  // flows
  flowDone: boolean;

  // timestamp
  doneOn: FieldValue;
  finishedOn: FieldValue;
}

interface FlowFinishedValuesProps {
  // timestamp
  finishedOn: FieldValue;
}
interface FlowSuccessValuesProps extends FlowDoneValuesProps {
  scoreGained: number;
  levelPointGained: number;

  // flows
  flowSuccess: true;

  // timestamp
  successOn: FieldValue;
}

interface FlowFailedValuesProps extends FlowDoneValuesProps {
  scoreGained: number;
  levelPointGained: number;

  // flows
  flowFailed: true;

  // timestamp
  failedOn: FieldValue;
}

class ChallengeQuizFirebaseEntity {
  getFlowInitValues(
    userId: string | number,
    quiz: ChallengeQuizProps
  ): FlowInitValuesProps {
    return {
      userId,
      id: quiz.id,
      type: quiz.type,
      initOn: serverTimestamp(),
      languageId: quiz.languageId,
      classRoomId: quiz.classRoomId,
      challengeSectionId: quiz.challengeSectionId,
    };
  }

  getFlowStartedValues(): FlowStartedValuesProps {
    return {
      startedOn: serverTimestamp(),
    };
  }

  getFlowFinishedValues(): FlowFinishedValuesProps {
    return {
      finishedOn: serverTimestamp(),
    };
  }

  getSuccessValues(
    player: PlayerClassRoomProps,
    quiz: ChallengeQuizProps,
    quizAnswer: ChallengeQuizAnswersHashProps
  ): FlowSuccessValuesProps {
    const data = this.getDoneValues(quiz, quizAnswer);
    const quizLevel = new ChallengeQuizLevelPointsEntity({
      level: quiz.level,
      player,
    });
    const quizScoreEntity = new ChallengeQuizScoreEntity({ level: quiz.level });

    return {
      ...data,
      flowSuccess: true,
      successOn: serverTimestamp(),
      levelPointGained: quizLevel.gained(),
      scoreGained: quizScoreEntity.gained(),
    };
  }

  getFailedValues(
    player: PlayerClassRoomProps,
    quiz: ChallengeQuizProps,
    quizAnswer: ChallengeQuizAnswersHashProps
  ): FlowFailedValuesProps {
    const data = this.getDoneValues(quiz, quizAnswer);
    const quizLevel = new ChallengeQuizLevelPointsEntity({
      level: quiz.level,
      player,
    });
    const quizScoreEntity = new ChallengeQuizScoreEntity({ level: quiz.level });

    return {
      ...data,
      flowFailed: true,
      failedOn: serverTimestamp(),
      levelPointGained: quizLevel.lost(),
      scoreGained: quizScoreEntity.lost(),
    };
  }

  private getDoneValues(
    quiz: ChallengeQuizProps,
    quizAnswer: ChallengeQuizAnswersHashProps
  ): FlowDoneValuesProps {
    return {
      flowDone: true,
      level: quiz.level,
      multi: quiz.multi,
      generic: quiz.generic,
      answers: quiz.answers,
      correct: quiz.correct,
      question: quiz.question,
      doneOn: serverTimestamp(),
      finishedOn: serverTimestamp(),
      answer: this.clearAnswer(quizAnswer),
    };
  }

  private clearAnswer(
    answer: ChallengeQuizAnswersHashProps
  ): ChallengeQuizAnswersHashProps {
    const result: ChallengeQuizAnswersHashProps = {};

    for (const key in answer) {
      if (!answer.hasOwnProperty(key)) continue;

      const value = answer[key];
      if (value) result[key] = value;
    }

    return result;
  }
}

export default ChallengeQuizFirebaseEntity;
