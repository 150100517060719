// redux
import { playerClassRoomActions } from "redux/slices/playerClassRoomSlice";
import { createListenerMiddleware } from "@reduxjs/toolkit";

// interfaces
import { PlayerClassRoomFirebaseUpdateProps } from "interfaces/playerClassRoomFirebase";

// services
import PlayerClassRoomFirebaseService from "services/firebase/player/PlayerClassRoomFirebaseService";

// utils
import stateUtils from "redux/utils/stateUtils";

const playerClassRoomMiddleware = createListenerMiddleware();

playerClassRoomMiddleware.startListening({
  actionCreator: playerClassRoomActions.async.update,
  effect: async ({ payload }, listenerApi) => {
    const { auth, classRoom } = stateUtils.get(listenerApi);

    if (!auth) return;
    if (!auth.user) return;
    if (!auth.user.id) return;
    if (!classRoom.id) return;

    updatePlayerClassRoomFirebase(auth.user.id, classRoom.id, payload);
  },
});

// private

function updatePlayerClassRoomFirebase(
  userId: number | string,
  classRoomId: number,
  values: PlayerClassRoomFirebaseUpdateProps
) {
  if (!userId) return;
  if (!classRoomId) return;

  const resource = new PlayerClassRoomFirebaseService();
  resource.update(userId, classRoomId, values);
}

export default playerClassRoomMiddleware;
