// interfaces
import { UserProps, UserResourceProps } from "interfaces/user";

// enums
import { UserGenreType } from "enums/userEnum";

function map(user: UserResourceProps): UserProps {
  return {
    id: user.id,
    email: user.email,
    image: user.image || "",
    languageId: user.languageId,
    lastName: user.lastName || "",
    firstName: user.firstName || "",
    givenName: user.givenName || "",
    genre: user.genre || UserGenreType.Unknown,
    emailActivated: !!user.emailActivated || false,
  };
}

function fake(id: string, genre?: UserGenreType): UserProps {
  return {
    id,
    image: "",
    email: "",
    lastName: "",
    firstName: "",
    givenName: "",
    languageId: 0,
    emailActivated: true,
    genre: genre || UserGenreType.Unknown,
  };
}

const userParser = {
  map,
  fake,
};

export default userParser;
