// react
import React, { useEffect, useState, useRef } from "react";

// svgs
import HeartPixelSvg from "svg/HeartPixelSvg";

// components
import { AvatarJarvisBg } from "components/avatars/AvatarJarvis";
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// utils
import c from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div<{ bgImage: string }>`
  ::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(${(props) => props.bgImage});
  }

  .avatar_button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 6000;
    width: 50px;
    height: 45px;
    top: 8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 3px;
  }

  .hearts {
    display: flex;
    list-style: none;
    padding: 0;
    position: absolute;
    top: 5px;
    right: 18px;
    width: 70px;
    height: 18px;

    li {
      margin: 0 3px;
    }
  }
`;

interface AvatarButtonProps {
  onClick(): void;
  customClassName: string;
  avatar: React.ReactNode;
  onAnimationEnd(animateName: string): void;
  color?: string;
}

const AvatarButton = ({
  avatar,
  onClick,
  customClassName,
  onAnimationEnd,
  color = c.yellow,
}: AvatarButtonProps) => {
  return (
    <ButtonRippleEffect
      color={color}
      onClick={onClick}
      onAnimationEnd={onAnimationEnd}
      className={`${customClassName} avatar_button`}
    >
      {avatar}
    </ButtonRippleEffect>
  );
};

interface BittlesHandlerProps {
  init: boolean;
  show: boolean;
  hide: boolean;
  success: boolean;
}

const JarvisHandler = ({ init, show, hide, success }: BittlesHandlerProps) => {
  const avatarAlreadyInit = useRef(false);
  const [animation, setAnimation] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleAnimation, [init, show, success, hide]);

  function handleAnimation() {
    if (!init) return;

    if (init && !avatarAlreadyInit.current) {
      avatarAlreadyInit.current = true;
      setAnimation("animate__backInUp");

      return;
    }

    if (hide) {
      setAnimation("animate__backOutLeft");
      return;
    }

    show
      ? setAnimation("animate__backInUp")
      : success
      ? setAnimation("animate__flip")
      : setAnimation("animate__hinge");
  }

  function onAnimationEnd(animateName: string) {
    if (animateName === "flip")
      return setTimeout(() => setAnimation("animate__backOutRight"));
  }

  return (
    <Wrapper bgImage={`/assets/imgs/jarvis.svg`}>
      {animation && (
        <AvatarButton
          onClick={() => {}}
          onAnimationEnd={onAnimationEnd}
          customClassName={`animate__animated ${animation}`}
          avatar={
            <div className="animate_y_infinite">
              <AvatarJarvisBg />
            </div>
          }
        />
      )}

      <ul className="hearts">
        <li className={(init && "animate__animated animate__heartBeat") || ""}>
          <HeartPixelSvg secondaryColor={c.red} primaryColor={c.purple} />
        </li>
        <li className={(init && "animate__animated animate__heartBeat") || ""}>
          <HeartPixelSvg secondaryColor={c.red} primaryColor={c.purple} />
        </li>
        <li className={(init && "animate__animated animate__heartBeat") || ""}>
          <HeartPixelSvg secondaryColor={c.red} primaryColor={c.purple} />
        </li>
      </ul>
    </Wrapper>
  );
};

export default React.memo(JarvisHandler);
