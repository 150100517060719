// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import { PlayerClassRoomRatingProps } from "interfaces/playerClassRoom";

// utils
import isEmpty from "lodash/isEmpty";

class PlayerRatingDataFirebaseService {
  private _resource = "players";
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  async update(
    userId: number | string,
    data: PlayerClassRoomRatingProps
  ): Promise<void> {
    if (!userId) return Promise.reject();
    if (isEmpty(data.rating)) return Promise.reject();

    const path = `${this._resource}/${userId}`;
    return this._firebase.set(path, data);
  }
}

export default PlayerRatingDataFirebaseService;
