// redux
// import { useDispatch } from "react-redux";
// import { tagActions } from "redux/slices/tagSlice";

// interfaces
// import { TagProps } from "interfaces/tag";
import { ChallengeTypeHandlerCommonProps } from "components/challenge/Challenge";

// factories
// import { AlertFactoryType } from "factories/AlertFactory";

// handlers
import JarvisHandler from "handlers/JarvisHandler";
import ChallengeBattleProgressHandler from "handlers/ChallengeBattleProgressHandler";
import JarvisQuestionBubbleHandler from "handlers/jarvisQuestionBubble/JarvisQuestionBubbleHandler";

// components
// import { tagActions } from "redux/slices/tagSlice";
import { DropZoneDragWhore } from "components/DropZone";
// import tagGuesserUtils from "utils/code/tagGuesserUtils";
import WelcomeIntroHandle from "handlers/WelcomeIntroHandle";
import SudoHandler from "handlers/challengeButtons/sudo/SudoHandler";
import ChallengeBattle from "components/challenge/battle/ChallengeBattle";
// import { TagProps } from "interfaces/tag";
// import { useEffect } from "react";
// import { challengeActions } from "redux/slices/challengeSlice";

// utils
import color from "styles/color";
import blinkUtils from "utils/blinkUtils";
// import numberUtils from "utils/numberUtils";
// import tagGuesserUtils from "utils/code/tagGuesserUtils";

// interface CofCofProps {
//   content: string;
//   type: AlertFactoryType;
// }

interface ChallengeIntroductionProps extends ChallengeTypeHandlerCommonProps {
  getBiggestStepLength(): number;
  blinkElRef: React.MutableRefObject<HTMLDivElement>;
}

const ChallengeIntroduction = ({
  code,
  close,
  paused,
  challenge,
  wrapperRef,
  isTagValid,
  blinkElRef,
  onSuccessTag,
  handlerWrapperRef,
  wrapperNotifiersRef,
  handlerNotifiersRef,
  getBiggestStepLength,
}: ChallengeIntroductionProps) => {
  const { flowInit, flowDone, flowFinished, flowSuccess } = challenge;

  function blink() {
    if (!blinkElRef.current) return;
    blinkUtils.blinkPurple(blinkElRef.current, 100);
  }

  return (
    <>
      <ChallengeBattleProgressHandler
        color={color.green}
        challenge={challenge}
        getBiggestStepLength={getBiggestStepLength}
      />

      <ChallengeBattle
        code={code}
        close={close}
        paused={paused}
        onFailedTag={blink}
        challenge={challenge}
        wrapperRef={wrapperRef}
        isTagValid={isTagValid}
        onSuccessTag={onSuccessTag}
        handlerWrapperRef={handlerWrapperRef}
        handlerNotifiersRef={handlerNotifiersRef}
        wrapperNotifiersRef={wrapperNotifiersRef}
      />

      <DropZoneDragWhore />

      {flowInit && (
        <>
          <SudoHandler
            paused={paused}
            challenge={challenge}
            wrapper={wrapperRef.current}
          />

          <JarvisQuestionBubbleHandler
            paused={paused}
            challenge={challenge}
            wrapper={wrapperRef.current}
          />

          <WelcomeIntroHandle
            clear={flowDone}
            paused={paused}
            wrapper={wrapperRef.current}
          />
        </>
      )}

      <JarvisHandler
        hide={paused}
        init={flowInit}
        show={!paused && !flowDone}
        success={flowDone && flowFinished && flowSuccess}
      />
    </>
  );
};

// function cofCof() {
//   const wrapper = wrapperRef.current;
//   if (!wrapper) return;

//   const { clientWidth, clientHeight } = wrapper;

//   alertAnimate({
//     wrapper: wrapper,
//     factories: [AlertFactory({ ...getGoodCofCof(), slower: true })],
//     bounds: {
//       top: `${numberUtils.randomInterval(100, clientHeight - 200)}px`,
//       left: `${numberUtils.randomInterval(100, clientWidth - 200)}px`,
//     },
//   });
// }

// function getGoodCofCof(): CofCofProps {
//   const random = ["???", "?????", "????????"];

//   return {
//     content: random[numberUtils.randomInterval(0, random.length - 1)],
//     type: AlertFactoryType.Success,
//   };
// }

// function help() {
//   if (flowDone) return;
//   if (flowInit && !flowStarted) return;

//   const tag = tagGuesserUtils.get(tags, specialTags, code);
//   if (!tag) return;

//   add({ ...tag, fromAvatar: true });
//   dispatch(tagActions.async.help());
// }

// function add(tag: TagProps) {
//   if (isTagValid(tag)) {
//     onSuccessTag(tag);

//     return;
//   }
// }

export default ChallengeIntroduction;
