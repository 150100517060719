// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { tagActions } from "redux/slices/tagSlice";
import { useDispatch, useSelector } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";

// components
import ChallengeExercise from "components/challenge/ChallengeExercise";
import ChallengeIntroduction from "components/challenge/ChallengeIntroduction";

// interfaces
import { TagProps, SpecialTagProps } from "interfaces/tag";
import { ChallengeCodeProps } from "interfaces/challengeCode";
import { ChallengeCodeStepProps } from "interfaces/challengeCode";
import { ChallengeCommonProps, ChallengeProps } from "interfaces/challenge";

// enums
import { ChallengeType } from "enums/challengeEnum";

// utils
import audioUtils from "utils/audioUtils";
import tagStepUtils from "utils/code/tagStepUtils";
import validatorUtils from "utils/code/validatorUtils";

interface ChallengeCommonHandlerProps {
  close(): void;
  handlerWrapperRef: (node: HTMLDivElement) => void;
  handlerNotifiersRef: (node: HTMLDivElement) => void;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  wrapperNotifiersRef: React.MutableRefObject<HTMLDivElement | null>;
}

interface ChallengeHandlerProps extends ChallengeCommonHandlerProps {
  blinkElRef: React.MutableRefObject<HTMLDivElement>;
  currentChallengeRef: React.MutableRefObject<ChallengeCommonProps | null>;
}

export interface ChallengeTypeHandlerCommonProps
  extends ChallengeCommonHandlerProps {
  paused: boolean;
  code: ChallengeCodeProps;
  challenge: ChallengeProps;
  onSuccessTag(tag: TagProps): void;
  isTagValid(tag: TagProps): boolean;
}

const Challenge = ({
  close,
  blinkElRef,
  wrapperRef,
  handlerWrapperRef,
  currentChallengeRef,
  handlerNotifiersRef,
  wrapperNotifiersRef,
}: ChallengeHandlerProps) => {
  const dispatch = useDispatch();
  const code = useSelector(s.challengeCode());
  const challenge = useSelector(s.challenge());
  const { paused } = useSelector(s.challengeFlow());
  const specialTagsRef = useRef<SpecialTagProps[]>([]);
  const currentStepTags = useRef<TagProps[]>([]);
  const currentStepTagIndex = useRef(0);
  const challengeCodeRef = useRef<ChallengeCodeProps | null>(null);
  const { flowDone, specialTags } = challenge;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenToCurrentChallenge, [challenge]);
  useEffect(handleCurrentStepTags, [code]);
  useEffect(handleChallengeCode, [code]);
  useEffect(handleCurrentStepTags, [code]);
  useEffect(handleSpecialTags, [specialTags]);
  useEffect(clearCurrentStepTagsIndex, [flowDone]);

  function listenToCurrentChallenge() {
    currentChallengeRef.current = challenge;
  }

  function handleCurrentStepTags() {
    currentStepTags.current = getCurrentStepsTags();
  }

  function handleChallengeCode() {
    challengeCodeRef.current = code;
  }

  function handleSpecialTags() {
    specialTagsRef.current = specialTags;
  }

  function clearCurrentStepTagsIndex() {
    if (flowDone) currentStepTagIndex.current = 0;
  }

  function successTag(tag: TagProps) {
    audioUtils.play.hit();

    currentStepTags.current = getCurrentStepsTags();
    currentStepTagIndex.current += 1;

    const specialTags = getSpecialTags(tag, currentStepTags.current);

    updateCurrentSpecialStepTags(specialTags, currentStepTags.current);
    dispatch(tagActions.async.add({ tag, specialTags }));
  }

  function getCurrentStepsTags(): TagProps[] {
    const steps =
      (challengeCodeRef.current && challengeCodeRef.current.steps) || [];

    return tagStepUtils.getCurrentByIndex(steps, currentStepTagIndex.current);
  }

  function updateCurrentSpecialStepTags(
    specialTags: SpecialTagProps[],
    steps: TagProps[]
  ) {
    if (!isCurrentStepSpecial(steps)) return;

    dispatch(
      challengeActions.update({
        specialTags,
      })
    );
  }

  function getSpecialTags(tag: TagProps, steps: TagProps[]): SpecialTagProps[] {
    if (!isCurrentStepSpecial(steps)) return specialTagsRef.current;
    return [...specialTagsRef.current, { tag, step: getCurrentStep(steps) }];
  }

  function isCurrentStepSpecial(steps: TagProps[]): boolean {
    return !!getCurrentStep(steps).special;
  }

  function getCurrentStep(steps: TagProps[]): TagProps {
    return steps[0];
  }

  function isTagValid(tag: TagProps): boolean {
    return validatorUtils.is(
      tag,
      currentStepTags.current,
      specialTagsRef.current
    );
  }

  function getBiggestStepLength(): number {
    if (!code) return 0;
    if (!code.steps) return 0;

    return code.steps.reduce((acc: number, list: ChallengeCodeStepProps) => {
      const { length } = list.data;

      if (acc < length) acc = length;
      return acc;
    }, 0);
  }

  return (
    <>
      {challenge.type === ChallengeType.Introduction && (
        <ChallengeIntroduction
          code={code}
          close={close}
          paused={paused}
          challenge={challenge}
          blinkElRef={blinkElRef}
          wrapperRef={wrapperRef}
          isTagValid={isTagValid}
          onSuccessTag={successTag}
          handlerWrapperRef={handlerWrapperRef}
          wrapperNotifiersRef={wrapperNotifiersRef}
          handlerNotifiersRef={handlerNotifiersRef}
          getBiggestStepLength={getBiggestStepLength}
        />
      )}

      {challenge.type === ChallengeType.Exercise && (
        <ChallengeExercise
          code={code}
          close={close}
          paused={paused}
          challenge={challenge}
          blinkElRef={blinkElRef}
          wrapperRef={wrapperRef}
          isTagValid={isTagValid}
          onSuccessTag={successTag}
          handlerWrapperRef={handlerWrapperRef}
          wrapperNotifiersRef={wrapperNotifiersRef}
          handlerNotifiersRef={handlerNotifiersRef}
          getBiggestStepLength={getBiggestStepLength}
        />
      )}
    </>
  );
};

export default Challenge;
