// interfaces
import { ChallengeProps } from "interfaces/challenge";
import { PlayerClassRoomProps } from "interfaces/playerClassRoom";
import { PlayerClassRoomDaysFirebaseProps } from "interfaces/playerClassRoomFirebase";

// utils
import { increment, FieldValue, serverTimestamp } from "firebase/firestore";
import { ChallengeType } from "enums/challengeEnum";

interface DoneValuesProps {
  last: FieldValue;
  streak: FieldValue;
  checkpoint: FieldValue;
  days: PlayerClassRoomDaysFirebaseProps;

  maxStreak?: FieldValue;
}

interface IntroDoneValuesProps extends DoneValuesProps {
  intro: FieldValue;
}

interface ExerciseDoneValuesProps extends DoneValuesProps {
  battle: FieldValue;
}

interface FailedValuesProps {
  streak: number;
  last: FieldValue;
  killed: FieldValue;
  checkpoint: FieldValue;
}

class PlayerClassRoomFirebaseEntity {
  getHelpValues(): { help: FieldValue } {
    return { help: increment(1) };
  }

  getMissedValues(): { missed: FieldValue } {
    return { missed: increment(1) };
  }

  getDoneValues(
    challenge: ChallengeProps,
    playerClassRoom: PlayerClassRoomProps
  ): IntroDoneValuesProps | ExerciseDoneValuesProps {
    if (challenge.type === ChallengeType.Introduction)
      return this.getIntroDoneValues(playerClassRoom);

    return this.getExerciseDoneValues(playerClassRoom);
  }

  private getIntroDoneValues(
    playerClassRoom: PlayerClassRoomProps
  ): IntroDoneValuesProps {
    const data: IntroDoneValuesProps = {
      intro: increment(1),
      streak: increment(1),
      last: serverTimestamp(),
      checkpoint: increment(1),
      days: { [playerClassRoom.checkpoint + 1]: true },
    };

    if (playerClassRoom.streak === playerClassRoom.maxStreak)
      data.maxStreak = increment(1);

    return data;
  }

  private getExerciseDoneValues(
    playerClassRoom: PlayerClassRoomProps
  ): ExerciseDoneValuesProps {
    const data: ExerciseDoneValuesProps = {
      battle: increment(1),
      streak: increment(1),
      last: serverTimestamp(),
      checkpoint: increment(1),
      days: { [playerClassRoom.checkpoint + 1]: true },
    };

    if (playerClassRoom.streak === playerClassRoom.maxStreak)
      data.maxStreak = increment(1);

    return data;
  }

  getFailedValues(): FailedValuesProps {
    return {
      streak: 0,
      killed: increment(1),
      last: serverTimestamp(),
      checkpoint: increment(1),
    };
  }
}

export default PlayerClassRoomFirebaseEntity;
