// react
import { useState } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";
import { playerClassRoomActions } from "redux/slices/playerClassRoomSlice";

// factories
import {
  alertAnimate,
  AlertFactory,
  AlertFactoryType,
} from "factories/AlertFactory";

// components
import ButtonTipToggle from "components/buttons/ButtonTipToggle";
import ButtonPlayPauseToggle from "components/buttons/ButtonPlayPauseToggle";

// utils
import color from "styles/color";
import styled from "styled-components";
import numberUtils from "utils/numberUtils";

const Wrapper = styled.div`
  display: flex;

  width: 100%;
  height: 100px;

  > ul {
    all: unset;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background: ${color.dark.bg};
    color: ${color.grey.strong};
  }
`;

interface ChallengeOptionsFooterProps {
  wrapper: HTMLDivElement | null;
  disabled?: boolean;
}

const ChallengeOptionsFooter = ({
  wrapper,
  disabled,
}: ChallengeOptionsFooterProps) => {
  const dispatch = useDispatch();
  const { paused } = useSelector(s.challengeFlow());
  const { tipsDisabled } = useSelector(s.playerClassRoom());
  const [isTipEnabled, setIsTipEnabled] = useState(!tipsDisabled);

  function play() {
    dispatch(challengeFlowActions.unpause());
  }

  function pause() {
    dispatch(challengeFlowActions.pause());
  }

  function enableTip() {
    setIsTipEnabled(true);

    setTimeout(() => alert("Tips are enabled", AlertFactoryType.Warning));
    setTimeout(() =>
      dispatch(playerClassRoomActions.async.update({ tipsDisabled: false }))
    );
  }

  function disableTip() {
    setIsTipEnabled(false);

    setTimeout(() => alert("Tips are disabled", AlertFactoryType.Danger));
    setTimeout(() =>
      dispatch(playerClassRoomActions.async.update({ tipsDisabled: true }))
    );
  }

  function alert(content: string, type: AlertFactoryType) {
    if (!wrapper) return;

    alertAnimate({
      factories: [
        AlertFactory({
          type,
          content,
        }),
      ],
      wrapper: wrapper as HTMLDivElement,
      bounds: {
        top: `${numberUtils.randomInterval(100, wrapper.clientHeight - 200)}px`,
        left: `${numberUtils.randomInterval(100, wrapper.clientWidth - 200)}px`,
      },
    });
  }

  return (
    <Wrapper>
      <ul>
        <li>
          <ButtonTipToggle
            enable={enableTip}
            disable={disableTip}
            enabled={isTipEnabled}
          />
        </li>
        <li>
          <ButtonPlayPauseToggle
            play={play}
            pause={pause}
            paused={paused}
            disabled={disabled}
          />
        </li>
      </ul>
    </Wrapper>
  );
};

export default ChallengeOptionsFooter;
