// interfaces
import { PaymentCheckoutSessionProps } from "interfaces/payment";

// services
import RequestCoreService from "services/core/RequestCoreService";

class StripeService {
  private _resource = "stripe";
  private _request: RequestCoreService;

  constructor() {
    this._request = new RequestCoreService();
  }

  createCheckoutSession(): Promise<{ data: PaymentCheckoutSessionProps }> {
    return this._request.post(`${this._resource}/create-checkout-session`);
  }

  createSetupIntentCheckoutSession(): Promise<{
    data: PaymentCheckoutSessionProps;
  }> {
    return this._request.post(
      `${this._resource}/setup-intent/create-checkout-session`
    );
  }
}

export default StripeService;
