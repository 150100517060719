// redux
import { tagActions } from "redux/slices/tagSlice";
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { challengeActions } from "redux/slices/challengeSlice";
import { challengeCodeActions } from "redux/slices/challengeCodeSlice";

// interfaces
import { TagProps, SpecialTagProps } from "interfaces/tag";
import { ChallengeCodeProps } from "interfaces/challengeCode";
import { ChallengeFirebaseUpdateProps } from "interfaces/challengeFirebase";

// entities
import ChallengeFirebaseEntity from "entities/ChallengeFirebaseEntity";
import PlayerClassRoomFirebaseEntity from "entities/PlayerClassRoomFirebaseEntity";

// services
import PlayerChallengeFirebaseService from "services/firebase/player/PlayerChallengeFirebaseService";

// enums
import { ChallengeType } from "enums/challengeEnum";

// utils
import cloneDeep from "lodash/cloneDeep";
import codeUtils from "utils/code/codeUtils";
import stateUtils from "redux/utils/stateUtils";
import validatorUtils from "utils/code/validatorUtils";
import blacklistUtils from "utils/code/blacklistUtils";
import { PlayerClassRoomFirebaseUpdateProps } from "interfaces/playerClassRoomFirebase";
import PlayerClassRoomFirebaseService from "services/firebase/player/PlayerClassRoomFirebaseService";

const tagMiddleware = createListenerMiddleware();

// tag/add
tagMiddleware.startListening({
  actionCreator: tagActions.async.add,
  effect: async ({ payload }, listenerApi) => {
    const { tag, specialTags } = payload;
    const { challenge, challengeCode } = stateUtils.get(listenerApi);
    const code = updateCode(tag, specialTags, challengeCode);
    const currentCode = codeUtils.get(tag, specialTags, code);
    const finished = validatorUtils.finished(code);
    const frame = challenge.frame + 1;

    listenerApi.dispatch(challengeActions.update({ frame, currentCode }));
    listenerApi.dispatch(challengeCodeActions.update(code));

    if (!finished) return;

    if (challenge.type === ChallengeType.Introduction)
      listenerApi.dispatch(challengeActions.async.done());
    if (challenge.type === ChallengeType.Exercise)
      listenerApi.dispatch(challengeActions.async.done());
  },
});

// tag/missed
tagMiddleware.startListening({
  actionCreator: tagActions.async.missed,
  effect: async (_action, listenerApi) => {
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id: challengeId, classRoomId } = challenge;
    const userId = auth.user.id;
    const challengeFirebaseEntity = new ChallengeFirebaseEntity();
    const playerClassRoomFirebaseEntity = new PlayerClassRoomFirebaseEntity();

    listenerApi.dispatch(challengeActions.incMissed());

    updatePlayerClassRoomFirebase(
      userId,
      classRoomId,
      playerClassRoomFirebaseEntity.getMissedValues()
    );
    updateChallengeFirebase(
      userId,
      challengeId,
      classRoomId,
      challengeFirebaseEntity.getMissedValues()
    );
  },
});

// tag/help
tagMiddleware.startListening({
  actionCreator: tagActions.async.help,
  effect: async (_action, listenerApi) => {
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id: challengeId, classRoomId } = challenge;
    const userId = auth.user.id;
    const challengeFirebaseEntity = new ChallengeFirebaseEntity();
    const playerClassRoomFirebaseEntity = new PlayerClassRoomFirebaseEntity();

    listenerApi.dispatch(challengeActions.incHelp());

    updatePlayerClassRoomFirebase(
      userId,
      classRoomId,
      playerClassRoomFirebaseEntity.getHelpValues()
    );
    updateChallengeFirebase(
      userId,
      challengeId,
      classRoomId,
      challengeFirebaseEntity.getHelpedValues()
    );
  },
});

// private

function updateCode(
  tag: TagProps,
  specialTags: SpecialTagProps[],
  code: ChallengeCodeProps
): ChallengeCodeProps {
  const { additional, steps, verified, finished } = code;
  const v = [...cloneDeep(verified), cloneDeep(tag)];
  const blacklist = blacklistUtils.get(v, specialTags, steps);
  const filteredSteps = blacklistUtils.filter(steps, blacklist);
  const filteredFinished = blacklistUtils.filter(finished, blacklist);

  return {
    additional,
    verified: v,
    steps: filteredSteps,
    finished: filteredFinished,
  };
}

// private

function updatePlayerClassRoomFirebase(
  userId: number | string,
  classRoomId: number,
  values: PlayerClassRoomFirebaseUpdateProps
) {
  if (!userId) return;
  if (!classRoomId) return;

  const resource = new PlayerClassRoomFirebaseService();
  resource.update(userId, classRoomId, values);
}

function updateChallengeFirebase(
  userId: number | string,
  challengeId: number,
  classRoomId: number,
  data: ChallengeFirebaseUpdateProps
) {
  if (!userId) return;
  if (!challengeId) return;
  if (!classRoomId) return;

  const challengeFirebaseService = new PlayerChallengeFirebaseService();
  challengeFirebaseService.update(challengeId, userId, classRoomId, data);
}

export default tagMiddleware;
