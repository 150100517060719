// react
import { IonLabel, IonInput, IonItem, IonTextarea } from "@ionic/react";

// components
import ErrorFeedback from "components/feedbacks/ErrorFeedback";

// utils
import styled from "styled-components";
import color from "styles/color";
import { useField } from "formik";

const Wrapper = styled.div`
  width: 100%;

  .formik_label {
    --color: ${color.grey.strong} !important;
  }

  .formik_input {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: 1px solid inherit;
      -webkit-text-fill-color: ${color.grey.strong};
    }

    --color: ${color.grey.strong} !important;
  }
`;

interface FormItemProps {
  bgColor?: string;
  removeBorder?: boolean;
  highlightColor?: string;
}

export const FormItem = styled(IonItem)<FormItemProps>`
  --padding-start: 0px;
  --padding-end: 0px;
  --border-color: ${color.grey.dark};
  --background: ${(props) => props && props.bgColor};

  ${(props) =>
    props && props.removeBorder
      ? `--border-color: transparent`
      : `--border-color: ${color.grey.dark}`};

  ${(props) =>
    props &&
    `
    --highlight-color-focused: ${
      (props && props.highlightColor) === "success" ||
      (props && !props.highlightColor)
        ? color.purple
        : color.red
    };
  `};
`;

const FormikInput = ({
  inputRef,
  label,
  bgColor = color.dark.bg,
  ...props
}: any) => {
  const [field, meta] = useField(props);

  return (
    <Wrapper>
      <FormItem bgColor={bgColor}>
        <IonLabel position="floating" className="formik_label">
          {label}
        </IonLabel>

        <IonInput
          ref={inputRef}
          onIonChange={field.onChange}
          onIonBlur={field.onBlur}
          value={field.value}
          className="formik_input"
          appAutofill
          {...props}
        />
      </FormItem>

      {meta.touched && meta.error && (
        <ErrorFeedback err={meta.error} alignLeft />
      )}
    </Wrapper>
  );
};

export const FormikTextArea = ({
  label,
  bgColor = color.dark.bg,
  ...props
}: any) => {
  const [field, meta] = useField(props);

  return (
    <Wrapper>
      <FormItem bgColor={bgColor}>
        <IonLabel position="floating" className="formik_label">
          {label}
        </IonLabel>

        <IonTextarea
          className="feedback_textarea"
          onIonChange={field.onChange}
          onIonBlur={field.onBlur}
          value={field.value}
          inputMode="text"
          mode="ios"
          rows={1}
          autoGrow
          appAutofill
          {...props}
        ></IonTextarea>
      </FormItem>

      {meta.touched && meta.error && (
        <ErrorFeedback err={meta.error} alignLeft />
      )}
    </Wrapper>
  );
};

export default FormikInput;
