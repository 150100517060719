// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const DonePixelIcon = ({
  width = "16",
  height = "16",
  color = c.green,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M193.085 357.966h-80.271c-5.99 0-10.847 4.857-10.847 10.847v80.271c0 5.99 4.857 10.847 10.847 10.847h80.271c5.99 0 10.847-4.857 10.847-10.847v-80.271c0-5.989-4.857-10.847-10.847-10.847zM91.119 256H10.847C4.857 256 0 260.857 0 266.847v80.271c0 5.99 4.857 10.847 10.847 10.847h80.271c5.99 0 10.847-4.857 10.847-10.847v-80.271c.001-5.99-4.856-10.847-10.846-10.847zM295.051 256H214.78c-5.99 0-10.847 4.857-10.847 10.847v80.271c0 5.99 4.857 10.847 10.847 10.847h80.271c5.99 0 10.847-4.857 10.847-10.847v-80.271c0-5.99-4.857-10.847-10.847-10.847zM399.186 154.034h-80.271c-5.99 0-10.847 4.857-10.847 10.847v80.271c0 5.99 4.857 10.847 10.847 10.847h80.271c5.99 0 10.847-4.857 10.847-10.847v-80.271c.001-5.99-4.857-10.847-10.847-10.847zM501.153 52.068h-80.271c-5.99 0-10.847 4.857-10.847 10.847v80.271c0 5.99 4.857 10.847 10.847 10.847h80.271c5.99 0 10.847-4.857 10.847-10.847V62.915c0-5.99-4.857-10.847-10.847-10.847z"
          fill={color}
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default DonePixelIcon;
