// react
import { useEffect, useState } from "react";

// interfaces
import { ChallengeProps } from "interfaces/challenge";

// components
import ProgressStatus from "components/ProgressStatus";

// utils
import c from "styles/color";

interface ChallengeProgressHandlerProps {
  challenge: ChallengeProps;
  getBiggestStepLength(): number;
  color?: string;
}

const ChallengeBattleProgressHandler = ({
  challenge,
  color = c.yellow,
  getBiggestStepLength,
}: ChallengeProgressHandlerProps) => {
  const { frame, flowDone, flowSuccess } = challenge;
  const [challengeProgress, setChallengeProgress] = useState(
    flowSuccess ? 0 : 1
  );

  useEffect(hideBoss, [challenge.flowFinished]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleProgressByFrame, [frame]);

  function hideBoss() {
    if (!challenge.flowFinished) return;
  }

  function handleProgressByFrame() {
    if (!frame) return;
    if (flowDone) return;

    const len = getBiggestStepLength();
    if (!len) return;

    setChallengeProgress((len - frame) / len);
  }

  return <ProgressStatus value={challengeProgress} color={color} />;
};

export default ChallengeBattleProgressHandler;
