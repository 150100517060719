// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// svgs
import CheckboxInputSvg from "svg/CheckboxInputSvg";
import RadioInputInputSvg from "svg/RadioInputInputSvg";

// interfaces
import { ChallengeQuizProps } from "interfaces/challengeQuiz";

// handlers
import {
  LevelEasyIcon,
  LevelHardIcon,
  LevelMediumIcon,
} from "handlers/LevelIconHandler";

// enums
import { ChallengeQuizLevelType } from "enums/challengeQuizEnum";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  width: 100%;
  padding: 0 15px;

  ul {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    p {
      all: unset;
      font-size: 20px;
      color: ${color.grey.strong};
    }

    span {
      display: inline-block;
      margin-left: 8px;
    }

    &.levels_wrapper {
      all: unset;
      display: flex;

      li {
        display: flex;
        align-items: center;
        margin: 0;
        padding-right: 5px;
        margin-right: 8px;
        color: ${color.grey.strong};
      }
    }

    &.info_wrapper {
      all: unset;
    }
  }
`;

interface ChallengeQuizSubStatusBarProps {
  quiz: ChallengeQuizProps;
}

const ChallengeQuizSubStatusBar = ({
  quiz,
}: ChallengeQuizSubStatusBarProps) => {
  const player = useSelector(s.playerClassRoom());

  function getOptionsInfo(): JSX.Element {
    if (quiz.multi)
      return (
        <p>
          <CheckboxInputSvg />
        </p>
      );

    return (
      <p>
        <RadioInputInputSvg />
      </p>
    );
  }

  return (
    <Wrapper>
      <ul className="levels_wrapper">
        <li>
          <LevelEasyIcon active={quiz.level === ChallengeQuizLevelType.Easy} />
          <span>{player.quiz.easy}</span>
        </li>

        <li>
          <LevelMediumIcon
            active={quiz.level === ChallengeQuizLevelType.Medium}
          />
          <span>{player.quiz.medium}</span>
        </li>

        <li>
          <LevelHardIcon active={quiz.level === ChallengeQuizLevelType.Hard} />
          <span>{player.quiz.hard}</span>
        </li>
      </ul>

      <ul className="info_wrapper">
        <li>{getOptionsInfo()}</li>
      </ul>
    </Wrapper>
  );
};

export default ChallengeQuizSubStatusBar;
