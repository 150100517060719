// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import {
  PlayerClassRoomFirebaseProps,
  PlayerClassRoomFirebaseUpdateProps,
} from "interfaces/playerClassRoomFirebase";

class PlayerClassRoomFirebaseService {
  private _resource = "players";
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  async get(
    userId: number | string,
    classRoomId: number
  ): Promise<PlayerClassRoomFirebaseProps> {
    if (!userId) return Promise.reject();
    if (!classRoomId) return Promise.reject();

    const path = `${this._resource}/${userId}/classRooms/${classRoomId}`;
    const response = await this._firebase.get(path);

    return response.data() as PlayerClassRoomFirebaseProps;
  }

  async update(
    userId: number | string,
    classRoomId: number,
    values: PlayerClassRoomFirebaseUpdateProps
  ): Promise<void> {
    if (!userId) return Promise.reject();
    if (!classRoomId) return Promise.reject();

    return this._firebase.set(
      `${this._resource}/${userId}/classRooms/${classRoomId}`,
      values
    );
  }
}

export default PlayerClassRoomFirebaseService;
