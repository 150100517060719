// core service
import RequestCoreService from "services/core/RequestCoreService";

// interfaces
import { SubscriberResourceProps } from "interfaces/subscriber";

interface SubscriberCompositeIdProps {
  classRoomId: number;
  userId: number | string;
}

class SubscriberService {
  private _resource = "subscriber";
  private _request: RequestCoreService;

  constructor() {
    this._request = new RequestCoreService();
  }

  get(classRoomId: number): Promise<{ data: SubscriberResourceProps }> {
    return this._request.get(`${this._resource}/${classRoomId}`);
  }

  updateCheckpoint(
    compositeId: SubscriberCompositeIdProps,
    checkpoint: number
  ): Promise<unknown | never> {
    if (!compositeId.userId) return Promise.reject();
    if (!compositeId.classRoomId) return Promise.reject();

    return this._request.put(`${this._resource}/checkpoint`, {
      ...compositeId,
      checkpoint,
    });
  }

  updateJarvisName(
    compositeId: SubscriberCompositeIdProps,
    jarvisName: string
  ): Promise<unknown | never> {
    if (!compositeId.userId) return Promise.reject();
    if (!compositeId.classRoomId) return Promise.reject();

    return this._request.put(`${this._resource}/jarvis-name`, {
      ...compositeId,
      jarvisName,
    });
  }
}

export default SubscriberService;
