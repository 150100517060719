export enum WizardTypeEnum {
  None = 0,
  Idle,
  Walk,
  Attack,
  SkillShield,
}

export enum WizardActionEnum {
  Idle = 1,
  Walk,
  Attack,
  SkillShield,
}
