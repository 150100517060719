// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { subscriberActions } from "redux/slices/subscriberSlice";

// services
import SubscriberService from "services/SubscriberService";

// entities
import JarvisEntity from "entities/characters/JarvisEntity";

// utils
import stateUtils from "redux/utils/stateUtils";

const subscriberMiddleware = createListenerMiddleware();

subscriberMiddleware.startListening({
  actionCreator: subscriberActions.async.updateCheckpoint,
  effect: async ({ payload }, listenerApi) => {
    const { auth, classRoom } = stateUtils.get(listenerApi);

    if (!auth) return;
    if (auth.anonymous) return;

    if (!auth.user) return;
    if (!auth.signed) return;
    if (!auth.user.id) return;
    if (!payload.checkpoint) return;

    updateSubscriberCheckpoint(auth.user.id, classRoom.id, payload.checkpoint);
  },
});

subscriberMiddleware.startListening({
  actionCreator: subscriberActions.async.updateJarvisName,
  effect: async (_, listenerApi) => {
    try {
      const { auth, classRoom, subscriber, npc } = stateUtils.get(listenerApi);
      const npcEntity = new JarvisEntity({ name: npc.jarvis.name });
      const subscriberEntity = new JarvisEntity({
        name: subscriber.jarvisName,
      });

      if (!npcEntity.has()) return;
      if (subscriberEntity.has()) return;

      if (!auth) return;
      if (auth.anonymous) return;

      if (!auth.user) return;
      if (!auth.signed) return;
      if (!auth.user.id) return;
      if (!auth.subscribed) return;

      await updateSubscriberJarvisName(
        auth.user.id,
        classRoom.id,
        npcEntity.name
      );

      listenerApi.dispatch(
        subscriberActions.updateJarvisName({ jarvisName: npcEntity.name })
      );
    } catch (err) {}
  },
});

// private

function updateSubscriberCheckpoint(
  userId: number | string,
  classRoomId: number,
  checkpoint: number
) {
  if (!userId) return;
  if (!checkpoint) return;
  if (!classRoomId) return;

  const resource = new SubscriberService();
  resource.updateCheckpoint({ userId, classRoomId }, checkpoint);
}

function updateSubscriberJarvisName(
  userId: number | string,
  classRoomId: number,
  jarvisName: string
): Promise<unknown | never> {
  if (!userId) return Promise.reject();
  if (!jarvisName) return Promise.reject();
  if (!classRoomId) return Promise.reject();

  const resource = new SubscriberService();
  return resource.updateJarvisName({ userId, classRoomId }, jarvisName);
}

export default subscriberMiddleware;
