// entities
import ChallengeQuizScoreEntity from "entities/ChallengeQuizScoreEntity";
import ChallengeQuizLevelPointsEntity from "entities/ChallengeQuizLevelPointsEntity";

// interfaces
import { ChallengeQuizProps } from "interfaces/challengeQuiz";
import { PlayerClassRoomProps } from "interfaces/playerClassRoom";

interface FlowInitValuesProps {
  flowInit: boolean;
}

interface FlowStartedValuesProps {
  flowStarted: boolean;
}

interface FlowDoneValuesProps {
  flowDone: true;
}

interface FlowFinishedValuesProps {
  flowFinished: boolean;
}

interface FlowSuccessValuesProps extends FlowDoneValuesProps {
  // flow
  flowSuccess: true;

  // gained
  scoreGained: number;
  levelPointGained: number;
}

interface FlowFailedValuesProps extends FlowDoneValuesProps {
  // flow
  flowFailed: true;

  // gained
  scoreGained: number;
  levelPointGained: number;
}

class ChallengeQuizStateEntity {
  getFlowInitValues(): FlowInitValuesProps {
    return {
      flowInit: true,
    };
  }

  getFlowStartedValues(): FlowStartedValuesProps {
    return {
      flowStarted: true,
    };
  }

  getFlowFinishedValues(): FlowFinishedValuesProps {
    return {
      flowFinished: true,
    };
  }

  getFlowUnFinishedValues(): FlowFinishedValuesProps {
    return {
      flowFinished: false,
    };
  }

  getFailedValues(
    player: PlayerClassRoomProps,
    quiz: ChallengeQuizProps
  ): FlowFailedValuesProps {
    const quizLevel = new ChallengeQuizLevelPointsEntity({
      level: quiz.level,
      player,
    });
    const quizScoreEntity = new ChallengeQuizScoreEntity({ level: quiz.level });

    return {
      flowDone: true,
      flowFailed: true,
      levelPointGained: quizLevel.lost(),
      scoreGained: quizScoreEntity.lost(),
    };
  }

  getSuccessValues(
    player: PlayerClassRoomProps,
    quiz: ChallengeQuizProps
  ): FlowSuccessValuesProps {
    const quizLevel = new ChallengeQuizLevelPointsEntity({
      level: quiz.level,
      player,
    });
    const quizScoreEntity = new ChallengeQuizScoreEntity({ level: quiz.level });

    return {
      flowDone: true,
      flowSuccess: true,
      levelPointGained: quizLevel.gained(),
      scoreGained: quizScoreEntity.gained(),
    };
  }
}

export default ChallengeQuizStateEntity;
