// react
import { match } from "react-router-dom";
import { IonContent, IonPage } from "@ionic/react";
import { useState, useCallback, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";
import { nextChallengeActions } from "redux/slices/nextChallengeSlice";
import { challengeQuizActions } from "redux/slices/challengeQuizSlice";
import { challengeSectionActions } from "redux/slices/challengeSectionSlice";

// widgets
// import PixelWidget from "components/widgets/PixelWidget";
// import PlayerScoreWidget from "components/widgets/PlayerScoreWidget";
// import LevelStatusWidget from "components/widgets/LevelStatusWidget";

// interfaces
import { ChallengeProps } from "interfaces/challenge";
import { ChallengeQuizProps } from "interfaces/challengeQuiz";
import { ChallengeSectionProps } from "interfaces/challengeSection";

// firebase
import FirebaseSync from "firebaseSync/FirebaseSync";

// components
import Footer from "components/Footer";
// import ProgressBar from "components/ProgressBar";
import ClassRoomAccordion from "components/classRoom/accordion/ClassRoomAccordion";

// handlers
import ChallengeHandler from "handlers/ChallengeHandler";
import FetchClassRoomDataHandler from "handlers/FetchClassRoomDataHandler";

// components
import Sign from "components/sign/Sign";
import Loading from "components/Loading";
import Header from "components/header/Header";
import HeaderMenu from "components/header/HeaderMenu";
import ClassRoomStartButton from "pages/classRoom/ClassRoomStartButton";

// utils
import color from "styles/color";
import styled from "styled-components";
import audioUtils from "utils/audioUtils";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  --ion-background-color: ${color.dark.bg};

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;

    .body {
      flex: 1;
      display: flex;
      flex-direction: column;

      > .header {
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
          all: unset;
          text-align: center;
          font-size: 2em;
          padding: 50px 20px;
          font-weight: bold;
          color: ${color.white};
        }
      }

      .progress_bar {
        height: 6px;
      }

      .widgets {
        padding: 20px 0;
      }
    }
  }
`;

interface ClassRoomContentProps {
  match: match<{ id: string; lang: string }>;
}

const ClassRoomContentPage = ({ match }: ClassRoomContentProps) => {
  const dispatch = useDispatch();
  const auth = useSelector(s.auth());
  const classRoom = useSelector(s.classRoom());
  const sections = useSelector(s.challengeSections());
  const sectionsBrief = useSelector(s.sectionsBrief());
  const [loading, setLoading] = useState(getLoading());
  const challengesHash = useSelector(s.challengesHash());
  const [notFound, setNotFound] = useState(false);
  const [isOpenSignModal, setIsOpenSignModal] = useState(false);
  const [isChallengeModalOpen, setIsChallengeModalOpen] = useState(false);
  const [isChallengeQuizModalOpen, setIsChallengeQuizModalOpen] =
    useState(false);
  const wrapperRef = useRef<HTMLDivElement | undefined>();
  const handlerWrapperRef = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    setTimeout(() => {
      wrapperRef.current = node;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getLoading() {
    return !!auth.signed;
  }

  function openChallengeQuizModal(
    quiz: ChallengeQuizProps,
    section: ChallengeSectionProps
  ) {
    if (isChallengeQuizModalOpen) return;

    setTimeout(() => {
      dispatch(challengeFlowActions.pause());
      dispatch(challengeQuizActions.set(quiz));
      dispatch(challengeSectionActions.set(section));
      dispatch(nextChallengeActions.async.set(quiz, challengesHash));

      setTimeout(() => setIsChallengeQuizModalOpen(true));
    });
  }

  function openChallengeModal(
    challenge: ChallengeProps,
    section: ChallengeSectionProps
  ) {
    if (isChallengeModalOpen) return;

    setTimeout(() => {
      audioUtils.bootstrap();
      // dispatch(challengeActions.async.openModal(challenge, section));
      setTimeout(() => setIsChallengeModalOpen(true));
    });
  }

  function openSignModal() {
    setIsOpenSignModal(true);
  }

  function closeSignModal() {
    if (!isOpenSignModal) return;
    setIsOpenSignModal(false);
  }

  function errorCallback(_error: unknown) {
    setLoading(false);
    setNotFound(true);
  }

  function successCallback() {
    setLoading(false);
  }

  function finishedSignIn() {
    setLoading(true);
  }

  return (
    <Wrapper>
      <HeaderMenu signed={auth.signed} />

      <IonPage id="mainContent">
        <Header />
        <Loading loading={loading} />

        <IonContent>
          <div className="content open_sans_family" ref={handlerWrapperRef}>
            <div className="body">
              <div className="header">
                <h1>Lógica de Programação e Algoritmos</h1>
              </div>

              {/* <div className="progress_bar">
                <ProgressBar
                  color="primary"
                  value={sectionsBrief.progress || 0}
                />
              </div> */}

              {/* <div className="widgets">
                <PlayerScoreWidget />
                <LevelStatusWidget />
                <PixelWidget />
                <SpaceshipStatusWidget />
              </div> */}
              {/* 
              <ChallengeHandler
                sections={sections}
                challengesMerged={challengesHash}
                openChallengeModal={openChallengeModal}
                isChallengeModalOpen={isChallengeModalOpen}
                toggleChallengeModal={setIsChallengeModalOpen}
                openChallengeQuizModal={openChallengeQuizModal}
                isChallengeQuizModalOpen={isChallengeQuizModalOpen}
                toggleChallengeQuizModal={setIsChallengeQuizModalOpen}
              /> */}

              <ClassRoomAccordion
                sections={sections}
                challenges={challengesHash}
                openChallengeModal={openChallengeModal}
                openChallengeQuizModal={openChallengeQuizModal}
              />

              {/* <Sign
                close={closeSignModal}
                isOpen={isOpenSignModal}
                finished={finishedSignIn}
              /> */}

              {!auth.signed && (
                <ClassRoomStartButton
                  onClick={openSignModal}
                  loading={loading}
                />
              )}

              {auth.signed && (
                <FetchClassRoomDataHandler
                  auth={auth}
                  errorCallback={errorCallback}
                  successCallback={successCallback}
                  classRoomId={Number(match.params.id)}
                  languageId={Number(match.params.lang)}
                />
              )}

              {!!(auth.firebaseSigned && auth.user && classRoom.id) && (
                <FirebaseSync user={auth.user} classRoomId={classRoom.id} />
              )}
            </div>

            <Footer />
          </div>
        </IonContent>
      </IonPage>
    </Wrapper>
  );
};

export default ClassRoomContentPage;
