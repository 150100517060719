// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// svg
import PremiumSvg from "svg/PremiumSvg";

// utils
import color from "styles/color";
import styled from "styled-components";

const StatusBarWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 65px;
  width: 100%;
  z-index: -1;

  .label {
    all: unset;
    font-size: 0.9em;
    margin-left: 15px;
    opacity: 0.5;
    text-transform: uppercase;
  }

  .label_white_border {
    padding: 8px;
    border-radius: 10px;
    border: 1px dashed ${color.grey.stronger};
  }

  .trial_label {
    color: ${color.blue};
  }

  .demo_label {
    color: ${color.red};
  }
`;

const ChallengeStatusBar = () => {
  const auth = useSelector(s.auth());

  return (
    <StatusBarWrapper>
      {auth.anonymous && (
        <p className="label label_white_border demo_label">Demo</p>
      )}

      {!auth.anonymous && auth.signed && !auth.subscribed && (
        <p className="label label_white_border trial_label">Trial</p>
      )}

      {!auth.anonymous && auth.signed && auth.subscribed && (
        <p className="label">
          <PremiumSvg />
        </p>
      )}
    </StatusBarWrapper>
  );
};

export default ChallengeStatusBar;
