// react
import { useCallback, useEffect, useRef } from "react";

// ionic-react
import { IonicSlides } from "@ionic/react";

// redux
import { selector as s } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { nextChallengeActions } from "redux/slices/nextChallengeSlice";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";

// factories
import { BlinkFactory } from "factories/spaceInvaders/BlinkFactory";

// components
import Modal from "components/Modal";
import Challenge from "components/challenge/Challenge";
import Customize from "components/customize/Customize";
import ChallengeQuiz from "components/challengeQuiz/ChallengeQuiz";

// factories
import { startAlertsDrag } from "factories/AlertFactory";

// entities
import JarvisEntity from "entities/characters/JarvisEntity";

// interfaces
import {
  ChallengeProps,
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";
import { ChallengeType } from "enums/challengeEnum";
import { ChallengeQuizProps } from "interfaces/challengeQuiz";
import { ChallengeSectionProps } from "interfaces/challengeSection";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";

// utils
import codeUtils from "utils/code/codeUtils";
import writerUtils from "utils/code/writerUtils";

interface ChallengeHandlerProps {
  isChallengeModalOpen: boolean;
  sections: ChallengeSectionProps[];
  isChallengeQuizModalOpen: boolean;
  challengesMerged: ChallengesCommonHashProps;
  toggleChallengeModal: (value: boolean) => void;
  toggleChallengeQuizModal: (value: boolean) => void;
  openChallengeModal: (
    challenge: ChallengeProps,
    section: ChallengeSectionProps
  ) => void;
  openChallengeQuizModal(
    challenge: ChallengeQuizProps,
    section: ChallengeSectionProps
  ): void;
}

const ChallengeHandler = ({
  sections,
  challengesMerged,
  openChallengeModal,
  isChallengeModalOpen,
  toggleChallengeModal,
  openChallengeQuizModal,
  toggleChallengeQuizModal,
  isChallengeQuizModalOpen,
}: ChallengeHandlerProps) => {
  const dispatch = useDispatch();
  const npc = useSelector(s.npc());
  const auth = useSelector(s.auth());
  const interactDragRef = useRef<any>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const blinkElRef = useRef<HTMLDivElement>(BlinkFactory());
  const reopenChallengeRef = useRef<ChallengeCommonProps>();
  const wrapperNotifiersRef = useRef<HTMLDivElement | null>(null);
  const currentChallengeRef = useRef<ChallengeCommonProps | null>(null);

  const handlerWrapperRef = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    setTimeout(() => {
      node.appendChild(blinkElRef.current);
      wrapperRef.current = node;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlerNotifiersRef = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    setTimeout(() => {
      wrapperNotifiersRef.current = node;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(bootstrapAlertsDrag, []);

  function bootstrapAlertsDrag() {
    interactDragRef.current = startAlertsDrag();
  }

  // REOPEN CHALLENGE MODAL

  function handleReopenChallengeModal() {
    if (!reopenChallengeRef.current) return;

    reOpenChallengeModal(reopenChallengeRef.current);
    reopenChallengeRef.current = undefined;
  }

  function reOpenChallengeModal(challenge: ChallengeCommonProps) {
    const sectionFound = sections.find(
      (section) => section.id === challenge.challengeSectionId
    );

    if (!sectionFound) return;

    const challengeFound = challengesMerged[sectionFound.id].find(
      (c) => c.uuid === challenge.uuid
    );

    if (!challengeFound) return;
    setTimeout(() => callOpenChallengeModal(challengeFound, sectionFound), 250);
  }

  function reOpenChallengeQuiz(challenge: ChallengeCommonProps) {
    reopenChallengeRef.current = challenge;
    closeChallengeQuizModal();
  }

  function callOpenChallengeModal(
    challenge: ChallengeCommonProps,
    section: ChallengeSectionProps
  ) {
    const { type } = challenge;
    const { Introduction, Exercise, Quiz } = ChallengeType;
    let cb = () => {};

    if (type === Introduction || type === Exercise)
      cb = openChallengeModal.bind(null, challenge as ChallengeProps, section);
    if (type === Quiz)
      cb = openChallengeQuizModal.bind(
        null,
        challenge as ChallengeQuizProps,
        section
      );

    setTimeout(cb);
  }

  function dismissChallengeModal() {
    unfreeze();
    codeUtils.clear();
    writerUtils.clear();
    closeChallengeModal();

    dismissModal();
  }

  function dismissChallengeQuizModal() {
    closeChallengeQuizModal();
    dismissModal();
  }

  function dismissModal() {
    dispatch(nextChallengeActions.clear());
    setTimeout(handleReopenChallengeModal);

    currentChallengeRef.current = null;
  }

  function closeChallengeModal() {
    if (!isChallengeModalOpen) return;
    setTimeout(() => toggleChallengeModal(false));
  }

  function closeChallengeQuizModal() {
    if (!isChallengeQuizModalOpen) return;
    setTimeout(() => toggleChallengeQuizModal(false));
  }

  function unfreeze() {
    setTimeout(() => dispatch(challengeFlowActions.unfreeze()));
  }

  function showCustomization() {
    return (
      auth.signed &&
      !auth.anonymous &&
      !new JarvisEntity({ name: npc.jarvis.name }).has()
    );
  }

  return (
    <>
      <Modal isOpen={isChallengeModalOpen} onDidDismiss={dismissChallengeModal}>
        <Swiper
          initialSlide={0}
          slidesPerView={1}
          modules={[IonicSlides]}
          noSwipingClass="no-swipe"
        >
          <SwiperSlide>
            <div className="slide">
              <Challenge
                wrapperRef={wrapperRef}
                blinkElRef={blinkElRef}
                close={closeChallengeModal}
                handlerWrapperRef={handlerWrapperRef}
                handlerNotifiersRef={handlerNotifiersRef}
                wrapperNotifiersRef={wrapperNotifiersRef}
                currentChallengeRef={currentChallengeRef}
              />

              <Customize show={showCustomization()} />
            </div>
          </SwiperSlide>
        </Swiper>
      </Modal>

      <Modal
        isOpen={isChallengeQuizModalOpen}
        onDidDismiss={dismissChallengeQuizModal}
      >
        <Swiper
          initialSlide={0}
          slidesPerView={1}
          modules={[IonicSlides]}
          noSwipingClass="no-swipe"
        >
          <SwiperSlide>
            <div className="slide">
              <ChallengeQuiz
                wrapperRef={wrapperRef}
                blinkElRef={blinkElRef}
                handlerWrapperRef={handlerWrapperRef}
                reOpenChallenge={reOpenChallengeQuiz}
                currentChallengeRef={currentChallengeRef}
                wrapperNotifiersRef={wrapperNotifiersRef}
                handlerNotifiersRef={handlerNotifiersRef}
              />

              <Customize show={showCustomization()} />
            </div>
          </SwiperSlide>
        </Swiper>
      </Modal>
    </>
  );
};

export default ChallengeHandler;
