// utils
import styled from "styled-components";

const Wrapper = styled.div`
  background-image: url(/assets/imgs/jarvis.png);
`;

export const AvatarJarvisBg = () => {
  return <Wrapper className="nerdfy_avatar"></Wrapper>;
};

const AvatarJarvis = () => {
  return <Wrapper className="nerdfy_avatar"></Wrapper>;
};

export default AvatarJarvis;
