// ionic-react
import { IonRippleEffect } from "@ionic/react";

// svgs
import BarricadeSvg from "svg/BarricadeSvg";

// enums
import { NextChallengeDisabledType } from "enums/nextChallengeEnum";

// interfaces
import { NextChallengeInfoProps } from "handlers/challengeDoneButton/ChallengeDoneButtonHandler";

// utils
import color from "styles/color";
import styled from "styled-components";

export const Next = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 150px;
  background: ${color.dark.bg};
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 20px;
  margin: 0;
  cursor: pointer;
  z-index: 888;

  > .btn_content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .next_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 25px;
        margin: 0;
      }
    }
  }

  > .done_footer {
    .next_challenge_info {
      text-align: right;
      line-height: 1.5;

      p {
        color: ${color.grey.middle};
        margin: 10px 0;
        width: 100%;
        font-size: 15px;
      }
    }
  }
`;

interface ChallengeBarrierButtonProps {
  info: NextChallengeInfoProps;
  Label: React.FC<{ label: string }>;
}

const ChallengeBarrierButton = ({
  Label,
  info,
}: ChallengeBarrierButtonProps) => {
  function getMessage(): string {
    if (info.nextChallengeDisabledType === NextChallengeDisabledType.Quiz)
      return "Antes de prosseguir, finalize TODOS os desafios anteriores...";

    if (
      info.nextChallengeDisabledType === NextChallengeDisabledType.LastChallenge
    )
      return "Ops, parece que você alcançou uma barreira...";

    return "Por algum motivo não é possível seguir em frente...";
  }

  return (
    <Next
      onClick={() => {}}
      className={`ion-activatable ripple-parent rectangle animate__animated no-swipe animate__bounceInLeft`}
    >
      <IonRippleEffect style={{ color: color.yellow }} />

      <div className="btn_content">
        <BarricadeSvg width="40" height="40" color={color.yellow} />

        <div className="next_wrapper">
          <Label label={"Caminho Bloqueado"} />
        </div>
      </div>

      <div className="done_footer">
        <div className="next_challenge_info">
          <p>{getMessage()}</p>
        </div>
      </div>
    </Next>
  );
};

export default ChallengeBarrierButton;
