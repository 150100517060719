// react
import { useEffect, useRef, useState } from "react";
import { IonicSlides } from "@ionic/react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// interfaces
import { UserProps } from "interfaces/user";

// swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// components
import JarvisSpeech from "./JarvisSpeech";
import End from "components/customize/End";
import ProgressBar from "components/ProgressBar";
import JarvisNaming from "components/customize/JarvisNaming";
import PlayerNaming from "components/customize/PlayerNaming";
import Introduction, { Blank } from "components/customize/Introduction";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9998;

  .slide {
    flex: 1;
    display: flex;

    .user_input {
      padding: 10px;
      color: ${color.grey.light};
      background: ${color.grey.dark};
    }
  }
`;

const SLIDE_INTRO = 0;
const SLIDE_PLAYER_NAME = 1;
const SLIDE_JARVIS_NAME = 2;
const SLIDE_TRIAL_INTRO = 3;
const SLIDE_WORLD_INTRO = 4;
const SLIDE_END = 5;
const SLIDE_BACK_END = 6;

const JARVIS_SPEECH_DELAY = 1600;

interface CustomizeProps {
  show: boolean;
  finished?(): void;
  trackSignUp?: (user: UserProps) => void;
}

const Customize = ({ finished, trackSignUp, show }: CustomizeProps) => {
  const auth = useSelector(s.auth());
  const alreadyShowed = useRef(false);
  const swiperRef = useRef<SwiperProps | undefined>();
  const [username, setUsername] = useState("");
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(SLIDE_INTRO);
  const [jarvisName, setJarvisName] = useState("");
  const [disabledBack, setDisabledBack] = useState(true);
  const [disabled, setDisabled] = useState({
    [SLIDE_END]: true,
    [SLIDE_INTRO]: true,
    [SLIDE_TRIAL_INTRO]: true,
    [SLIDE_WORLD_INTRO]: true,
    [SLIDE_PLAYER_NAME]: true,
    [SLIDE_JARVIS_NAME]: true,
  });
  const [animation, setAnimation] = useState("");

  useEffect(handleShow, [show]);

  function handleShow() {
    if (show) {
      alreadyShowed.current = true;
      setAnimation("animate__bounceInLeft");
    } else if (alreadyShowed.current) setAnimation("animate__bounceOutLeft");
  }

  function onSubmitUserName(name: string) {
    setUsername(name);
  }

  function onSubmitJarvisName(name: string) {
    setJarvisName(name);
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;
  }

  function slideTo(index: number) {
    if (!swiperRef.current) return;
    swiperRef.current.slideTo(index, 300);
  }

  function goToIntroduction() {
    setProgress(0);
    setTimeout(() => slideTo(SLIDE_INTRO));
  }

  function goToPlayerNameSlide() {
    setProgress(0.2);
    updateStep(SLIDE_PLAYER_NAME);

    setTimeout(() => slideTo(SLIDE_PLAYER_NAME));
  }

  function goToJarvisNameSlide() {
    setProgress(0.4);
    updateStep(SLIDE_JARVIS_NAME);

    setTimeout(() => slideTo(SLIDE_JARVIS_NAME));
  }

  function goToSlideTrialIntroSlide() {
    setProgress(0.6);
    updateStep(SLIDE_TRIAL_INTRO);

    setTimeout(() => slideTo(SLIDE_TRIAL_INTRO));
  }

  function goToWorldIntroSlide() {
    setProgress(0.8);
    updateStep(SLIDE_WORLD_INTRO);

    setTimeout(() => slideTo(SLIDE_WORLD_INTRO));
  }

  function goToEndSlide() {
    setProgress(1);
    updateStep(SLIDE_END);

    setTimeout(() => slideTo(SLIDE_END));
  }

  function goBackToPreviousEndSlide() {
    setStep(SLIDE_BACK_END);
    goToWorldIntroSlide();
  }

  function onFinishedLastSpeech() {
    onFinishedSpeech();
    setDisabledBack(false);
  }

  function onFinishedSpeech() {
    setDisabled({ ...disabled, [step]: false });
  }

  function updateStep(s: number) {
    if (s <= step) return;
    setStep(s);
  }

  return (
    <>
      {animation && (
        <Wrapper className={`animate__animated ${animation}`}>
          <ProgressBar value={progress} color={color.green} />

          {step === SLIDE_INTRO && (
            <JarvisSpeech
              delay={1800}
              onFinished={onFinishedSpeech}
              content={[
                "Welcome! I'll be here to guide you every step of the way on your coding journey.",
                "Don't worry, no boring lectures here!",
                "We'll progress faster—our method is far more effective than watching videos or reading texts.",
              ]}
            />
          )}

          {step === SLIDE_PLAYER_NAME && (
            <JarvisSpeech
              delay={JARVIS_SPEECH_DELAY}
              onFinished={onFinishedSpeech}
              content={[
                "Before we begin, may I know your name? What shall I call you?",
              ]}
            />
          )}

          {step === SLIDE_JARVIS_NAME && (
            <JarvisSpeech
              delay={JARVIS_SPEECH_DELAY}
              onFinished={onFinishedSpeech}
              content={[
                `Very well, ${username}, now it's my turn. Allow me to introduce myself, my name is...`,
              ]}
            />
          )}

          {step === SLIDE_TRIAL_INTRO && (
            <JarvisSpeech
              delay={JARVIS_SPEECH_DELAY}
              onFinished={onFinishedSpeech}
              content={[
                "Welcome to your trial!",
                "I'm excited to offer you 5 gameplay days to explore and experience a glimpse of what we have to offer.",
                "Take your time, enjoy the challenges, and let's see how far you can go!",
              ]}
            />
          )}

          {step === SLIDE_WORLD_INTRO && (
            <JarvisSpeech
              delay={JARVIS_SPEECH_DELAY}
              onFinished={onFinishedSpeech}
              content={[
                "However, I'd love to share my story, but alas, time is short...",
                "To sum up, Princess Ada has been kidnapped by a mysterious force from a higher dimension...",
                "Now, I need your help to bring her back safely...",
              ]}
            />
          )}

          {step === SLIDE_END && (
            <JarvisSpeech
              delay={JARVIS_SPEECH_DELAY}
              onFinished={onFinishedLastSpeech}
              content={[
                "For that, I'll help you learn faster by sending you daily challenges and providing helpful tips along the way.",
                "I'll also keep you on track with quizzes and daily motivation.",
                "Sounds good?",
              ]}
            />
          )}

          {step === SLIDE_BACK_END && (
            <JarvisSpeech
              delay={JARVIS_SPEECH_DELAY}
              onFinished={onFinishedLastSpeech}
              content={["Are you up for it??"]}
            />
          )}

          <Swiper
            initialSlide={0}
            slidesPerView={1}
            onSwiper={handleSwiper}
            modules={[IonicSlides]}
            noSwipingClass="no_swipe"
          >
            <SwiperSlide>
              <div className="slide no_swipe">
                <Introduction
                  goForward={goToPlayerNameSlide}
                  proceedDisabled={disabled[SLIDE_INTRO]}
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide no_swipe">
                <PlayerNaming
                  goBack={goToIntroduction}
                  disabledBack={disabledBack}
                  onSubmit={onSubmitUserName}
                  username={auth?.user.firstName}
                  goForward={goToJarvisNameSlide}
                  isActualSlide={step === SLIDE_PLAYER_NAME}
                  proceedDisabled={disabled[SLIDE_PLAYER_NAME]}
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide no_swipe">
                <JarvisNaming
                  disabledBack={disabledBack}
                  goBack={goToPlayerNameSlide}
                  onSubmit={onSubmitJarvisName}
                  goForward={goToSlideTrialIntroSlide}
                  isActualSlide={step === SLIDE_JARVIS_NAME}
                  proceedDisabled={disabled[SLIDE_JARVIS_NAME]}
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide no_swipe">
                <Blank
                  disabledBack={disabledBack}
                  goBack={goToJarvisNameSlide}
                  goForward={goToWorldIntroSlide}
                  proceedDisabled={disabled[SLIDE_TRIAL_INTRO]}
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide no_swipe">
                <Blank
                  goForward={goToEndSlide}
                  disabledBack={disabledBack}
                  goBack={goToSlideTrialIntroSlide}
                  proceedDisabled={disabled[SLIDE_WORLD_INTRO]}
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide no_swipe">
                <End
                  auth={auth}
                  finished={finished}
                  username={username}
                  jarvisName={jarvisName}
                  trackSignUp={trackSignUp}
                  disabledBack={disabledBack}
                  goBack={goBackToPreviousEndSlide}
                  proceedDisabled={disabled[SLIDE_END]}
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </Wrapper>
      )}
    </>
  );
};

export default Customize;
