// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// utils
import styled from "styled-components";

const Wrapper = styled.div`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  opacity: 0.6;
  padding: 5px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    margin: 5px;

    span {
      display: inline-block;
      margin-left: 8px;
    }
  }

  .stats {
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: bold;
    color: var(--dark-bg-color);
    background: var(--yellow-color);
  }
`;

const StatsWidget = () => {
  const player = useSelector(s.playerClassRoom());

  return (
    <Wrapper>
      <p className="stats open_sans_font_family">
        Day <span>{player.checkpoint}</span>
      </p>

      <p className="stats open_sans_font_family">
        <span className="nerdfy_pink">Max Streak</span>
        <span>{player.maxStreak}</span>
      </p>

      <p className="stats open_sans_font_family">
        <span className="nerdfy_acai">Current Streak</span>
        <span>{player.streak}</span>
      </p>

      <p className="stats open_sans_font_family">
        <span className="nerdfy_purple">Intro</span> <span>{player.intro}</span>
      </p>

      <p className="stats open_sans_font_family">
        <span className="nerdfy_green">Quiz</span>
        <span>0</span>
      </p>

      <p className="stats open_sans_font_family">
        <span className="nerdfy_blue">Saved</span>
        <span>{player.battle}</span>
      </p>

      <p className="stats open_sans_font_family">
        <span className="nerdfy_red">Lost</span> <span>{player.killed}</span>
      </p>
    </Wrapper>
  );
};

export default StatsWidget;
